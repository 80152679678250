import React  from 'react';
import { Fragment } from 'react';
import MedicalRecord from './MedicalProviderRecord';
import MedicalReport from './MedicalProviderReport';
import MedicalProvider from './medical-Provider';

const MedicalProviderCategory = () => {
  return (
    <React.Fragment>
      <MedicalProvider >
            <MedicalReport/>
            <MedicalRecord/>
      </MedicalProvider>
      </React.Fragment>

  );
};

export default MedicalProviderCategory;
