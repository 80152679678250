import React  from 'react';
import { Fragment } from 'react';
import MedicationRecord from './MedicationRecord';
import MedicationReport from './MedicationReport';
import MedicationProvider from './medication-Provider';

const MedicalProviderCategory = () => {
  return (
    <React.Fragment>
      <MedicationProvider>
            <MedicationReport/>
            <MedicationRecord/>
      </MedicationProvider>
      </React.Fragment>

  );
};

export default MedicalProviderCategory;
