import React from 'react'
import Button from '@mui/material/Button';
import { styled } from "@mui/material/styles";
export default function PersonalizedButton(props) {
    const ColorButton = styled(Button)(({ theme }) => ({
      backgroundColor: "#4788B8",
      color:"white",
      "&:hover": {
        backgroundColor: "black"
      }
    }));
    return (
     <React.Fragment>
         <ColorButton 
          fullWidth
          type={props.type}
          variant={props.variant}
          onClick={props.onClick}
          sx={props.sx}
          >
          {props.children}
          </ColorButton>     
     </React.Fragment>
    );
  }
  