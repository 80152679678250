import React,{useState,useEffect,useContext}  from 'react';
import { Fragment } from 'react';
import ObgynReport from './ObgynReport';
import  ObgynProvider from './obgyn-Provider';
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import PregnancyHistoryCategory from '../PregnancyHistory/PregnancyHistoryCategory';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Stack from "@mui/material/Stack";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import PersonalizedButton from "../../../Layout/Button"
import {numbersNotAllowed,emptyCheck,dateFormatter,dateIsValid} from "../../../../methods/functions"
import Typography from '@mui/material/Typography';
import { Identity } from '@mui/lab/node_modules/@mui/base';
import ObgynContext from '../Obgyn/obgyn-context';
const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& > :not(style) + :not(style)': {
    marginTop: theme.spacing(2),
  },
}));
const ObgynRecord = () => {
  const obgynCtx = useContext(ObgynContext);
  const [ageAtFirstPeriodError, setAgeAtFirstPeriodError] = useState();
  const [ageAtFirstPeriodField, setAgeAtFirstPeriodField] = useState(0);
  const [dateError, setDateError] = useState();

  const changeAgeAtFirstPeriod = (event) => {
    setAgeAtFirstPeriodField(event.target.value);
  };

  const [numberOfPregnancyError, setNumberOfPregnancyError] = useState();
  const [numberOfPregnancyField, setNumberOfPregnancyField] = useState(0);

  const changeNumberOfPregnancy = (event) => {
    setNumberOfPregnancyField(event.target.value);
  };

  const [concernSexualActivityError, setConcernSexualActivityError] = useState();
  const [concernSexualActivityField, setConcernSexualActivityField] = useState(0);

  const changeConcernSexualActivity = (event) => {
    setConcernSexualActivityField(event.target.value);
 };

  const [abnormalPapSmearError, setAbnormalPapSmearError] = useState();
  const [abnormalPapSmearField, setAbnormalPapSmearField] = useState(0);

  const changeAbnormalPapSmear = (event) => {
    setAbnormalPapSmearField(event.target.value);
 }

  const [treatmentForAbnormalSmearError, setTreatmentForAbnormalSmearError] = useState();
  const [treatmentForAbnormalSmearField, setTreatmentForAbnormalSmearField] = useState('');

  const changeTreatmentForAbnormalSmear = (event) => {
    setTreatmentForAbnormalSmearField(event.target.value);
 };

  const [dateOfLastMammogramError, setDateOfLastMammogramError] = useState();
  const [dateOfLastMammogramField, setDateOfLastMammogramField] = React.useState(new Date());

  const changeDateOfLastMammogram = (value) => {
    setDateOfLastMammogramField(value);
 };

  const [abnormalMammogramError, setAbnormalMammogramError] = useState();
  const [abnormalMammogramField, setAbnormalMammogramField] = useState(0);

  const changeAbnormalMammogram = (event) => {
    setAbnormalMammogramField(event.target.value);
 };
 const [recordOption ,setRecordOption] = useState(0);
 const [updated,setUpdatedField]=useState('');
 const handleChangeRecordOption = (event) => {
  if(event.target.value==0)
  {
    setRecordOption(0);
    setAgeAtFirstPeriodField(0);
    setNumberOfPregnancyField(0);
    setConcernSexualActivityField(0);
    setAbnormalPapSmearField(0);
    setTreatmentForAbnormalSmearField('');
    setDateOfLastMammogramField(new Date());
    setAbnormalMammogramField(0);
  }
  setRecordOption(event.target.value);
};
useEffect(() => {
  if(updated==1)
  {
    setRecordOption(0);
    setAgeAtFirstPeriodField(0);
    setNumberOfPregnancyField(0);
    setConcernSexualActivityField(0);
    setAbnormalPapSmearField(0);
    setTreatmentForAbnormalSmearField('');
    setDateOfLastMammogramField(new Date());
    setAbnormalMammogramField(0);
    setRecordOption(0);
    setUpdatedField(0);
  }
  if(obgynCtx.items.option==2)
  {
    setAgeAtFirstPeriodField(obgynCtx.items.ageFirstPeriod);
    setNumberOfPregnancyField(obgynCtx.items.numberOfPregnancies);
    setConcernSexualActivityField(obgynCtx.items.concernSexualActivity);
    setAbnormalPapSmearField(obgynCtx.items.abnormalPapSmear==0?0:1);
    setTreatmentForAbnormalSmearField(obgynCtx.items.treatmentForAbnormalSmear);
    setDateOfLastMammogramField(obgynCtx.items.dateOfLastMammogram);
    setAbnormalMammogramField(obgynCtx.items.abnormalMammogram);
    setRecordOption(1);
  }
  // eslint-disable-next-line
}, [obgynCtx.items.option]);

 const handleSubmit = (event) => {
  setDateError(false);
  event.preventDefault();
  let ageAtFirstPeriod=ageAtFirstPeriodField;
  let numberOfPregnancy=numberOfPregnancyField;
  let concernSexualActivity=concernSexualActivityField;
  let abnormalPapSmear=abnormalPapSmearField;
  let treatmentForAbnormalSmear=treatmentForAbnormalSmearField;
  let dateOfLastMammogram=dateOfLastMammogramField;
  let abnormalMammogram=abnormalMammogramField;
  let finalDecision=true;
  if(numbersNotAllowed(ageAtFirstPeriod)){
        setAgeAtFirstPeriodError(true);
        finalDecision=false;
  }
  else
  {
     if(ageAtFirstPeriod<0  || ageAtFirstPeriod==-0)
     {
      setAgeAtFirstPeriodError(true);
      finalDecision=false;
     }
  }
  if(numbersNotAllowed(numberOfPregnancy)){
    setNumberOfPregnancyError(true);
    finalDecision=false;
  }
  else
  {
    if(numberOfPregnancy<0 ||numberOfPregnancy == -0 )
    {
      setNumberOfPregnancyError(true);
      finalDecision=false;
    }
  }
  if(abnormalPapSmear==1)
  {
      if(emptyCheck(treatmentForAbnormalSmear)){
        setTreatmentForAbnormalSmearError(true);
        finalDecision=false;
      }
  } 
  function getData(){
    const getURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICAL_HISTORY_GET;
    return fetch(getURL ,{
     method: 'GET',
     headers: {  
     'Content-Type': 'application/json',
     'Token': localStorage.getItem("token"),
     'userid':localStorage.getItem("userId")     
     }
   })
   .then((response) => response.json())
   .then((data) => {   
     try{
       return data.body[0];
     }
     catch{
       return 0;
     }

   });
  };

  if (isNaN(dateOfLastMammogram) || dateOfLastMammogram == null) {
    try {
      if (dateIsValid(dateOfLastMammogram) == false) {
        setDateError(true);
        finalDecision = false;
      }
    } catch {
      let today = new Date(dateOfLastMammogram);
      let yyyy = today.getFullYear();
      if (dateOfLastMammogram == null) {
        setDateError(true);
        finalDecision = false;
      }
      if (yyyy <= 1899) {
        setDateError(true);
        finalDecision = false;
      }
    }
  } else {
    let today = new Date(dateOfLastMammogram);
    let yyyy = today.getFullYear();
    if (yyyy <= 1899) {
      setDateError(true);
      finalDecision = false;
    }
  }

  if(finalDecision)
  {
    setDateError(false);
    let dateeOfLastMammogram= dateFormatter(dateOfLastMammogram);
    setAgeAtFirstPeriodError(false);
    setNumberOfPregnancyError(false);
    setConcernSexualActivityError(false);
    setAbnormalPapSmearError(false);
    setTreatmentForAbnormalSmearError(false);
    setDateOfLastMammogramError(false);
    setAbnormalMammogramError(false);
    getData().then(response => {
      let id=0;
      let exists=undefined;
      try{
        id= response.id?1:0;
        exists= response.obgyn.abnormalPapSmear==0|| response.obgyn.abnormalPapSmear==1?1:0;
      }
      catch{
        exists=0;
        id=0;
      }
      let gynecologicalHistory=null;
      let pregnancy=null;
      let diseases=null;
      try
      {
          gynecologicalHistory=response.obgyn.gynecologicalHistory;
          pregnancy= response.obgyn.pregnancy;
          diseases= response.diseases;
      }
      catch
      {
          gynecologicalHistory=[];
          pregnancy=[];
          diseases=[];
      }

      if(exists==0)
      {
            if(recordOption==0 && exists==0 && id==0){
              if(id==0)
              {
                const createURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICAL_HISTORY_CREATE;
                  const medicalHistoryData={
                    userId:localStorage.getItem("userId"),
                    diseases: diseases,
                    obgyn:{
                      gynecologicalHistory:gynecologicalHistory,
                      pregnancy: pregnancy,
                      abnormalPapSmear:abnormalPapSmear==0?0:{
                        "treatmentForAPS":treatmentForAbnormalSmear
                      },
                      ageFirstPeriod:parseInt(ageAtFirstPeriod), 
                      concernSexualActivity:parseInt(concernSexualActivity),
                      mammogram:{
                          abnormalMammogram: parseInt(abnormalMammogram),
                          dateOfLastMammogram:dateeOfLastMammogram
                      },
                      numberOfPregnancies:parseInt(numberOfPregnancy)
                  
                    }
                  }

                  const requestOptions = {
                    method: 'POST',
                    headers: { 
                      
                      'Content-Type': 'application/json',
                      "Token": localStorage.getItem("token")  
                  
                    },
                        body: JSON.stringify(medicalHistoryData)
                    };
                    try{
                      fetch(createURL, requestOptions)
                          .then(response => response.json());
                          alert("Done");

                          obgynCtx.items=[];
                          obgynCtx.addItem( obgynCtx.items);

                    }
                    catch{
                        alert("Error");
                    }  
              }
              else
              {

                alert("You can't add more records, because you already have one OBGYN record. If you want to apply further changes, please update it.");
              }

            }
            else
            {
                    //UPDATE
                    try
                    {
                        gynecologicalHistory=response.obgyn.gynecologicalHistory;
                        pregnancy= response.obgyn.pregnancy;
                        diseases= response.diseases;
                    }
                    catch
                    {
                        gynecologicalHistory=[];
                        pregnancy=[];
                        diseases=[];
                    }
                    let originalArray=[];
                    originalArray=response;
                    const update=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICAL_HISTORY_UPDATE;
                    originalArray.documentId=originalArray.id;
                    originalArray.obgyn= {
                      gynecologicalHistory:gynecologicalHistory,
                      pregnancy: pregnancy,
                      abnormalPapSmear:abnormalPapSmear==0?0:{
                        "treatmentForAPS":treatmentForAbnormalSmear
                      },
                      ageFirstPeriod:parseInt(ageAtFirstPeriod), 
                      concernSexualActivity:parseInt(concernSexualActivity),
                      mammogram:{
                          abnormalMammogram: parseInt(abnormalMammogram),
                          dateOfLastMammogram:dateeOfLastMammogram
                      },
                      numberOfPregnancies:parseInt(numberOfPregnancy)
                  
                    };
            
                      const requestOptions = {
                        method: 'PUT',
                        headers: { 
                          
                          'Content-Type': 'application/json',
                          "Token": localStorage.getItem("token")
                        },
                          body: JSON.stringify(originalArray)
                    };

                    try{
                      fetch(update, requestOptions)
                      .then(response => response.json());
                        alert("Done");
                        obgynCtx.items=[];
                        obgynCtx.addItem( obgynCtx.items);

                      }
                      catch
                      {
                      alert("Error");
                      }
            }     
      }
      else{
        if(recordOption==1)
        {
          //UPDATE
          try
          {
              gynecologicalHistory=response.obgyn.gynecologicalHistory;
              pregnancy= response.obgyn.pregnancy;
              diseases= response.diseases;
          }
          catch
          {
              gynecologicalHistory=[];
              pregnancy=[];
              diseases=[];
          }
          let originalArray=[];
          originalArray=response;
          const update=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICAL_HISTORY_UPDATE;
          originalArray.documentId=originalArray.id;
          originalArray.obgyn= {
            gynecologicalHistory:gynecologicalHistory,
            pregnancy: pregnancy,
            abnormalPapSmear:abnormalPapSmear==0?0:{
              "treatmentForAPS":treatmentForAbnormalSmear
            },
            ageFirstPeriod:parseInt(ageAtFirstPeriod), 
            concernSexualActivity:parseInt(concernSexualActivity),
            mammogram:{
                abnormalMammogram: parseInt(abnormalMammogram),
                dateOfLastMammogram:dateeOfLastMammogram
            },
            numberOfPregnancies:parseInt(numberOfPregnancy)
        
          };
  
            const requestOptions = {
              method: 'PUT',
              headers: { 
                
                'Content-Type': 'application/json',
                "Token": localStorage.getItem("token")
              },
                body: JSON.stringify(originalArray)
          };

          try{
            fetch(update, requestOptions)
            .then(response => response.json());
              alert("Done");
              obgynCtx.items=[];
              obgynCtx.addItem( obgynCtx.items);

            }
            catch
            {
            alert("Error");
            }
          
        }
        else
        {
          alert("You can't add more records, because you already have one OBGYN record. If you want to apply further changes, please update it.");
        }
      }
    }
    
    );
  }


 }
  return (
      <React.Fragment>
      <Box
        component="form"
         noValidate
        onSubmit={handleSubmit}
            sx={{
              my: 4,
              mx: 12,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >  
         <Typography component="h1" variant="h5">
                  Record
                </Typography>
                <FormControl sx={{ m: 2}}>
                      <RadioGroup
                        sx={{ display: 'inline' }}
                        aria-labelledby="record-radio-buttons-group-label"
                        name="record-radio-buttons-group"
                        value={recordOption}
                        onChange={handleChangeRecordOption}
                      >
                        <FormControlLabel value="0" control={<Radio />} label="Create" />
                        <FormControlLabel value="1" disabled  control={<Radio />} label="Update" />
                      </RadioGroup>
        </FormControl>
        <FormControl sx={{ m: 1}}>
        <TextField
                margin="normal"
                fullWidth
                id="ageAtFirstPeriodField"
                label="Age at first period (years)"
                InputLabelProps={{ shrink: true }}
                type="number"
                name="ageAtFirstPeriodField"
                error= {ageAtFirstPeriodError? true:false}
                value={ageAtFirstPeriodField}
                onChange={changeAgeAtFirstPeriod}  
              />
          </FormControl>
          <FormControl sx={{ m: 1}}>
          <TextField
                margin="normal"
                fullWidth
                id="numberOfPregnancyField"
                label="Number of pregnancies"
                InputLabelProps={{ shrink: true }}
                type="number"
                name="numberOfPregnancyField"
                error= {numberOfPregnancyError? true:false}
                value={numberOfPregnancyField}
                onChange={changeNumberOfPregnancy}  
              />
              </FormControl>
              <FormControl sx={{ m: 2}} >
                      <FormLabel id="radio-buttons-group-label">Are there concerns about your sexual activity which you may want to discuss with your doctor?</FormLabel>
                      <RadioGroup
                        aria-labelledby="radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={concernSexualActivityField}
                        onChange={changeConcernSexualActivity}
                      >
                        <center>
                        <FormControlLabel value="1" control={<Radio />} label="Yes" />
                        <FormControlLabel value="0" control={<Radio />} label="No" />
                        </center>
                      </RadioGroup>
                </FormControl>
               <FormControl sx={{ m: 2}} >
                      <FormLabel id="radio-buttons-group-label">Have you had an abnormal pap smears?</FormLabel>
                      <RadioGroup
                        aria-labelledby="radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={abnormalPapSmearField}
                        onChange={changeAbnormalPapSmear}
                      >
                        <center>
                        <FormControlLabel value="1" control={<Radio />} label="Yes" />
                        <FormControlLabel value="0" control={<Radio />} label="No" />
                        </center>
                      </RadioGroup>
              </FormControl>
              {      
                      abnormalPapSmearField==1? 
              <FormControl sx={{ m: 2}} >
              <TextField
                        InputLabelProps={{ shrink: true }}
                        error= {treatmentForAbnormalSmearError? true:false}
                        sx={{ m: 0}}
                        margin="normal"
                        id="treatmentForAbnormalSmear"
                        label="Treatment for abnormal smears?"
                        name="treatmentForAbnormalSmea"      
                        value={treatmentForAbnormalSmearField}
                        onChange={changeTreatmentForAbnormalSmear}      
                />    
              </FormControl>
                       :   
                       <React.Fragment></React.Fragment>
               }
              <FormControl sx={{ m: 2}} >
              <Stack  spacing={4} sx={{ m: 1 , mx: 0,mt: 2}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} >
                      <DesktopDatePicker
                      label="Date of last mammogram*"
                      id="dateOfLastMammogram"
                      inputFormat="MM/dd/yyyy"
                      value={dateOfLastMammogramField}
                      onChange={changeDateOfLastMammogram}
                      renderInput={(params) => (
                        <TextField {...params} error={dateError ? true : false} />
                      )}
                    />
                    </LocalizationProvider>
                   </Stack> 
              </FormControl>
              <FormControl sx={{ m: 2}} >
                      <FormLabel id="radio-buttons-group-label">Have you had an abnormal mammogram?</FormLabel>
                      <RadioGroup
                        aria-labelledby="radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={abnormalMammogramField}
                        onChange={changeAbnormalMammogram}
                      >
                        <center>
                        <FormControlLabel value="1" control={<Radio />} label="Yes" />
                        <FormControlLabel value="0" control={<Radio />} label="No" />
                        </center>
                      </RadioGroup>
              </FormControl>
              <PersonalizedButton  
              type="submit"
                variant="contained"
                 sx={{ mt: 2, width: '50%'  }}
              >
                  Save
              </PersonalizedButton>   
      </Box>
      <PregnancyHistoryCategory/>
</React.Fragment>
  );
};

export default ObgynRecord;
