import React,{ useState, useRef, useContext }  from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PersonalizedButton from "../Layout/Button"
import AuthContext from '../../store/auth-context';
import { useHistory } from 'react-router-dom';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {validateEmail,checkPassword} from "../../methods/functions"
import image from '../../assets/defaultImage.jpg';

function Copyright(props) {
  return (
    <Typography component={'div'} variant="body2" color="text.secondary"  align="center" {...props}>
      {'2022 Copyright © '}
      <Link color="inherit" href="" >
        OneMe
      </Link>{' '}
      <div style={{"lineHeight": 4}} >
      <Link color="inherit" href="https://oneme.app/ourpolicies/terms-of-use-and-privacy-policy">
      Legal & Privacy Policy Terms
      </Link>{' '}
      </div>
      </Typography>
   
  );
}

function RedBar(props) {
  return (
    <Box
      sx={{
        height: props.height,
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? 'rgba(255, 0, 0, 0.1)'
            : 'rgb(255 132 132 / 25%)',
      }}
    > 
    <div align="left" 
    style=
    {{
      mt: props.mt, 
      mb: props.mb,
      paddingTop:props.paddingTop,
      paddingLeft:props.paddingLeft,
      paddingBottom:props.paddingBottom
    }}
    > 
    {props.children} 
    </div> 
    </Box>  
  )
}

const theme = createTheme({
});

export default function AuthForm() {
  const history = useHistory();
  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const authCtx = useContext(AuthContext);
  const [error, setError] = useState();
  const [emailError, setEmailError] = useState();
  const [credentialError, setCredentiallError] = useState();
  const [emailConfirmError, setEmailConfirmError] = useState();
  const [isLogin, setIsLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [passworddError, setPasswordError] = useState();
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submitHandler = (event) => {
    setCredentiallError(false);
    setError(false);
    setEmailError(false);
    setPasswordError(false);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setIsLoading(true);

    const signInURL=process.env.REACT_APP_SIGN_IN_URL + process.env.REACT_APP_WEB_API_KEY;
    const emailIsVerified= process.env.REACT_APP_GET_GET_USER_DATA + process.env.REACT_APP_WEB_API_KEY;
    const email=data.get('email');
    const password= data.get('password');
    let finalDecision=true;
    if(!validateEmail(email)){
      setError(true);
      setEmailError(true);
      finalDecision=false;
    }
    if(!checkPassword(password)){
      setError(true);
      setPasswordError(true);
      finalDecision=false;
    }

    if(finalDecision)
    {
      fetch(signInURL , {
        method: 'POST',
        body: JSON.stringify({
          email: email,
          password: password,
          returnSecureToken: true,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          
            if (res.ok) {        
              return res.json();
            } else {
              return res.json().then((data) => {
                let errorMessage = 'Authentication failed!';
                setError(false);
                setEmailError(false);
                setEmailConfirmError(false);
              });
            }
        })
        .then((signInData) => {
              fetch(emailIsVerified, {
                  method: 'POST',
                  body: JSON.stringify({
                    idToken: signInData.idToken
                  }),
                  headers: {
                    'Content-Type': 'application/json',
                  },
              })
                .then((resSignIn) => resSignIn.json())
                .then((dataSignIn) => {
                      if(dataSignIn.users[0].emailVerified)
                      {
                        setIsLoading(false);
                        setIsLogin(true);
                        setError(false);
                        setEmailError(false);
                        setEmailConfirmError(false);
                        setPasswordError(false);
                        const expirationTime = new Date(
                          new Date().getTime() + +signInData.expiresIn * 1000
                        );
                        authCtx.login(signInData.idToken, expirationTime.toISOString(),signInData.localId);
                        history.replace('/home');
                        localStorage.setItem("email", email);
                      }
                      else
                      {
                        setError(true);
                        setEmailConfirmError(true);
                      }
                })
                .catch((err) => {
                  console.log(err.message);
                });
        })
        .catch((err) => {
          setCredentiallError(true);
          console.log(err.message);
          setError(true);
          setEmailConfirmError(false);
          setEmailError(false);
        });
      }
    else
    {

    }
   
  };

  
  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${image})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: '#4788B8' }}>
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={submitHandler} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth={true}
                ref={emailInputRef}
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <FormControl fullWidth={true} variant="outlined" sx={{ mt: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    required
                    fullWidth={true}
                    id="password"
                    name="password"
                    label="Password"
                    autoComplete="password"
                    type={values.showPassword ? "text" : "password"}
                    ref={passwordInputRef}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
              </FormControl>
              <PersonalizedButton  
              type="submit"
                variant="contained"
                 sx={{ mt: 2, mb: 2 }}
                >
                  Sign in
                </PersonalizedButton>
                {error &&(
                <RedBar
                    paddingTop={"0.1em"}
                    paddingBottom={"0.1em"}
                >
                  <ul> 
                  {  emailError &&(<li>Invalid Email</li>)}
                  {  passworddError &&(<li>Invalid Password</li>)}
                  {credentialError&&(<li>Review your credentials</li>)}
                  { emailConfirmError  &&(<li>Please confirm your email</li>)}
                 </ul>
                </RedBar>
                )}
              <Grid container>
                <Grid item xs>
                  <Link href="resetPassword" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="profile" variant="body2">
                    {"Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />    
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
