import { Fragment } from 'react';
import  React,{useEffect,useState,useContext } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PersonalizedButton from "../../Layout/Button"
import FormControl from "@mui/material/FormControl";
import { useTheme } from '@mui/material/styles';
import {numbersNotAllowed,emptyCheck,dateFormatter} from "../../../methods/functions"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ShareHealthRecordContext from './shareHealthRecord-context';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import { styled } from "@mui/material/styles";
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import { makeStyles } from "@material-ui/core/styles";
import TablePagination from '@mui/material/TablePagination';
import DeleteIcon from "@material-ui/icons/Delete";
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import Divider from "@mui/material/Divider";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer
} from "@material-ui/core";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 250,
  },
  tableContainer: {
    borderRadius: 15,
    margin: "10px 10px",
    maxWidth: "1800px",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    backgroundColor: "#4788B8",
    color: theme.palette.getContrastText(theme.palette.primary.dark)
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
  name: {
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
  },
  status: {
    fontWeight: "bold",
    fontSize: "0.75rem",
    color: "white",
    backgroundColor: "grey",
    borderRadius: 8,
    padding: "3px 10px",
    display: "inline-block",
  },
  hover: {
    cursor: "pointer",
  },
  center:{
    textAlign:'center'
  }
}));

const tableHeader = [
  { label: "Access", data: "familyRelationship" },
  { label: "Remove", data: "whiteSpaceUpdate" }
];

const styleObj = {
  fontSize: 20,
  color: "black",
}

export default function ShareHealthRecordEdit() {
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [shareHealthRecordData, setData] = useState([]);
  const deleteItem = (index) => {
    let categoryList=arrayFieldMasterCategory;
    let result= window.confirm("Are you sure?");
    if (result) {
      let shareMedicalData= shareHealthRecordData.filter(
        (data) =>    
         data!=index
        );
        categoryList.unshift(index);
        setArrayMasterCategory(categoryList);
        setData(shareMedicalData);
    }
  };

  const [showSuper,setShowSuper]= React.useState(0);
  const [arrayFieldMasterCategory ,setArrayMasterCategory] = React.useState([]);
  const shareHealthRecordCtx = useContext(ShareHealthRecordContext);
  const [healthRecordFieldError, setShareHealthRecordError] = useState();
  const [sharerUserIdField,setSharerUserIdField] = useState();
  const [recipientEmailField,setRecipientEmailField] = useState();

  const createURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_FAMILY_RELATIONSHIP_CREATE;

  const [categoryList, setCategoryList] = React.useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
      let valuex=  typeof value === 'string' ? value.split(',') : value;
      setCategoryList(
        valuex
      );
  };
  const handleShareHealthRecord = (event) => {
    event.preventDefault();
    setShareHealthRecordError(false);
    let finalDecision=true;
    let masterArray="";
    if(categoryList.length==0&&shareHealthRecordData.length==0 )
    {
      setShareHealthRecordError(true);
      finalDecision=false;
    }
  
    if(finalDecision)
    { 
      setCategoryList([]);
      let listToDB=
        categoryList.map((row)=>{
          return parseInt(row.index);
        });
      let reportToDB= shareHealthRecordData.map((row)=>{
        return parseInt(row.index);
      });

        masterArray=[...new Set([...listToDB,...reportToDB])];
        const update=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_UPDATE_INVITE;

        let dataToDB={
          sharerUserId: sharerUserIdField,
          recipientEmail: recipientEmailField,
          categories: masterArray
        };
        const requestOptions = {
          method: 'PUT',
          headers: { 
            
            'Content-Type': 'application/json',
            "Token": localStorage.getItem("token")
          },
            body: JSON.stringify(dataToDB)
       };

        try{
        fetch(update, requestOptions)
        .then(response => response.json());
            alert("Done");
            setShowSuper(0);
        }
        catch
        {
         alert("Error");
        }

    }
  
    

  };
  
  
    useEffect(() => {
      setArrayMasterCategory([]);
      setData([]);
      if(shareHealthRecordCtx.items.option==2)
      {
        setShowSuper(0);
        let arrCategories=[];
        let editMasterArray=shareHealthRecordCtx.items;
        const getURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_SHARED_BY;
        let getCategoryCatalog=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_CATEGORY_CATALOG;
        let master=[];
        fetch(getCategoryCatalog ,{
          method: 'GET',
          headers: {  
          'Content-Type': 'application/json',
          'Token': localStorage.getItem("token"),
          'userid':localStorage.getItem("userId")     
          }
        })
        .then((response) => response.json())
        .then((data) => {
               let datax=undefined;
                 
               try
               {
               datax= data.body.category;
       
               }
               catch
               {
                 datax=[];
               }
              
               for(let row in datax)
               {
                 master.push({
                   index: row,
                   subCategoryId: row,
                   subCategory: "",
                   categoryId: row,
                   category:datax[row],
                   label:datax[row],
                   key: row
                   }) ;  
     
               }
              
             }      
        ); 
        fetch(getURL ,{
          method: 'GET',
          headers: {  
          'Content-Type': 'application/json',
          'Token': localStorage.getItem("token"),
          'userid':localStorage.getItem("userId")     
          }
        })
        .then((response) => response.json())
        .then((data) => {  
       
         for(let row in data.body.invites)
         {    
          if(data.body.invites[row].access.status.toLowerCase()!="inactive")
          {
              if(data.body.invites[row].granteeEmail==editMasterArray.granteeEmail)
              {
                for(let rowx in data.body.invites[row].access.categories)
                {
                        let id=master[data.body.invites[row].access.categories[rowx]].categoryId;
                        arrCategories.push(master[data.body.invites[row].access.categories[rowx]])
                }
           
              }
            }

         }
         setSharerUserIdField(editMasterArray.granteeUserId);
         setRecipientEmailField(editMasterArray.granteeEmail);
         let newArray=master;
         for(let row in arrCategories)
         {
          
              for(let rowx in master)
              {
                if(arrCategories[row].categoryId==master[rowx].categoryId)
                {
                  newArray = newArray.filter(data=> data.categoryId!=master[rowx].categoryId);
                 
                }
     
              }

         }
         
         setShowSuper(1);
         setArrayMasterCategory(newArray);
         setData(arrCategories);  
        });
      }

    }, [shareHealthRecordCtx]);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  }
};

const theme = useTheme();
function getStyles(option, categoryList, theme) {
  return {
    fontWeight:
    categoryList.indexOf(option) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


const ColorButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#333399",
  color:"white",
  "&:hover": {
    backgroundColor: "#333399"
  }
}));
const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& > :not(style) + :not(style)': {
    marginTop: theme.spacing(2),
  },
}));

  return (
        <Box
        component="form"
         noValidate
        onSubmit={handleShareHealthRecord}
            sx={{
              my: 4,
              mx: 12,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          > 

             <FormControl sx={{ m: 0 }}>
             {
                showSuper==1?       
                <TableContainer className={classes.tableContainer}>
                  <center><p style={styleObj}>Current accesses</p></center>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {tableHeader.map((cell) => (
                          <TableCell key={Math.random()} className={classes.tableHeaderCell}>
                            {cell.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {(rowsPerPage > 0
                        ? shareHealthRecordData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : shareHealthRecordData
                      ).map((row,i) => (
                        <TableRow key={Math.random()} >
                          <TableCell>{row.category}</TableCell>
                          <TableCell>
                            <Grid container>
                              <Grid item lg={12}>
                                <Typography>
                                  <DeleteIcon
                                    color="secondary"
                                    onClick={deleteItem.bind(this, row)}
                                    className={classes.hover}
                                  />
                                </Typography>
                            </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={shareHealthRecordData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                </TableContainer>:<React.Fragment></React.Fragment>
              }
            </FormControl>
            {
                  showSuper==1?
                  <React.Fragment>
                <FormControl sx={{ m: 1, width: 300 }}>  
                <InputLabel id="demo-multiple-chip-label">Add access</InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  error= {healthRecordFieldError? true:false}
                  value={categoryList}
                  onChange={handleChange}
                  input=   {
                  <OutlinedInput id="select-multiple-chip" label="Add access"/>
                   }
                  MenuProps={MenuProps}
                >
                  {arrayFieldMasterCategory.map((option,i) => (
                    <MenuItem
                      key={option.index}
                      value={option}
                      style={getStyles(option,categoryList, theme)}
                    >
                      {option.category}
 
                    </MenuItem>
                  ))}
                </Select>

              </FormControl>
              <PersonalizedButton  
                            type="submit"
                              variant="contained"
                               sx={{ mt: 2, mb: 3 }}
                            >
                                Save
              </PersonalizedButton>  
              </React.Fragment>
                    :<React.Fragment></React.Fragment>
            }
        <React.Fragment>

    </React.Fragment>
  
        </Box>
  );
}
