import  React,{useEffect,useState,useContext} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography
} from "@material-ui/core";
import TablePagination from '@mui/material/TablePagination';
import {emptyCheck} from "../../../methods/functions"
import DeleteIcon from "@material-ui/icons/Delete";
import DownloadIcon from '@mui/icons-material/Download';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import VaccineContext from '../Vaccine/vaccine-context';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    borderRadius: 15,
    margin: "10px 10px",
    maxWidth: "1800px",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    backgroundColor: "#4788B8",
    color: theme.palette.getContrastText(theme.palette.primary.dark)
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
  name: {
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
  },
  status: {
    fontWeight: "bold",
    fontSize: "0.75rem",
    color: "white",
    backgroundColor: "grey",
    borderRadius: 8,
    padding: "3px 10px",
    display: "inline-block",
  },
  hover: {
    cursor: "pointer",
  },
  center:{
    textAlign:'center'
  }
}));

const tableHeader = [
  { label: "Document", data: "id" },
  { label: "Category", data: "category" },
  { label: "Trade Provider", data: "subCategory" },
  { label: "Date", data: "date" },
  { label: "File", data: "file" },
  { label: "Update", data: "whiteSpaceDelete" },
  { label: "Delete", data: "whiteSpaceUpdate" }
];

function createData(
  document,
  id,
  categoryId,
  subCategoryId,
  categoryName,
  subCategoryName,
  date,
  filename,
  option
  ) {
  return {
    document,
    id,
    categoryId,
    subCategoryId,
    categoryName,
    subCategoryName,
    date,
    filename,
    option
  };
}

const MTable = (props) => {
  const vaccineCtx = useContext(VaccineContext);
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [vaccineData, setData] = useState([]);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getCatalog=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_VACCINE_GET_CATALOG;
  const getURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_VACCINE_GET;
    useEffect(() => {
      let array=[];
      fetch(getCatalog ,{
       method: 'GET',
       headers: {  
       'Content-Type': 'application/json',
       'Token': localStorage.getItem("token"),
       'userid':localStorage.getItem("userId")     
       }
     })
     .then((response) => response.json())
     .then((data) => {   
      let arrSubCategory=[];
      let count=0;
      let subCount=0;
      let datax=undefined;
      let objectSubCategory=[];
      let objectCategory=[];
      let existsSubCategory=0;
      try
      {
       datax= data.body[0].category;
  
      }
      catch
      {
        datax=[];
      }
  
      for(let category in datax)
      {
          count=count+1;
          try
          {
            existsSubCategory = datax[category].subCategory.length; 
            }  
            catch
            {
              existsSubCategory=-1;
            }
  
            if(datax[category].name.toLowerCase()=="other")
            {
                  objectCategory[category]= {
                    index: count,
                    category:datax[category].name,
                    label:datax[category].name,
                    key: category 
                  };    
            }
            else
            {
      
                  objectCategory[category]= {
                    index: count,
                    category:datax[category].name,
                    label:datax[category].name,
                    key: category 
                  };       
            } 
  
            if(existsSubCategory>0)
            {
         
              for(let subCategory in datax[category].subCategory)
              { 
                  arrSubCategory.push({
                    indexSubCategory: subCount++,
                    category:category,
                    label:datax[category].subCategory[subCategory],
                    key: subCategory
                  });
  
              }

              objectSubCategory[category]=arrSubCategory;
  
            }
            arrSubCategory=[];
  
            subCount=0;
      }
      fetch(getURL ,{
        method: 'GET',
        headers: {  
        'Content-Type': 'application/json',
        'Token': localStorage.getItem("token"),
        'userid':localStorage.getItem("userId")     
        }
      })
      .then((response) => response.json())
      .then((datax) => { 
           let arrayX=undefined;
           try
           {
           arrayX= datax.body[0].vaccine;
           }
           catch
           {
             arrayX=[];
           }
           for(let row in arrayX )
           {
          
             let subCategory="";
             let subCategoryId=0;
             if(!(isNaN(parseInt(arrayX[row].tradeProvider))))
             { 
               subCategoryId=parseInt(arrayX[row].tradeProvider);
               subCategory=objectSubCategory[arrayX[row].category][subCategoryId].label.toString();
             }
             else
             {
               if(arrayX[row].tradeProvider=="")
               {
                subCategoryId=-1;
                subCategory="";
               }
               else
               {
                 subCategory=arrayX[row].tradeProvider;
                 subCategoryId=-2;
               }
             }

              array.push(
                createData(
                  datax.body[0].id,
                  parseInt(row),
                  parseInt(arrayX[row].category),
                  subCategoryId,
                  objectCategory[arrayX[row].category].category,
                  subCategory,
                  arrayX[row].date,
                  arrayX[row].filename
                  )
              );
          
           }
            setData(array);     
        })
     });

      // eslint-disable-next-line
    }, [vaccineCtx.count]);

  const updateItem = (index) => {
    vaccineData[index].option=2;
    vaccineCtx.addItem(vaccineData[index]);
  };
  
  const downloadItem = (index) => {
    const downloadURL= process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_VACCINE_DOWNLOAD;
    const valuex= vaccineData[index];

    var myHeaders = new Headers();
    myHeaders.append("Token", localStorage.getItem("token"));
    myHeaders.append("userid", localStorage.getItem("userId") );
    myHeaders.append("filename", valuex.filename);
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };
   
 
    fetch(downloadURL, requestOptions)
      .then(response => response.json())
      .then(result => {
        let file= new Uint8Array(result.body.content[0].data);
        const blob = new Blob([file]); 
        const url= window.URL.createObjectURL(blob);
        const a= document.createElement('a');
        a.style.display='none';
        a.href=url;
        //the filename you want
        a.download= valuex.filename;
        a.setAttribute('download',valuex.filename);
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }
    
      
      )
      .catch(error => console.log('error', error));
  };

  const deleteItem = (index) => {
    const updateURL= process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_VACCINE_UPDATE;
    let result= window.confirm("Are you sure?");
    if (result) {
      const valuex= vaccineData[index];
      
      const newVaccineData = vaccineData.filter((user, i) => i !== index);
      const getURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_VACCINE_GET;

             fetch(getURL ,{
               method: 'GET',
               headers: {  
               'Content-Type': 'application/json',
               'Token': localStorage.getItem("token"),
               'userid':localStorage.getItem("userId")     
               }
             })
             .then((response) => response.json())
             .then((data) => { 
                  let vaccineArray=[];
                  let originalArray=[];
                  let arrayX;
                  let subCategory;
                  try
                  {
                  arrayX= data.body[0].vaccine;
                  }
                  catch
                  {
                    arrayX=[];
                  }
                  originalArray=data.body;

                  if(!(arrayX.length==1))
                  {  
                  
                          if(!emptyCheck(valuex.filename))
                          {
                            const deleteURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_VACCINE_DELETE;
                            const deleteData = 
                            {
                               userId:localStorage.getItem("userId"), 
                               filename: valuex.filename
                            };
                            const requestOptions = {
                              method: 'DELETE',
                              headers: { 
                                
                                'Content-Type': 'application/json',
                                "Token": localStorage.getItem("token")  
                            
                              },
                                  body: JSON.stringify(deleteData)
                              };
                              try{
                                fetch(deleteURL, requestOptions)
                                    .then(response => response.json());
                                    alert("Done");
                              }
                              catch{
                                  alert("Error");
                              }
                          }


                      for(let m in arrayX )
                      {

                        if(arrayX[m].tradeProvider.toString()=="")
                        {
                          subCategory= -1;
                        }
                        else
                        {
                          if(isNaN(arrayX[m].tradeProvider))
                          {
                            subCategory= -2;
                          }
                          else
                          {
                              subCategory= arrayX[m].tradeProvider;
                          }
                        } 
                          if(valuex.subCategoryId.toString()=="-2")
                          {
                            if(!(arrayX[m].category.toString()== valuex.categoryId.toString() && arrayX[m].tradeProvider == valuex.subCategoryName && arrayX[m].date.toString() == valuex.date.toString()))
                            {
                              vaccineArray.push(
                                {
                                 date: arrayX[m].date,
                                 category: arrayX[m].category,
                                 tradeProvider: arrayX[m].tradeProvider,
                                 filename: arrayX[m].filename
                                }  
                              );
                            }
                          }
                          else
                          {
                            if(!(arrayX[m].category.toString()== valuex.categoryId.toString() && subCategory ==valuex.subCategoryId && arrayX[m].date.toString() == valuex.date.toString()))
                            {
                              vaccineArray.push(
                                {
                                 date: arrayX[m].date,
                                 category: arrayX[m].category,
                                 tradeProvider: arrayX[m].tradeProvider,
                                 filename: arrayX[m].filename
                                }  
                              );
                            }
                          }
                      }

                      var formdata = new FormData();

                      formdata.append("newRecord",0);
                      formdata.append("userId", localStorage.getItem("userId"));
                      formdata.append("documentId", originalArray[0].id);
                      formdata.append("vaccineArray", JSON.stringify(vaccineArray));
                      var myHeaders = new Headers();
                      myHeaders.append("Token",localStorage.getItem("token"));
                      var requestOptions = {
                        method: 'PUT',
                        headers: myHeaders,
                        body: formdata
                      };
                      try{
                        fetch(updateURL, requestOptions)
                            .then(response => response.json());
                      }
                      catch{
                          alert("Error");
                      }  
                      setData(newVaccineData);

                  }
                  else
                  {
                        const deleteURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_VACCINE_DELETE_ALL;
                        let documentId=valuex.document;
                        const deleteData = 
                        {
                           documentId:  documentId,
                           userId:localStorage.getItem("userId") 
                        };
                        const requestOptions = {
                          method: 'DELETE',
                          headers: { 
                            
                            'Content-Type': 'application/json',
                            "Token": localStorage.getItem("token")  
                        
                          },
                              body: JSON.stringify(deleteData)
                          };
                          try{
                            fetch(deleteURL, requestOptions)
                                .then(response => response.json());
                                alert("Done");
                          }
                          catch{
                              alert("Error");
                          }
                          setData(newVaccineData);
                  }
         }); 
    }
  };

  const styleObj = {
    fontSize: 30,
    color: "black",
}
  return (
    <React.Fragment>
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableHeader.map((cell) => (
              <TableCell key={cell.data} className={classes.tableHeaderCell}>
                {cell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
        {(rowsPerPage > 0
            ? vaccineData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : vaccineData
          ).map((row,i) => (
            <TableRow key={row.id}>
              <TableCell>{row.document}</TableCell>
              <TableCell>{row.categoryName}</TableCell>
              <TableCell>{row.subCategoryName}</TableCell>
              <TableCell>{row.date}</TableCell>
              <TableCell>{!emptyCheck(row.filename)==true?
                    <Typography>
                    <DownloadIcon
                      color="primary"
                      onClick={downloadItem.bind(this, i)}
                      className={classes.hover}
                    />
                  </Typography>
                :
              
              
              ""}</TableCell>
              <TableCell>
                <Grid container>
                  <Grid item lg={12}>
                    <Typography>
                      <AutoFixNormalIcon
                        color="primary"
                        onClick={updateItem.bind(this, i)}
                        className={classes.hover}
                        />
                      </Typography>
                </Grid>
                </Grid>
              </TableCell>
              <TableCell>
                <Grid container>
                  <Grid item lg={12}>
                    <Typography>
                      <DeleteIcon
                        color="secondary"
                        onClick={deleteItem.bind(this, i)}
                        className={classes.hover}
                      />
                    </Typography>
                </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={vaccineData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
    </TableContainer>

    </React.Fragment>
  );
};

export default MTable;