import { Fragment } from 'react';
import  React,{useEffect,useState,useContext ,useRef} from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PersonalizedButton from "../../Layout/Button"
import FormControl from "@mui/material/FormControl";
import {numbersNotAllowed,emptyCheck,dateFormatter, dateIsValid} from "../../../methods/functions"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Stack from "@mui/material/Stack";
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import LabWorkContext from '../LabWork/labWork-context';
import CircularProgress from '@mui/material/CircularProgress';
export default function InsuranceRecord() {
  const inputRef = useRef(null);
  const [labWorkDateValue, setLabWorkDateValue] = React.useState(new Date());
  const [recordOption ,setRecordOption] = useState(0);
  const labWorkCtx = useContext(LabWorkContext);
  const [updated,setUpdatedField]=useState('');
  const [nameField, setNameField] = useState('');
  const [nameFieldError, setNameFieldError] = useState();
	const [selectedFile, setSelectedFile] = useState();
  const [downloadingMessage,setDownloadingMessage]= useState();
  const [dateError, setDateError] = useState();
  const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
	};

  const createURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_LABWORK_CREATE;
  const updateURL= process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_LABWORK_UPDATE;


  const handleChangeRecordOption = (event) => {
    if(event.target.value==0)
    {
      setRecordOption(0);
      setLabWorkDateValue(new Date());
    }
    setRecordOption(event.target.value);
  };
  const resetFileInput = () => {
    // 👇️ reset input value
    if (inputRef.current != null) {
      inputRef.current.value = null;
    }

  };
  const handleSubmitLabWork = (event) => {
    event.preventDefault();
    setNameFieldError(false);
    setDateError(false);
    let labWorkDate=labWorkDateValue;
    let finalDecision=true;
    let existsImage=0;
    let name=nameField;
    try
    {
      let exists=selectedFile.name.split(".")[0];
      existsImage=1;
    }
    catch
    {
      existsImage=0;
    }
    name= name? name :'';
    if(emptyCheck(name)){
      setNameFieldError(true);
      finalDecision=false;
    }

    if (isNaN(labWorkDate) || labWorkDate == null) {
      try {
        if (dateIsValid(labWorkDate) == false) {
          setDateError(true);
          finalDecision = false;
        }
      } catch {
        let today = new Date(labWorkDate);
        let yyyy = today.getFullYear();
        if (labWorkDate == null) {
          setDateError(true);
          finalDecision = false;
        }
        if (yyyy <= 1899) {
          setDateError(true);
          finalDecision = false;
        }
      }
    } else {
      let today = new Date(labWorkDate);
      let yyyy = today.getFullYear();
      if (yyyy <= 1899) {
        setDateError(true);
        finalDecision = false;
      }
    }

    if(finalDecision)
    {
      setDateError(false);
      setDownloadingMessage(true);
      setNameFieldError(false);
          let labWorkDatee= dateFormatter(labWorkDate);
          const getURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_GET_LABWORK_IMAGING;
          fetch(getURL ,{
            method: 'GET',
            headers: {  
            'Content-Type': 'application/json',
            'Token': localStorage.getItem("token"),
            'userid':localStorage.getItem("userId")     
            }
          })
          .then((response) => response.json())
          .then((data) => {
            let arrayX=undefined;
            try
            {
            arrayX= data.body[0].labWorkImaging;
            }
            catch
            {
              arrayX=[];
            }
            const result = arrayX ? arrayX.length : 0;

            if(result==0)
            {
                  let formdata = new FormData();
                  if(existsImage==1)
                  {
                    formdata.append("file", selectedFile);
                    formdata.append("existsImage", 1);
                  }
                  else{
                    formdata.append("existsImage", 0);
                  }
                  formdata.append("userId", localStorage.getItem("userId"));
                  formdata.append("name", name);
                  formdata.append("date", labWorkDatee);
                  let myHeaders = new Headers();
                  myHeaders.append("Token",localStorage.getItem("token"));
                  let requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata
                  };

                  try{
                    fetch(createURL, requestOptions)
                      .then(response => response.json())
                      .then( pleaseWait=> {
                           if(pleaseWait.message==="ok")
                            {
                              alert("Done");
                              setDownloadingMessage(false);
                              setLabWorkDateValue(new Date());
                              setSelectedFile([]);
                              resetFileInput();
                              setNameField("");
                              labWorkCtx.items=[];
                              labWorkCtx.addItem(labWorkCtx.items);
                            }
                            else
                            {
                              alert("Error");
                            }
                       }
                      );
                    }
                    catch{
                        alert("Error");
                    }  
            }
            else
            {          
                   if(recordOption==1){
                     //Update
                    setRecordOption(0);
                    const valuex= labWorkCtx;
                    let labWorkDB=[];
                    let originalArray=[];
                    originalArray=data.body;

                    for(let m in arrayX )
                    {
                      if(m==valuex.items.id)
                      {
                        labWorkDB.push(
                              {
                               date: labWorkDatee,
                               name: name,
                               filename: valuex.items.filename
                              }  
                         );
                      }
                      else
                      {
                        labWorkDB.push(
                          {
                           date: arrayX[m].date,
                           name:arrayX[m].name,
                           filename:arrayX[m].filename
                          }  
                      );
                      }
       
                     
                    }
                    let formdata = new FormData();

                    formdata.append("newRecord",0);
                    formdata.append("userId", localStorage.getItem("userId"));
                    formdata.append("documentId", originalArray[0].id);            
                    formdata.append("labWorkImagingArray", JSON.stringify(labWorkDB));
                    let myHeaders = new Headers();
                    myHeaders.append("Token",localStorage.getItem("token"));
                    let requestOptions = {
                      method: 'PUT',
                      headers: myHeaders,
                      body: formdata
                    };
                    try{
                      fetch(updateURL, requestOptions)
                          .then(response => response.json())
                          .then( pleaseWait=> {
                              if(pleaseWait.message==="ok")
                              {
                                alert("Done");
                                setDownloadingMessage(false);
                                setLabWorkDateValue(new Date());
                                setSelectedFile([]);
                                resetFileInput();
                                setNameField("");
                                labWorkCtx.items=[];
                                labWorkCtx.addItem(labWorkCtx.items);
                              }
                              else
                              {
                                alert("Error");
                              }
                           }
                         );
                    }
                    catch{
                        alert("Error");
                    }  
                   
                   labWorkCtx.addItem(labWorkCtx.items);
                   }
                   else{
                    let formdata = new FormData();
                    let extension;
                    if(existsImage==1)
                    {
                      let indexExtension=selectedFile.type.indexOf("/") +1;
                      let length=selectedFile.type.length;
                      extension= selectedFile.type.substring(indexExtension,length);
                      formdata.append("file", selectedFile);
                      formdata.append("filename", selectedFile.name.split(".")[0] + "_"+ Date.now() +"."+ extension );
                      formdata.append("existsImage", 1);
                    }
                    else
                    {
                      formdata.append("existsImage", 0);
                    }

                    const valuex= labWorkCtx;
                    let originalArray=[];
                 
                    originalArray=data.body; 
                    originalArray[0].labWorkImaging.push(
                      {
                        date: labWorkDatee,
                        name:name,
                        filename: existsImage==1?selectedFile.name.split(".")[0] + "_"+ Date.now() +"."+ extension :""
                      }
                    );
                 
                    formdata.append("labWorkImagingArray",JSON.stringify(originalArray[0].labWorkImaging));
                    formdata.append("newRecord",1);
                
                    formdata.append("userId", localStorage.getItem("userId"));
                    formdata.append("documentId", originalArray[0].id);
                    let myHeaders = new Headers();
                    myHeaders.append("Token",localStorage.getItem("token"));
                    let requestOptions = {
                      method: 'PUT',
                      headers: myHeaders,
                      body: formdata
                    };

                    try{
                          fetch(updateURL, requestOptions)
                          .then(response => response.json())
                          .then( pleaseWait=> {
                            if(pleaseWait.message==="ok")
                            {
                              alert("Done");
                              setDownloadingMessage(false);
                              setLabWorkDateValue(new Date());
                              setSelectedFile([]);
                              resetFileInput();
                              setNameField("");

                              labWorkCtx.items=[];
                              labWorkCtx.addItem(labWorkCtx.items);
                            }
                            else
                            {
                              alert("Error");
                            }
                          }
                          );
                    }
                    catch{
                        alert("Error");
                    }  

                    labWorkCtx.addItem(labWorkCtx.items);
                   }

            }
          }
          );
    }

    

  };
  
  
    useEffect(() => {
      if(updated==1)
      {
        setNameField("");
        setLabWorkDateValue(new Date());
        setRecordOption(0);
        setUpdatedField(0);
      }
      if(labWorkCtx.items.option==2)
      {
        let date= labWorkCtx.items.date?labWorkCtx.items.date:new Date();
        setLabWorkDateValue(date);
        setNameField(labWorkCtx.items.name);
        setRecordOption(1);
      }
      // eslint-disable-next-line
    }, [labWorkCtx]);


  const labWorkDateHandleChange = (newValue) => {
      setLabWorkDateValue(newValue);
  };

  const changeNameField = (event) => {
    setNameField(event.target.value);
  };


  return (
        <Box
        component="form"
         noValidate
        onSubmit={handleSubmitLabWork}
            sx={{
              my: 4,
              mx: 12,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >  
         { downloadingMessage?
          <div>
            <center>
          <Typography component="h2" variant="h5">
                  Please wait
          </Typography> 
          <div><CircularProgress /></div>
          </center>
          </div>:<React.Fragment></React.Fragment>
          }
          <FormControl sx={{ m: 3}}>
              <Typography component="h1" variant="h5">
                  Record
                </Typography>
                </FormControl>
                <FormControl sx={{ m: 2}}>
                      <RadioGroup
                        sx={{ display: 'inline' }}
                        aria-labelledby="record-radio-buttons-group-label"
                        name="record-radio-buttons-group"
                        value={recordOption}
                        onChange={handleChangeRecordOption}
                      >
                        <FormControlLabel value="0" control={<Radio />} label="Create" />
                        <FormControlLabel value="1" disabled control={<Radio />} label="Update" />
                      </RadioGroup>
              </FormControl>       
              <TextField
                        InputLabelProps={{ shrink: true }}
                        error= {nameFieldError? true:false}
                        sx={{ m: 2}}
                        margin="normal"
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        value={nameField}
                        onChange={changeNameField}
                                        
                />   
              {
                recordOption==0?
                <FormControl  sx={{ mt: 1, mx: 0 }} >
                <input  ref={inputRef} type="file" name="file" onChange={changeHandler} />
                </FormControl>
                :
                <React.Fragment></React.Fragment>
              }

            <FormControl sx={{ m: 3 }}>
                    <Stack spacing={4} sx={{ m: 1, mx: 0 }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          label="Date"
                          id="labWorkDate"
                          value={labWorkDateValue}
                          onChange={labWorkDateHandleChange}
                          inputFormat="MM/dd/yyyy"
                          renderInput={(params) => (
                            <TextField {...params} error={dateError ? true : false} />
                          )}
                        />
                      </LocalizationProvider>
                    </Stack>
              </FormControl>
              <PersonalizedButton  
              type="submit"
                variant="contained"
                 sx={{ mt: 2, mb: 2 }}
              >
                  Save
              </PersonalizedButton>    
        </Box>
  );
}
