import React, { useState ,useContext} from "react";
import {
  AppBar,
  Button,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DrawerComp from "./Drawer";
import { styled } from "@mui/material/styles";
import classesx from './MainNavigation.module.css';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../store/auth-context';
import classes from './MainNavigation.module.css';
import logo from '../../assets/OneMeLogo.png'
const ColorButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#4788B8",
  color:"white",
  "&:hover": {
    backgroundColor: "#4788B8"
  }
}));

const Header = () => {
  const history = useHistory();
  const authCtx = useContext(AuthContext);

  const isLoggedIn = authCtx.isLoggedIn;

  const logoutHandler = () => {
    authCtx.logout();
    history.replace('/');
  };

  const healthRecordHandler = () => {
    history.replace('/healthRecord');
  };

  const shareHealthRecordHandler = () => {
    history.replace('/shareHealthRecord');
  };
  const healthRecordSharedWithMeHandler = () => {
    history.replace('/healthRecordSharedWithMe');
  };
  const login = () => {
    history.replace('/');
  };


  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <React.Fragment>
      <AppBar >
        <Toolbar sx={{ background: "#ffffff" }}>
        {isMatch==false?
         <Link to='/home' style={{ textDecoration: 'none' }}>
          <img src={logo} height="50" alt="Smiley face"></img>
       </Link>
       :<></>
        }
        {isMatch  ? 
            <>
              <Typography sx={{ fontSize: "2rem", paddingLeft: "10%" }}>
              <Link to='/home' style={{ textDecoration: 'none' }}>
              <img src={logo} height="50" alt="Smiley face"></img>
                </Link>
              </Typography>
              <DrawerComp />
            </>
          :<></>
          }
          {isMatch && isMatch==false ? 
            <>
              <DrawerComp />
            </>
          :<></>
          }
          {!isLoggedIn && isMatch==false&& (
              <>
			       <ColorButton  sx={{ marginLeft: "auto" }} onClick={login} variant="contained">
              Login
              </ColorButton >
              </>
          )}
           {isLoggedIn && isMatch==false && (
            <>
             <ColorButton  sx={{ marginLeft: "auto" }} onClick={healthRecordHandler} variant="contained">
              Health Record
              </ColorButton >
            <ColorButton  sx={{ marginLeft: "10px" }} onClick={shareHealthRecordHandler} variant="contained">
              Share
              </ColorButton >
              <ColorButton  sx={{ marginLeft: "10px" }} onClick={healthRecordSharedWithMeHandler} variant="contained">
              Shared with me
              </ColorButton >
              <ColorButton  sx={{ marginLeft: "10px" }} onClick={logoutHandler} variant="contained">
                Logout
                </ColorButton >
            </>
          )}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default Header;