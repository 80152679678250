import  React,{useEffect,useState,useContext} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography
} from "@material-ui/core";
import TablePagination from '@mui/material/TablePagination';

import DeleteIcon from "@material-ui/icons/Delete";
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 250,
  },
  tableContainer: {
    borderRadius: 15,
    margin: "10px 10px",
    maxWidth: "400px",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    backgroundColor: "#4788B8",
    color: theme.palette.getContrastText(theme.palette.primary.dark)
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
  name: {
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
  },
  status: {
    fontWeight: "bold",
    fontSize: "0.75rem",
    color: "white",
    backgroundColor: "grey",
    borderRadius: 8,
    padding: "3px 10px",
    display: "inline-block",
  },
  hover: {
    cursor: "pointer",
  },
  center:{
    textAlign:'center'
  }
}));

const tableHeader = [
  { label: "Document", data: "id" },
  { label: "Medication Name", data: "name" },
  { label: "Dosage", data: "dosage" },
  { label: "Frequency", data: "frequency" },
  { label: "Date of first use", data: "dateOfFirstUse" },
  { label: "Date of last use", data: "dateOfLastUse" }
];

function createData(
  document,
  id,
  medicationName,
  dosage,
  frequency,
  dateOfFirstUse,
  dateOfLastUse,
  option
  ) {
  return {
    document,
    id,
    medicationName,
    dosage,
    frequency,
    dateOfFirstUse,
    dateOfLastUse,
    option
  };
}

const MTable = (props) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [medicationData, setData] = useState([]);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
    const getMedicationURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICATION_GET;
    useEffect(() => {
      let array=[];
          fetch(getMedicationURL ,{
            method: 'GET',
            headers: {  
            'Content-Type': 'application/json',
            'Token': localStorage.getItem("token"),
            'userid':props.userId   
            }
          })
          .then((response) => response.json())
          .then((data) => {   
               let arrayX;
               try
               {
               arrayX= data.body[0].medication;
               }
               catch
               {
                 arrayX=[];
               }
               for(let m in arrayX )
               {
                array.push(
                  createData(
                    data.body[0].id,
                    m,
                    data.body[0].medication[m].medicationName,
                    data.body[0].medication[m].dosage,
                    data.body[0].medication[m].frequency,
                    data.body[0].medication[m].dateOfFirstUse,
                    data.body[0].medication[m].dateOfLastUse
                    )
                );

               }
          setData(array);     
      })

      // eslint-disable-next-line
    }, []);


  const styleObj = {
    fontSize: 30,
    color: "black",
}
  return (
    <React.Fragment>
          <center><p>Medication</p></center>
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableHeader.map((cell) => (
              <TableCell key={cell.data} className={classes.tableHeaderCell}>
                {cell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
        {(rowsPerPage > 0
            ? medicationData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : medicationData
          ).map((row,i) => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.medicationName}</TableCell>
              <TableCell>{row.dosage}</TableCell>
              <TableCell>{row.frequency}</TableCell>
              <TableCell>{row.dateOfFirstUse}</TableCell>
              <TableCell>{row.dateOfLastUse}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={medicationData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
    </TableContainer>

    </React.Fragment>
  );
};

export default MTable;