import  React,{useEffect,useState,useContext} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography
} from "@material-ui/core";
import TablePagination from '@mui/material/TablePagination';
import { styled } from '@mui/material/styles';
import DeleteIcon from "@material-ui/icons/Delete";
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import ShareHealthRecordContext from './shareHealthRecord-context';
import Divider from "@mui/material/Divider";
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 250,
  },
  tableContainer: {
    borderRadius: 15,
    margin: "10px 10px",
    maxWidth: "400px",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    backgroundColor: "#4788B8",
    color: theme.palette.getContrastText(theme.palette.primary.dark)
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
  name: {
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
  },
  status: {
    fontWeight: "bold",
    fontSize: "0.75rem",
    color: "white",
    backgroundColor: "grey",
    borderRadius: 8,
    padding: "3px 10px",
    display: "inline-block",
  },
  hover: {
    cursor: "pointer",
  },
  center:{
    textAlign:'center'
  }
}));
const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& > :not(style) + :not(style)': {
    marginTop: theme.spacing(2),
  },
}));


const tableHeader = [
  { label: "Status", data: "Status" },
  { label: "Contact", data: "contact" },
  { label: "Edit", data: "whiteSpaceDelete" },
  { label: "Remove", data: "whiteSpaceUpdate" }
];

function createData(
  userId,
  status,
  granteeEmail,
  granteeUserId,
  contact,
  token,
  option
  ) {
  return {
    userId,
    status,
    granteeEmail,
    granteeUserId,
    contact,
    token,
    option
  };
}

const MTable = (props) => {
  const shareHealthRecordCtx = useContext(ShareHealthRecordContext);
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [shareHealthRecordData, setData] = useState([]);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_SHARED_BY;

    useEffect(() => {
      
      let array=[];
      fetch(getURL ,{
       method: 'GET',
       headers: {  
       'Content-Type': 'application/json',
       'Token': localStorage.getItem("token"),
       'userid':localStorage.getItem("userId")     
       }
     })
     .then((response) => response.json())
     .then((data) => {  

      for(let row in data.body.invites)
      {    
        if(data.body.invites[row].access.status.toLowerCase()!="inactive")
        {
          array.push(
            createData(
              localStorage.getItem("userId"),
              data.body.invites[row].access.status,
              data.body.invites[row].granteeEmail,
              data.body.invites[row].access.userId,
              data.body.invites[row].granteeName,
              data.body.invites[row].token
              )
          );
        }
      }

      setData(array);  
     });

      // eslint-disable-next-line
    }, [shareHealthRecordCtx.count]);

  const updateItem = (index) => {
    index.option=2;
    shareHealthRecordCtx.addItem(index);
  };

  const deleteItem = (index) => {
    const deleteShareHealtRecordDataURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_REMOVE_INVITE;

    let result= window.confirm("Are you sure?");
    if (result) {
      const newShareHealthRecordData= shareHealthRecordData.filter((data) => data.granteeEmail !==index.granteeEmail);
      let {granteeUserId,granteeEmail,token}= index;

      const deleteData = 
      {
       userId:granteeUserId,
       token: token,
       recipientEmail:granteeEmail
      };

      const requestOptions = {
        method: 'DELETE',
        headers: { 
          
          'Content-Type': 'application/json',
          "Token": localStorage.getItem("token")  
      
        },
            body: JSON.stringify(deleteData)
        };
        try{
          fetch(deleteShareHealtRecordDataURL, requestOptions)
              .then(response => response.json());
              alert("Done");

        }
        catch{
            alert("Error");
        }
      setData(newShareHealthRecordData);
    }
  };
  
  const styleObj = {
    fontSize: 30,
    color: "black",
}
  return (
    <React.Fragment>
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableHeader.map((cell) => (
              <TableCell key={cell.data} className={classes.tableHeaderCell}>
                {cell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
        {(rowsPerPage > 0
            ? shareHealthRecordData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : shareHealthRecordData
          ).map((row,i) => (
            <TableRow key={Math.random()} >
              <TableCell>{row.status}</TableCell>
              <TableCell>{row.contact}</TableCell>
              <TableCell>
                <Grid container>
                  <Grid item lg={12}>
                    <Typography>
                      <AutoFixNormalIcon
                        color="primary"
                        onClick={updateItem.bind(this, row)}
                        className={classes.hover}
                        />
                      </Typography>
                </Grid>
                </Grid>
              </TableCell>
              <TableCell>
                <Grid container>
                  <Grid item lg={12}>
                    <Typography>
                      <DeleteIcon
                        color="secondary"
                        onClick={deleteItem.bind(this, row)}
                        className={classes.hover}
                      />
                    </Typography>
                </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={shareHealthRecordData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
    </TableContainer>

    </React.Fragment>
  );
};

export default MTable;