import ShareHealthRecord from '../components/ShareHealthRecord/ShareHealthRecord';
import ShareHealthRecordCategory from '../components/ShareHealthRecord/ShareHealthRecord/ShareHealthRecordCategory';
import  React,{useEffect,useState,useContext } from 'react';
import MainNavigation from '../../src/components/Layout/MainNavigation';
import { Fragment } from 'react';

const ShareHealthRecordPage = () => {
  return (
    <Fragment>
      <MainNavigation />
      <ShareHealthRecord>
      <ShareHealthRecordCategory />
      </ShareHealthRecord>
  </Fragment>
  );
};

export default ShareHealthRecordPage;
