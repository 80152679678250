import { useReducer } from 'react';
import React  from 'react';
import ShareHealthRecordContext from './shareHealthRecord-context';
import Divider from "@mui/material/Divider";
import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& > :not(style) + :not(style)': {
      marginTop: theme.spacing(2),
    },
  }));

const defaultShareHealthRecordState = {
  items: [],
  count: 0,
  report:0
};

const cartReducer = (state, action) => {
  
  if (action.type === 'ADD') {

    if(typeof action.item !== 'undefined' && action.item.length === 0)
    {
      return {
        count:state.count+1,
        items: action.item,
        report:state.report+1
      };
    }
    else
    {
      return {
        count:state.count+1,
        items: action.item,
        report:0
      };
    }

  }

  return defaultShareHealthRecordState;
};

const ShareHealthRecordProvider = (props) => {
  const [shareHealthRecordState, dispatchCartAction] = useReducer(
    cartReducer,
    defaultShareHealthRecordState
  );

  const addItemToRecordHandler = (item) => {
    dispatchCartAction({ type: 'ADD', item: item });
  };

  const cartContext = {
    items: shareHealthRecordState.items,
    count: shareHealthRecordState.count,
    report: shareHealthRecordState.report,
    addItem: addItemToRecordHandler
  };

  return (
    <ShareHealthRecordContext.Provider value={cartContext}>
      <React.Fragment >
      {props.children}
      </React.Fragment>
    </ShareHealthRecordContext.Provider>
  );
};

export default ShareHealthRecordProvider;
