import React  from 'react';
import { Fragment } from 'react';
import LabWorkRecord from './LabWorkRecord';
import LabWorkReport from './LabWorkReport';
import LabWorkProvider from './labWork-Provider';

const LabWorkCategory = () => {
  return (
      <LabWorkProvider>
           <LabWorkReport/>
            <LabWorkRecord/>
      </LabWorkProvider>

  );
};

export default LabWorkCategory;
