import { Fragment } from 'react';
import  React,{useEffect,useState,useContext } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PersonalizedButton from "../../Layout/Button"
import FormControl from "@mui/material/FormControl";
import {numbersNotAllowed,emptyCheck,dateFormatter} from "../../../methods/functions"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Stack from "@mui/material/Stack";
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { isExists } from 'date-fns';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MedicalHistoryContext from '../MedicalHistory/medicalHistory-context';

export default function MedicalHistoryRecord() {
  const [arrayFieldCategory ,setArrayCategory] = React.useState([]);
  const [field,setFieldCategory] = React.useState(0);
  const [showSubCategory,setShowSubCategory]=  React.useState(0);
  const [arrayFieldSubCategory ,setArraySubCategory] = React.useState([]);
  const [arrayFieldSubCategoryObject ,setArraySubCategoryObject] = React.useState([]);
  const [fieldSubCategory,setFieldSubCategory] = React.useState(0);
  const [recordOption ,setRecordOption] = useState(0);
  const medicalHistoryCtx = useContext(MedicalHistoryContext);
  const [updated,setUpdatedField]=useState('');

  const [showSubName,setShowSubName]=  React.useState(0);
  const [subNameField, setSubNameField] = useState('');
  const [subNameFieldError, setSubNameFieldError] = useState();



  const createURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICAL_HISTORY_CREATE;

  function getData(){
    const getCatalog=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICAL_HISTORY_GET_DISEASE_CATALOG;
    return fetch(getCatalog ,{
     method: 'GET',
     headers: {  
     'Content-Type': 'application/json',
     'Token': localStorage.getItem("token"),
     'userid':localStorage.getItem("userId")     
     }
   })
   .then((response) => response.json())
   .then((data) => { 
    let arrCategory=[];
    let arrSubCategory=[];
    let count=0;
    let subCount=0;
    let datax=undefined;
    let objectSubCategory=[];
    let objectCategory=[];
    let existsSubCategory=0;
    let arrayX=[];
    try
    {
     datax= data.body.category;

    }
    catch
    {
      datax=[];
    }

    for(let category in datax)
    {
        count=count+1;
        try
        {
          existsSubCategory = datax[category].subCategory.length; 
          }  
          catch
          {
            existsSubCategory=-1;
          }

          if(datax[category].name.toLowerCase()=="other")
          {
            arrCategory.push({
                  index: count,
                  category:"Other",
                  label:"Other",
                  key: parseInt(category)
                })

          }
          else
          {
                    arrCategory.push({
                      index: count,
                      category:datax[category].name,
                      label:datax[category].name,
                      key: parseInt(category)
                    })              
          } 
        
          objectCategory=[];

          if(existsSubCategory>0)
          {
       
            for(let subCategory in datax[category].subCategory)
            { 
                arrSubCategory.push({
                  indexSubCategory: subCount++,
                  category:category,
                  label:datax[category].subCategory[subCategory],
                  key: parseInt(subCategory)
                });

            }
            objectSubCategory[category]=arrSubCategory;
            setArraySubCategoryObject(objectSubCategory);
            if(category==0)
            {
              setArraySubCategory(arrSubCategory);
            }

          }
          arrSubCategory=[];

          subCount=0;
    }
    setArrayCategory(arrCategory);
    setShowSubCategory(1);
   });
  }

  const handleChangeRecordOption = (event) => {
    if(event.target.value==0)
    {
      setRecordOption(0);
      setSubNameField('');
      setShowSubCategory(0);
      setShowSubName(0);
      setFieldCategory(0);
      setFieldSubCategory(0);
    }
    setRecordOption(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubNameFieldError(false);
    let category=field;
    let subCategory=fieldSubCategory;
    let finalDecision=true;

    if(showSubCategory==0)
    {
      if(arrayFieldCategory[category].category.toLowerCase().toString()=="other"){
        if(emptyCheck(subNameField) || !numbersNotAllowed(subNameField)){
          setSubNameFieldError(true);
          finalDecision=false;
        }
      }
    }

    if(finalDecision)
    {
      setSubNameFieldError(false);
          const getURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICAL_HISTORY_GET;
          fetch(getURL ,{
            method: 'GET',
            headers: {  
            'Content-Type': 'application/json',
            'Token': localStorage.getItem("token"),
            'userid':localStorage.getItem("userId")     
            }
          })
          .then((response) => response.json())
          .then((data) => {
            let arrayX=undefined;
            let firstime=0;
            try
            {
            arrayX= data.body[0].diseases;
            }
            catch
            {
              arrayX=[];
              firstime=1;
            }
            const result = arrayX ? arrayX.length : 0;

            if(showSubName!=1)
            {
              subCategory=subCategory==-1?"":subCategory;
            }
            else
            {
              subCategory=subNameField;
            }

            if(result==0 && firstime==1)
            {     
              let obgyn=undefined;
              try
              {
                obgyn=data.body[0].obgyn;
              }
              catch{
                obgyn=[]
              }
                  const medicalHistoryData={
                    userId:localStorage.getItem("userId"),
                    diseases: [
                      {
                            dateOfSurgery: "",
                            category: parseInt(category),
                            subCategory: subCategory
                      }
                    ],
                    obgyn:obgyn
                  };

                const requestOptions = {
                  method: 'POST',
                  headers: { 
                    
                    'Content-Type': 'application/json',
                    "Token": localStorage.getItem("token")  
                
                  },
                      body: JSON.stringify(medicalHistoryData)
                  };
                  try{
                    fetch(createURL, requestOptions)
                        .then(response => response.json());
                        alert("Done");
                        setFieldCategory(0);
                        setFieldSubCategory(0);
                        setSubNameField('');
                        setShowSubName(0);
                        setShowSubCategory(0);
                        medicalHistoryCtx.items=[];
                        medicalHistoryCtx.addItem(medicalHistoryCtx.items);
                  }
                  catch{
                      alert("Error");
                  }  

            }
            else
            {
                   if(recordOption==1){
                     //Update
                    setRecordOption(0);
                    const valuex= medicalHistoryCtx;
                    let diseases=[];
                    let originalArray=[];
                    originalArray=data.body;

                    for(let m in arrayX )
                    {
                      if(m==valuex.items.id)
                      {
                             diseases.push(
                              {
                               dateOfSurgery: "",
                               category: parseInt(category),
                               subCategory: subCategory
                              }  
                         );
                      }
                      else
                      {
                        diseases.push(
                          {
                           dateOfSurgery: "",
                           category: arrayX[m].category,
                           subCategory: arrayX[m].subCategory
                          }  
                      );
                      }
       
                     
                    }
                    let obgyn=undefined;
                    try
                    {
                      obgyn=data.body[0].obgyn;
                    }
                    catch{
                      obgyn=[]
                    }

                    originalArray[0].documentId=originalArray[0].id;
                    originalArray[0].obgyn=obgyn;
                    originalArray[0].diseases=diseases;

                      const update=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICAL_HISTORY_UPDATE;
                      const requestOptions = {
                        method: 'PUT',
                        headers: { 
                          
                          'Content-Type': 'application/json',
                          "Token": localStorage.getItem("token")
                        },
                          body: JSON.stringify(originalArray[0])
                    };

                      try{
                      fetch(update, requestOptions)
                      .then(response => response.json());
                      medicalHistoryCtx.items=[];
                      setUpdatedField(1);
                      medicalHistoryCtx.report=medicalHistoryCtx.report+1;
                      medicalHistoryCtx.addItem(medicalHistoryCtx.items);
        
                      }
                      catch
                      {
                      alert("Error");
                      }

                   setSubNameField('');
                   setShowSubName(0);
                   setShowSubCategory(0);
                   setFieldCategory(0);
                   setFieldSubCategory(0);
                   alert("Done");
                   
                   medicalHistoryCtx.addItem(medicalHistoryCtx.items);
                   }
                   else{
                    const valuex= medicalHistoryCtx;
                    let originalArray=[];
 
                    originalArray=data.body;

                    originalArray[0].diseases.push(
                      {
                        dateOfSurgery: "",
                        category: parseInt(category),
                        subCategory: subCategory
                      }
                    );

                    originalArray[0].documentId=originalArray[0].id;

                    const update=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICAL_HISTORY_UPDATE;
                    const requestOptions = {
                      method: 'PUT',
                      headers: { 
                        
                        'Content-Type': 'application/json',
                        "Token": localStorage.getItem("token")
                      },
                        body: JSON.stringify(originalArray[0])
                   };

                    try{
                    fetch(update, requestOptions)
                    .then(response => response.json());
                    medicalHistoryCtx.items=[];
                    setUpdatedField(1);
                    medicalHistoryCtx.report=medicalHistoryCtx.report+1;
                    }
                    catch
                    {
                     alert("Error");
                    }
                    setSubNameField('');
                    setShowSubName(0);
                    setShowSubCategory(0);
                    setFieldCategory(0);
                    setFieldSubCategory(0);
                    alert("Done");
                   
                   medicalHistoryCtx.addItem(medicalHistoryCtx.items);
                   }

            }
          }
          );
    }

    

  };
  
    useEffect(() => {
      setShowSubName(0);
      if(updated==1)
      {
        setSubNameField('');
        setShowSubName(0);
        setShowSubCategory(1);
        setFieldCategory(0);
        setFieldSubCategory(0);
        setRecordOption(0);
        setUpdatedField(0);
      }
      if(medicalHistoryCtx.items.option==2)
      {
        setFieldCategory(medicalHistoryCtx.items.categoryId);
        let subCount=0;
        let verification=-1;
        let arrXSubCategory=[];
        for(let row in arrayFieldSubCategoryObject[medicalHistoryCtx.items.categoryId])
        {
            verification=0;
            arrXSubCategory.push({
              indexSubCategory: subCount++,
              category:parseInt(medicalHistoryCtx.items.categoryId),
              label:arrayFieldSubCategoryObject[medicalHistoryCtx.items.categoryId][row].label,
              key: parseInt(arrayFieldSubCategoryObject[medicalHistoryCtx.items.categoryId][row].key)
            });
        }
        if(verification!=-1)
        {
          setArraySubCategory(arrXSubCategory);
          setFieldSubCategory(medicalHistoryCtx.items.subCategoryId);
          setShowSubCategory(1);
          setShowSubName(0);
        }
        else
        {
          if(medicalHistoryCtx.items.subCategoryId==-2){
             setShowSubName(1);
             setShowSubCategory(0);
             setSubNameField(medicalHistoryCtx.items.subCategoryName);
          }
          else
          {
            setShowSubCategory(0);
          }
        }

        setRecordOption(1);
      }
      else{
        setShowSubName(0);
        setShowSubCategory(1);
        if(arrayFieldCategory.length==0){
          getData();
          setShowSubCategory(0);
        }
        else
        {
          getData();
        }
      }
      // eslint-disable-next-line
    }, [medicalHistoryCtx]);



  const handleChangeOptionCategory = (event) => {
    const newArray= arrayFieldCategory;
    let arrSubCategoryX=[];

    let subCount=0;
    let categoryId=event.target.value;

    for(let row in arrayFieldSubCategoryObject[categoryId])
    {
        arrSubCategoryX.push({
          indexSubCategory: subCount++,
          category:newArray[(event.target.value)].key,
          label:arrayFieldSubCategoryObject[categoryId][row].label,
          key:arrayFieldSubCategoryObject[categoryId][row].key
        });

    }

    if(arrSubCategoryX.length>0 )
    {
      setArraySubCategory(arrSubCategoryX);
      setShowSubCategory(1);
      setShowSubName(0);
      setFieldSubCategory(0);
    }
    else
    {
      setShowSubCategory(0);
      setArraySubCategory([]);
      setFieldSubCategory(-1);
      if(newArray[(event.target.value)].label.toLowerCase()=="other")
      {
          setShowSubName(1);
      }
      else
      {
        setShowSubName(0);
      }
    }

    setFieldCategory(event.target.value);
  };

  const handleChangeOptionSubCategory = (event) => {

    setFieldSubCategory(event.target.value);
  };
  const changeSubNameField = (event) => {
    setSubNameField(event.target.value);
};

  return (
        <Box
        component="form"
         noValidate
        onSubmit={handleSubmit}
            sx={{
              my: 4,
              mx: 12,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >  
              <Typography component="h1" variant="h5">
                  Record
                </Typography>
                <FormControl sx={{ m: 2}}>
                      <RadioGroup
                        sx={{ display: 'inline' }}
                        aria-labelledby="record-radio-buttons-group-label"
                        name="record-radio-buttons-group"
                        value={recordOption}
                        onChange={handleChangeRecordOption}
                      >
                        <FormControlLabel value="0" control={<Radio />} label="Create" />
                        <FormControlLabel value="1" disabled control={<Radio />} label="Update" />
                      </RadioGroup>
              </FormControl>
              <FormControl  sx={{ mt: 0, mx: 0 }} >
              </FormControl>
              <React.Fragment>
              <FormControl  sx={{ mt: 1}} >
              <InputLabel sx={{ mt: 1 }} id="optionLabel">Category</InputLabel>
              <Select
                        labelId="optionLabel"
                        id="option"
                        label="Option"
                        value={field}
                        onChange={handleChangeOptionCategory}
                        >
                            {
                               arrayFieldCategory.map((option) => (
                                  <MenuItem  key={option.key} value={option.key}>{option.label}</MenuItem>
                                ))
                            }
              </Select>
             </FormControl>
             </React.Fragment>
             {
                  showSubCategory==1?
              <FormControl  sx={{ mt: 2}} >
              <InputLabel sx={{ mt: 1 }} id="optionSubCategoryLabel">SubCategory</InputLabel>
              <Select
                        labelId="optionSubCategoryLabel"
                        id="optionSubCategory"
                        label="option"
                        value={fieldSubCategory}
                        onChange={handleChangeOptionSubCategory}
                        >
                            {
                               arrayFieldSubCategory.map((optionSubCategory) => (
                                  <MenuItem  key={optionSubCategory.key} value={optionSubCategory.key}>{optionSubCategory.label}</MenuItem>
                                ))
                            }
              </Select>
             </FormControl>
                :   
                <React.Fragment></React.Fragment>
            }
             {
              showSubName==1?
             <FormControl  sx={{ mt: 3, mx: 0 }} >
             <TextField
                        InputLabelProps={{ shrink: true }}
                        error= {subNameFieldError? true:false}
                        sx={{ m: 0}}
                        margin="normal"
                        id="subNameField"
                        label="SubCategory"
                        name="subNameField"      
                        value={subNameField}
                        onChange={changeSubNameField}      
                />    
              </FormControl>                        :   
              <React.Fragment></React.Fragment>
              }
              <PersonalizedButton  
              type="submit"
                variant="contained"
                 sx={{ mt: 2, mb: 2 }}
              >
                  Save
              </PersonalizedButton>    
        </Box>
  );
}
