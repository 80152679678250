import { Fragment } from 'react';
import  React,{useEffect,useState,useContext } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PersonalizedButton from "../../../Layout/Button"
import FormControl from "@mui/material/FormControl";
import {numbersNotAllowed,emptyCheck,dateFormatter} from "../../../../methods/functions"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Stack from "@mui/material/Stack";
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { isExists } from 'date-fns';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import GynecologicalHistoryContext from '../GynecologicalHistory/gynecologicalHistory-context';

export default function GynecologicalHistoryRecord() {
  const [arrayFieldCategory ,setArrayCategory] = React.useState([]);
  const [field,setFieldCategory] = React.useState(0);
  const [recordOption ,setRecordOption] = useState(0);
  const gynecologicalHistoryCtx = useContext(GynecologicalHistoryContext);
  const [updated,setUpdatedField]=useState('');

  const [showSubName,setShowSubName]=  React.useState(0);
  const [subNameField, setSubNameField] = useState('');
  const [subNameFieldError, setSubNameFieldError] = useState();

  const createURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICAL_HISTORY_CREATE;

  function getData(){
    const getCatalog=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICAL_HISTORY_GET_GYNECOLOGICAL_CATALOG;
    return fetch(getCatalog ,{
     method: 'GET',
     headers: {  
     'Content-Type': 'application/json',
     'Token': localStorage.getItem("token"),
     'userid':localStorage.getItem("userId")     
     }
   })
   .then((response) => response.json())
   .then((data) => {

    let arrCategory=[];
    let count=0;
    let datax=undefined;
    let objectCategory=[];
    
    try
    {
     datax= data.body.diseases;

    }
    catch
    {
      datax=[];
    }

    for(let category in datax)
    {
        count=count+1;


          if(datax[category].toLowerCase()=="other")
          {
            arrCategory.push({
                  index: count,
                  category:"Other",
                  label:"Other",
                  key: parseInt(category)
                })

          }
          else
          {
                    arrCategory.push({
                      index: count,
                      category:datax[category],
                      label:datax[category],
                      key: parseInt(category)
                    })              
          } 
        
          objectCategory=[];

    }

    setArrayCategory(arrCategory);
   });
  }

  const handleChangeRecordOption = (event) => {
    if(event.target.value==0)
    {
      setRecordOption(0);
      setSubNameField('');
      setShowSubName(0);
      setFieldCategory(0);
    }
    setRecordOption(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubNameFieldError(false);
    let category=field;
    let subCategory=subNameField;
    let finalDecision=true;

    if(showSubName==1)
    {
      if(arrayFieldCategory[category].category.toLowerCase().toString()=="other"){
        if(emptyCheck(subCategory) || !numbersNotAllowed(subCategory)){
          setSubNameFieldError(true);
          finalDecision=false;
        }
      }
    }
   
    if(finalDecision)
    {
      setSubNameFieldError(false);
          const getURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICAL_HISTORY_GET;
          fetch(getURL ,{
            method: 'GET',
            headers: {  
            'Content-Type': 'application/json',
            'Token': localStorage.getItem("token"),
            'userid':localStorage.getItem("userId")     
            }
          })
          .then((response) => response.json())
          .then((data) => {
            let arrayX=undefined;
            let arrayDisease=undefined;
            let id=undefined;
            try
            {
            arrayX= data.body[0].obgyn.gynecologicalHistory;
            id=data.body[0].id;
            arrayDisease= data.body[0].diseases;
            }
            catch
            {
              arrayX=[];
              id=0;
            }
            const update=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICAL_HISTORY_UPDATE;

            const result = arrayX ? arrayX.length : 0;
           
            if(showSubName!=1)
            {
              subCategory=subCategory==-1?"":subCategory;
            }
            else
            {
              subCategory=subNameField;
            }
            if(id==0)
            {
              let abnormalPapSmear=null;
              let ageFirstPeriod=null;
              let concernSexualActivity=null;
              let mammogram=null;
              let numberOfPregnancies=null;
              let pregnancy=null;
              try
              {
                abnormalPapSmear=arrayX[0].obgyn.abnormalPapSmear;
                ageFirstPeriod=arrayX[0].obgyn.ageFirstPeriod;
                concernSexualActivity=arrayX[0].obgyn.concernSexualActivity;
                mammogram=arrayX[0].obgyn.mammogram;
                numberOfPregnancies=arrayX[0].obgyn.numberOfPregnancies;
                pregnancy=arrayX[0].obgyn.pregnancy;
              }
              catch{

              }
              const medicalHistoryData={
                userId:localStorage.getItem("userId"),
                diseases: [],
                obgyn:{
                  gynecologicalHistory:[
                      {
                      category: parseInt(category),
                      subCategory: subCategory
                    }]
                  ,
                  abnormalPapSmear:abnormalPapSmear,
                  ageFirstPeriod:ageFirstPeriod,
                  concernSexualActivity:concernSexualActivity,
                  mammogram:mammogram,
                  numberOfPregnancies:numberOfPregnancies,
                  pregnancy:pregnancy
               
              }
              };

     
                  const requestOptions = {
                    method: 'POST',
                    headers: { 
                      
                      'Content-Type': 'application/json',
                      "Token": localStorage.getItem("token")  
                  
                    },
                        body: JSON.stringify(medicalHistoryData)
                    };
                    try{
                      fetch(createURL, requestOptions)
                          .then(response => response.json());
                          alert("Done");
                          setFieldCategory(0);
                          setSubNameField('');
                          setShowSubName(0);
                          gynecologicalHistoryCtx.items=[];
                          gynecologicalHistoryCtx.addItem(gynecologicalHistoryCtx.items);
                    }
                    catch{
                        alert("Error");
                    }  
              

            }
            else{      
              if(result==0)
              {
                if(showSubName==0)
                {
                  subCategory="";
                }
                let originalArray=[];

                originalArray=data.body;
                originalArray[0].obgyn={
                    gynecologicalHistory:[
                      {
                      category: parseInt(category),
                      subCategory: subCategory
                    }]
                  ,
                  abnormalPapSmear:originalArray[0].obgyn.abnormalPapSmear,
                  ageFirstPeriod:originalArray[0].obgyn.ageFirstPeriod,
                  concernSexualActivity:originalArray[0].obgyn.concernSexualActivity,
                  mammogram:originalArray[0].obgyn.mammogram,
                  numberOfPregnancies:originalArray[0].obgyn.numberOfPregnancies,
                  pregnancy:originalArray[0].obgyn.pregnancy
                  
                  };
               
                originalArray[0].documentId=originalArray[0].id;

                    const requestOptions = {
                      method: 'PUT',
                      headers: { 
                        
                        'Content-Type': 'application/json',
                        "Token": localStorage.getItem("token")
                      },
                        body: JSON.stringify(originalArray[0])
                  };
                  try{
                    fetch(update, requestOptions)
                    .then(response => response.json());
                      alert("Done");
                      setFieldCategory(0);
                      setSubNameField('');
                      setShowSubName(0);
                      gynecologicalHistoryCtx.items=[];
                      gynecologicalHistoryCtx.addItem(gynecologicalHistoryCtx.items);
      
                    }
                    catch
                    {
                    alert("Error");
                    }
                
              
              }
              else
              {

                    if(recordOption==1){
                      if(showSubName==0)
                      {
                        subCategory="";
                      }
                      //Update
                      setRecordOption(0);
                      const valuex= gynecologicalHistoryCtx;
                      let gynecologicalHistory=[];
                      let originalArray=[];
                      originalArray=data.body;

                      for(let m in arrayX )
                      {
                        if(m==valuex.items.id)
                        {
                          gynecologicalHistory.push(
                                {
                                category: parseInt(category),
                                subCategory: subCategory
                                }  
                          );
                        }
                        else
                        {
                          gynecologicalHistory.push(
                            {
                            category: arrayX[m].category,
                            subCategory: arrayX[m].subCategory
                            }  
                        );
                        }
        
                      
                      }
    
                      originalArray[0].documentId=originalArray[0].id;
                      originalArray[0].obgyn.gynecologicalHistory=gynecologicalHistory;

                        const update=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICAL_HISTORY_UPDATE;
                        const requestOptions = {
                          method: 'PUT',
                          headers: { 
                            
                            'Content-Type': 'application/json',
                            "Token": localStorage.getItem("token")
                          },
                            body: JSON.stringify(originalArray[0])
                      };
                     
                        try{
                        fetch(update, requestOptions)
                        .then(response => response.json());
                        gynecologicalHistoryCtx.items=[];
                        setUpdatedField(1);
                        gynecologicalHistoryCtx.report=gynecologicalHistoryCtx.report+1;
                        gynecologicalHistoryCtx.addItem(gynecologicalHistoryCtx.items);
          
                        }
                        catch
                        {
                        alert("Error");
                        }
                     
                    setSubNameField('');
                    setShowSubName(0);
                    setFieldCategory(0);
                    
                    gynecologicalHistoryCtx.addItem(gynecologicalHistoryCtx.items);
                    }
                    else{
                      let originalArray=[];
  
                      originalArray=data.body;
                      let gynecologicalHistory=[] ;
                  
                      for(let row in originalArray[0].obgyn.gynecologicalHistory )
                      {
                        gynecologicalHistory.push( {
                          category: parseInt(originalArray[0].obgyn.gynecologicalHistory[row].category),
                          subCategory: originalArray[0].obgyn.gynecologicalHistory[row].subCategory
                        });
                      }
                      if(showSubName==0)
                      {
                        subCategory="";
                      }
                      gynecologicalHistory.push( {
                        category: parseInt(category),
                        subCategory: subCategory
                      });

                      originalArray[0].obgyn.gynecologicalHistory= gynecologicalHistory;
                           
                      originalArray[0].documentId=originalArray[0].id;
                      
                      const update=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICAL_HISTORY_UPDATE;
                      const requestOptions = {
                        method: 'PUT',
                        headers: { 
                          
                          'Content-Type': 'application/json',
                          "Token": localStorage.getItem("token")
                        },
                          body: JSON.stringify(originalArray[0])
                    };

                      try{
                      fetch(update, requestOptions)
                      .then(response => response.json());
                      gynecologicalHistoryCtx.items=[];
                      setUpdatedField(1);
                      gynecologicalHistoryCtx.report=gynecologicalHistoryCtx.report+1;
                      }
                      catch
                      {
                      alert("Error");
                      }
                     
                      setSubNameField('');
                      setShowSubName(0);
                      setFieldCategory(0);
                      alert("Done");
                    
                      gynecologicalHistoryCtx.addItem(gynecologicalHistoryCtx.items);
                    }
              }
            }
          }
          );
    }
  
    

  };
  
    useEffect(() => {
      setShowSubName(0);
      if(updated==1)
      {
        setSubNameField('');
        setShowSubName(0);
        setFieldCategory(0);
        setRecordOption(0);
        setUpdatedField(0);
      }
      if(gynecologicalHistoryCtx.items.option==2)
      {
        setFieldCategory(gynecologicalHistoryCtx.items.categoryId);
        let subCount=0;
        let verification=-1;
        let arrXSubCategory=[];

        if(verification!=-1)
        {
          setShowSubName(0);
        }
        else
        {
          if(gynecologicalHistoryCtx.items.subCategoryId==-2){
             setShowSubName(1);
             setSubNameField(gynecologicalHistoryCtx.items.subCategoryName);
          }
          else
          {
            setShowSubName(0);
          }
        }

        setRecordOption(1);
      }
      else{
        setShowSubName(0);
        if(arrayFieldCategory.length==0){
          getData();
        }
      }
      // eslint-disable-next-line
    }, [gynecologicalHistoryCtx]);



  const handleChangeOptionCategory = (event) => {
    const newArray= arrayFieldCategory;
    let arrSubCategoryX=[];

    let subCount=0;
    let categoryId=event.target.value;

    if(arrSubCategoryX.length>0 )
    {
      setShowSubName(0);
    }
    else
    {
      if(newArray[(event.target.value)].label.toLowerCase()=="other")
      {
          setShowSubName(1);
      }
      else
      {
        setShowSubName(0);
      }
    }

    setFieldCategory(event.target.value);
  };

  const changeSubNameField = (event) => {
    setSubNameField(event.target.value);
};
  return (
        <Box
        component="form"
         noValidate
        onSubmit={handleSubmit}
            sx={{
              my: 4,
              mx: 12,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >  
              <Typography component="h1" variant="h5">
                  Record
                </Typography>
                <FormControl sx={{ m: 2}}>
                      <RadioGroup
                        sx={{ display: 'inline' }}
                        aria-labelledby="record-radio-buttons-group-label"
                        name="record-radio-buttons-group"
                        value={recordOption}
                        onChange={handleChangeRecordOption}
                      >
                        <FormControlLabel value="0" control={<Radio />} label="Create" />
                        <FormControlLabel value="1" disabled control={<Radio />} label="Update" />
                      </RadioGroup>
              </FormControl>
              <FormControl  sx={{ mt: 0, mx: 0 }} >
              </FormControl>
              <React.Fragment>
              <FormControl  sx={{ mt: 1}} >
              <InputLabel sx={{ mt: 1 }} id="optionLabel">Category</InputLabel>
              <Select
                        labelId="optionLabel"
                        id="option"
                        label="Option"
                        value={field}
                        onChange={handleChangeOptionCategory}
                        >
                            {
                               arrayFieldCategory.map((option) => (
                                  <MenuItem  key={option.key} value={option.key}>{option.label}</MenuItem>
                                ))
                            }
              </Select>
             </FormControl>
             </React.Fragment>
             {
              showSubName==1?
             <FormControl  sx={{ mt: 3, mx: 0 }} >
             <TextField
                        InputLabelProps={{ shrink: true }}
                        error= {subNameFieldError? true:false}
                        sx={{ m: 0}}
                        margin="normal"
                        id="subNameField"
                        label="SubCategory"
                        name="subNameField"      
                        value={subNameField}
                        onChange={changeSubNameField}      
                />    
              </FormControl>                        :   
              <React.Fragment></React.Fragment>
              }
              <PersonalizedButton  
              type="submit"
                variant="contained"
                 sx={{ mt: 2, mb: 2 }}
              >
                  Save
              </PersonalizedButton>    
        </Box>
  );
}
