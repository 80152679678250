import HealthRecordSharedWithMe from '../components/HealthRecordSharedWithMe/HealthRecordSharedWithMe';
import HealthRecordSharedWithMeCategory from '../components/HealthRecordSharedWithMe/HealthRecordSharedWithMe/HealthRecordSharedWithMeCategory';
import  React,{useEffect,useState,useContext } from 'react';
import MainNavigation from '../components/Layout/MainNavigation';
import { Fragment } from 'react';

const HealthRecordSharedWithMePage = () => {
  return (
    <Fragment>
      <MainNavigation />
      <HealthRecordSharedWithMe>
      <HealthRecordSharedWithMeCategory />
      </HealthRecordSharedWithMe>
  </Fragment>
  );
};

export default HealthRecordSharedWithMePage;
