import  React,{useEffect,useState,useContext} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography
} from "@material-ui/core";
import TablePagination from '@mui/material/TablePagination';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 250,
  },
  tableContainer: {
    borderRadius: 15,
    margin: "10px 10px",
    maxWidth: "400px",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    backgroundColor: "#4788B8",
    color: theme.palette.getContrastText(theme.palette.primary.dark)
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
  name: {
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
  },
  status: {
    fontWeight: "bold",
    fontSize: "0.75rem",
    color: "white",
    backgroundColor: "grey",
    borderRadius: 8,
    padding: "3px 10px",
    display: "inline-block",
  },
  hover: {
    cursor: "pointer",
  },
  center:{
    textAlign:'center'
  }
}));

const tableHeader = [
  { label: "Document Number", data: "documentId" },
  { label: "Name", data: "name" },
  { label: "MiddleName", data: "middleName" },
  { label: "LastName", data: "lastName" },
  { label: "Date Of Birth", data: "dateOfBirth" },
  { label: "Gender", data: "gender" },
  { label: "Zip Code", data: "zipCode" },
  { label: "Country", data: "country" },
  { label: "State", data: "state" },
  { label: "City", data: "city" },
  { label: "Street", data: "street" },
  { label: "Email", data: "email" },
  { label: "Telephone Number", data: "telephoneNumber" }
];

function createData(
  documentId,
  name,
  middleName,
  lastName,
  dateOfBirth,
  gender,
  country,
  state,
  city,
  zipCode,
  street,
  email,
  telephoneNumber,
  option
  ) {
  return {
    documentId,
    name,
    middleName,
    lastName,
    dateOfBirth,
    gender,
    country,
    state,
    city,
    zipCode,
    street,
    email,
    telephoneNumber,
    option
  };
}

const MTable = (props) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [profileData, setData] = useState([]);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

    useEffect(() => {
      let array=[];
      const getUser=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_GET_USER;
      fetch(getUser ,{
        method: 'GET',
        headers: {  
         'Content-Type': 'application/json',
         'Token': localStorage.getItem("token"),
          'id':  props.userId           
        }
      })
      .then(response=> response.json())
      .then(data => {
        array.push(
          createData(
             data.body.documentId,
             data.body.name,
             data.body.middleName,
             data.body.lastName,
             data.body.dateOfBirth,
             data.body.gender,
             data.body.country,
             data.body.state,
             data.body.city,
             data.body.zipCode,
             data.body.street,
             data.body.email,  
             data.body.telephoneNumber
            )
        );
        setData(array);   
      }); 
     
      // eslint-disable-next-line
    }, []);

  const styleObj = {
    fontSize: 30,
    color: "black",
}
  return (
    <React.Fragment>
        <center><p>Profile Data</p></center>
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableHeader.map((cell) => (
              <TableCell key={cell.data} className={classes.tableHeaderCell}>
                {cell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
        {(rowsPerPage > 0
            ? profileData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : profileData
          ).map((row,i) => (
            <TableRow key={row.documentId}>
              <TableCell>{row.documentId}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.middleName}</TableCell>
              <TableCell>{row.lastName}</TableCell>
              <TableCell>{row.dateOfBirth}</TableCell>
              <TableCell>{row.gender}</TableCell>
              <TableCell>{row.zipCode}</TableCell>
              <TableCell>{row.country}</TableCell>
              <TableCell>{row.state}</TableCell>
              <TableCell>{row.city}</TableCell>
              <TableCell>{row.street}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell>{row.telephoneNumber}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={profileData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
    </TableContainer>

    </React.Fragment>
  );
};

export default MTable;