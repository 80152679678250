import  React,{useEffect,useState,useContext} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography
} from "@material-ui/core";
import TablePagination from '@mui/material/TablePagination';
import DeleteIcon from "@material-ui/icons/Delete";
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import PregnancyHistoryContext from './pregnancyHistory-context';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    borderRadius: 15,
    margin: "10px 10px",
    maxWidth: "1800px",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    backgroundColor: "#4788B8",
    color: theme.palette.getContrastText(theme.palette.primary.dark)
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
  name: {
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
  },
  status: {
    fontWeight: "bold",
    fontSize: "0.75rem",
    color: "white",
    backgroundColor: "grey",
    borderRadius: 8,
    padding: "3px 10px",
    display: "inline-block",
  },
  hover: {
    cursor: "pointer",
  },
  center:{
    textAlign:'center'
  }
}));

const tableHeader = [
  { label: "Document", data: "id" },
  { label: "Sex", data: "category" },
  { label: "YearOfPregnancy", data: "yearOfPregnancy" },
  { label: "PlaceOfDeliveryOrAbortion", data: "placeOfDeliveryOrAbortion" },
  { label: "DurationPreg", data: "durationPreg" },
  { label: "HrsOfLabor", data: "hoursOfLabor" },
  { label: "TypeOfDeliveryComplication", data: "typeOfDeliveryComplication" },
  { label: "BirthWeight", data: "birthWeight" },
  { label: "PresentHealth", data: "presentHealth" },
  { label: "Update", data: "whiteSpaceDelete" },
  { label: "Delete", data: "whiteSpaceUpdate" }
];

function createData(
  document,
  id,
  categoryId,
  categoryName,
  yearOfPregnancy,
  placeOfDeliveryAbortion,
  durationPreg,
  hoursOfLabor,
  typeOfDeliveryComplication,
  birthWeight,
  presentHealth,
  option
  ) {
  return {
    document,
    id,
    categoryId,
    categoryName,
    yearOfPregnancy,
    placeOfDeliveryAbortion,
    durationPreg,
    hoursOfLabor,
    typeOfDeliveryComplication,
    birthWeight,
    presentHealth,
    option
  };
}

const MTable = (props) => {
  const pregnancyHistoryCtx = useContext(PregnancyHistoryContext);
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [medicalHistoryData, setData] = useState([]);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getCatalog=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICAL_HISTORY_GET_SEX_STATUS;  const getURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICAL_HISTORY_GET;
  useEffect(() => {
      let array=[];
      fetch(getCatalog ,{
        method: 'GET',
        headers: {  
        'Content-Type': 'application/json',
        'Token': localStorage.getItem("token"),
        'userid':localStorage.getItem("userId")     
        }
      })
      .then((response) => response.json())
      .then((data) => {
        let arrCategory=[];
        let count=0;
        let datax=undefined;
        let objectCategory=[];
        try
        {
         datax= data.body.category;
    
        }
        catch
        {
          datax=[];
        }
        for(let category in datax)
        {
            count=count+1;
                 
             if(datax[category].toLowerCase()=="male"||datax[category].toLowerCase()=="female")
             {
               objectCategory[category]= {
                index: count,
                category:datax[category],
                label:datax[category],
                key: parseInt(category)
              };       
             }         
              
        }

      fetch(getURL ,{
        method: 'GET',
        headers: {  
        'Content-Type': 'application/json',
        'Token': localStorage.getItem("token"),
        'userid':localStorage.getItem("userId")     
        }
      })
      .then((response) => response.json())
      .then((datax) => { 
           let arrayX=undefined;
           try
           {
           arrayX= datax.body[0].obgyn.pregnancy;
           }
           catch
           {
             arrayX=[];
           }

           for(let row in arrayX )
           {
            array.push(
              createData(
                datax.body[0].id,
                parseInt(row),
                arrayX[row].sex,
                objectCategory[arrayX[row].sex].category,
                arrayX[row].yearOfPregnancy,
                arrayX[row].placeOfDeliveryAbortion,
                arrayX[row].durationPreg,
                arrayX[row].hoursOfLabor,
                arrayX[row].typeOfDeliveryComplication,
                arrayX[row].birthWeight,
                arrayX[row].presentHealth

                )
            );
           }
            setData(array);     
        })
     });

      // eslint-disable-next-line
    }, [pregnancyHistoryCtx.count]);

  const updateItem = (index) => {
    medicalHistoryData[index].option=2;

    pregnancyHistoryCtx.addItem(medicalHistoryData[index]);
  };
  
  const deleteItem = (index) => {
    let result= window.confirm("Are you sure?");
    if (result) {
      const valuex= medicalHistoryData[index];
      
      const newMedicalHistoryData =medicalHistoryData.filter((user, i) => i !== index);

      const getURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICAL_HISTORY_GET;

             fetch(getURL ,{
               method: 'GET',
               headers: {  
               'Content-Type': 'application/json',
               'Token': localStorage.getItem("token"),
               'userid':localStorage.getItem("userId")     
               }
             })
             .then((response) => response.json())
             .then((data) => { 
                  let pregnancy=[];
                  let originalArray=[];
                  let arrayX;
                  let subCategory;
                  try
                  {
                  arrayX= data.body[0].obgyn.pregnancy;
                  }
                  catch
                  {
                    arrayX=[];
                  }
                  originalArray=data.body;

                  if(!(arrayX.length==1))
                  {  
                  
                      for(let m in arrayX )
                      {
                
                            if(!(m== valuex.id.toString()))
                            {
                              pregnancy.push(
                                {
                                  sex:arrayX[m].sex,
                                  yearOfPregnancy: arrayX[m].yearOfPregnancy,
                                  placeOfDeliveryAbortion:arrayX[m].placeOfDeliveryAbortion,
                                  durationPreg: arrayX[m].durationPreg,
                                  hoursOfLabor:arrayX[m].hoursOfLabor,
                                  typeOfDeliveryComplication:arrayX[m].typeOfDeliveryComplication,
                                  birthWeight:parseFloat(arrayX[m].birthWeight).toFixed(2),
                                  presentHealth:arrayX[m].presentHealth
                                
                              }
                              );
                            }
                          
                       
                      }
                   
                      originalArray[0].documentId=originalArray[0].id;
                      originalArray[0].obgyn.pregnancy=pregnancy;

                      const update=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICAL_HISTORY_UPDATE;
                      const requestOptions = {
                          method: 'PUT',
                          headers: { 
                            
                            'Content-Type': 'application/json',
                            "Token": localStorage.getItem("token")
                          },
                            body: JSON.stringify(originalArray[0])
                       };

                    try{
                      fetch(update, requestOptions)
                      .then(response => response.json());
                      }
                      catch
                      {
                        alert("Error");
                      }
           
                      setData(newMedicalHistoryData);

                  }
                  else
                  {
                        const update=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICAL_HISTORY_UPDATE;
                        originalArray[0].documentId=originalArray[0].id;
                        originalArray[0].obgyn.pregnancy=[];
                        
                        const requestOptions = {
                            method: 'PUT',
                            headers: { 
                              
                              'Content-Type': 'application/json',
                              "Token": localStorage.getItem("token")
                            },
                              body: JSON.stringify(originalArray[0])
                        };

                      try{
                        fetch(update, requestOptions)
                        .then(response => response.json());
                        }
                        catch
                        {
                          alert("Error");
                        }

                  

                        setData(newMedicalHistoryData);
                  }
            
         }); 
    
    }
  };

  const styleObj = {
    fontSize: 30,
    color: "black",
}
  return (
    <React.Fragment>
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableHeader.map((cell) => (
              <TableCell key={cell.data} className={classes.tableHeaderCell}>
                {cell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
        {(rowsPerPage > 0
            ? medicalHistoryData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : medicalHistoryData
          ).map((row,i) => (
            <TableRow key={row.id}>
              <TableCell>{row.document}</TableCell>
              <TableCell>{row.categoryName}</TableCell>
              <TableCell><center>{row.yearOfPregnancy}</center></TableCell>
              <TableCell><center>{row.placeOfDeliveryAbortion}</center></TableCell>
              <TableCell><center>{row.durationPreg}</center></TableCell>
              <TableCell><center>{row.hoursOfLabor}</center></TableCell>
              <TableCell><center>{row.typeOfDeliveryComplication}</center></TableCell>
              <TableCell><center>{row.birthWeight}</center></TableCell>
              <TableCell><center>{row.presentHealth}</center></TableCell>
              <TableCell>
                <Grid container>
                  <Grid item lg={12}>
                    <Typography>
                      <AutoFixNormalIcon
                        color="primary"
                        onClick={updateItem.bind(this, i)}
                        className={classes.hover}
                        />
                      </Typography>
                </Grid>
                </Grid>
              </TableCell>
              <TableCell>
                <Grid container>
                  <Grid item lg={12}>
                    <Typography>
                      <DeleteIcon
                        color="secondary"
                        onClick={deleteItem.bind(this, i)}
                        className={classes.hover}
                      />
                    </Typography>
                </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={medicalHistoryData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
    </TableContainer>

    </React.Fragment>
  );
};

export default MTable;