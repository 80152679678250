import  React,{useEffect,useState,useContext} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography
} from "@material-ui/core";
import TablePagination from '@mui/material/TablePagination';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 250,
  },
  tableContainer: {
    borderRadius: 15,
    margin: "10px 10px",
    maxWidth: "400px",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    backgroundColor: "#4788B8",
    color: theme.palette.getContrastText(theme.palette.primary.dark)
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
  name: {
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
  },
  status: {
    fontWeight: "bold",
    fontSize: "0.75rem",
    color: "white",
    backgroundColor: "grey",
    borderRadius: 8,
    padding: "3px 10px",
    display: "inline-block",
  },
  hover: {
    cursor: "pointer",
  },
  center:{
    textAlign:'center'
  }
}));

const tableHeader = [
  { label: "Document", data: "documentNumber" },
  { label: "Insurance Type", data: "insuranceTypeValue" },
  { label: "Primary Insurance", data: "primaryInsurance" },
  { label: "Name", data: "name" },
  { label: "Address", data: "address" },
  { label: "Phone", data: "phoneNumber" },
  { label: "RelationshipToInsurer", data: "relationshipToInsurer" },
  { label: "Member Id", data: "memberId" },
  { label: "Group Number", data: "groupNumber" },
  { label: "Coverage Date", data: "coverageDate" },
  { label: "Expiration Date", data: "expirationDate" },
  { label: "Provider Name", data: "providerName" },
  { label: "Provider Contact Number", data: "providerContactNumber" }
];

function createData(
  documentNumber,
  insuranceType,
  primaryInsurance,
  name,
  address,
  phoneNumber,
  relationshipToInsurer,
  memberId,
  groupNumber,
  coverageDate,
  expirationDate,
  providerName,
  providerContactNumber,
  primaryInsuranceValue,
  relationshipDateOfBirth,
  relationship,
  option
  ) {
  return {
    documentNumber,
    insuranceType,
    primaryInsurance,
    name,
    address,
    phoneNumber,
    relationshipToInsurer,
    memberId,
    groupNumber,
    coverageDate,
    expirationDate,
    providerName,
    providerContactNumber,
    primaryInsuranceValue,
    relationshipDateOfBirth,
    relationship,
    option
  };
}

const MTable = (props) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [insuranceData, setData] = useState([]);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
    const getInsuranceURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_GET_INSURANCE_GET;
    const getInsuranceTypeURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_GET_INSURANCE_TYPE;
    useEffect(() => {
      let array=[];
      fetch(getInsuranceTypeURL ,{
        method: 'GET',
        headers: {  
        'Content-Type': 'application/json',
        'Token': localStorage.getItem("token")        
        }
      })
      .then((response) => response.json())
      .then((data) => {    
        const datax= data.body;
        const arr = Object.keys(datax).map(
          (key) => [{
            "Key": key,
            "Type": datax[key]
            }]
          );
          const personalizedArray=[];
          for (let i = 0; i < arr.length; i++) {
            personalizedArray.push(
              {
                value: parseInt(arr[i][0].Key),
                label:arr[i][0].Type
              }
            )
          }

          fetch(getInsuranceURL ,{
            method: 'GET',
            headers: {  
            'Content-Type': 'application/json',
            'Token': localStorage.getItem("token"),
            'userid':props.userId    
            }
          })
          .then((response) => response.json())
          .then((data) => {   
            for(let i in data.body) {
              const {insurerDateOfBirth,relationship}=data.body[i].relationship;
              if(typeof insurerDateOfBirth === 'undefined')
              {
                insurerDateOfBirth="";
              }
              else
              {
                insurerDateOfBirth=",Coverage Date:" + insurerDateOfBirth;
              }
              if(typeof relationship=== 'undefined')
              {
                  relationship="";
              }        
              array.push(
                  createData(
                    data.body[i].id,
                    data.body[i].insuranceType,
                    data.body[i].primary==1?"Yes":"No",
                    data.body[i].name,
                    data.body[i].address,
                    data.body[i].phoneNumber,
                    relationship + insurerDateOfBirth,
                    data.body[i].memberId,
                    data.body[i].groupNumber,
                    data.body[i].coverageDate,
                    data.body[i].expirationDate,
                    data.body[i].providerName,
                    data.body[i].providerContactNumber,
                    data.body[i].primary,
                    data.body[i].relationship.insurerDateOfBirth,
                    data.body[i].relationship.relationship
                    )
                );
          }
          array.forEach((element) => {
            personalizedArray.forEach((elementx) => {
              if(element.insuranceType==elementx.value){
                 element.insuranceTypeValue=elementx.label;
              }
            });
          });
          array.push(...[]);
          setData(array);     
      })
      })
      // eslint-disable-next-line
    }, []);

  const styleObj = {
    fontSize: 30,
    color: "black",
}
  return (
    <React.Fragment>
    <center><p>Insurance</p></center>
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableHeader.map((cell) => (
              <TableCell key={cell.data} className={classes.tableHeaderCell}>
                {cell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
        {(rowsPerPage > 0
            ? insuranceData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : insuranceData
          ).map((row,i) => (
            <TableRow key={row.documentNumber}>
              <TableCell>{row.documentNumber}</TableCell>
              <TableCell>{row.insuranceTypeValue}</TableCell>
              <TableCell>{row.primaryInsurance}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.address}</TableCell>
              <TableCell>{row.phoneNumber}</TableCell>
              <TableCell>{row.relationshipToInsurer}</TableCell>
              <TableCell>{row.memberId}</TableCell>
              <TableCell>{row.groupNumber}</TableCell>
              <TableCell>{row.coverageDate}</TableCell>
              <TableCell>{row.expirationDate}</TableCell>
              <TableCell>{row.providerName}</TableCell>
              <TableCell>{row.providerContactNumber}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={insuranceData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
    </TableContainer>

    </React.Fragment>
  );
};

export default MTable;