import React  from 'react';
import { Fragment } from 'react';
import InsuranceRecord from './InsuranceRecord';
import InsuranceReport from './InsuranceReport';
import InsuranceProvider from './Insurance-Provider';
import Box from '@mui/material/Box';
const InsuranceCategory = () => {
  return (
      <InsuranceProvider>
            <InsuranceReport/>
            <InsuranceRecord/>
      </InsuranceProvider>

  );
};

export default InsuranceCategory;
