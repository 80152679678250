import  React,{ useRef,useState } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

export default function HealthRecordSharedWithMe(props) {

  return (
        <Box
            sx={{
              my: 8,
              mx: 12, 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
         
          >
        <Grid item xs={12} sm={8} md={12} elevation={6} >
        <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
          {props.children}
          </Box>
        </Grid>


        </Box>
  );
}
