import { useReducer } from 'react';
import React  from 'react';
import MedicationContext from './medication-context';
import Divider from "@mui/material/Divider";
import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& > :not(style) + :not(style)': {
      marginTop: theme.spacing(2),
    },
  }));

const defaultMedicationState = {
  items: [],
  count: 0,
  report:0
};

const cartReducer = (state, action) => {
  
  if (action.type === 'ADD') {

    if(typeof action.item !== 'undefined' && action.item.length === 0)
    {
      return {
        count:state.count+1,
        items: action.item,
        report:state.report+1
      };
    }
    else
    {
      return {
        count:state.count+1,
        items: action.item,
        report:0
      };
    }

  }

  return defaultMedicationState;
};

const MedicationProvider = (props) => {
  const [medicationState, dispatchCartAction] = useReducer(
    cartReducer,
    defaultMedicationState
  );

  const addItemToRecordHandler = (item) => {
    dispatchCartAction({ type: 'ADD', item: item });
  };

  const cartContext = {
    items: medicationState.items,
    count: medicationState.count,
    report: medicationState.report,
    addItem: addItemToRecordHandler
  };

  return (
    <MedicationContext.Provider value={cartContext}>
      <Root>
      <Divider fullWidth><h2>Medication</h2></Divider>
      </Root>
      <React.Fragment >
      {props.children}
      </React.Fragment>
    </MedicationContext.Provider>
  );
};

export default MedicationProvider;
