import  React,{ useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import UserProfile from './components/Profile/UserProfile';
import ResetPassword from './components/Profile/ResetPassword';
import AuthPage from './pages/AuthPage';
import AuthContext from './store/auth-context';
import PrivacyPolicy from './components/Profile/PrivacyPolicy';
import TermsOfService from './components/Profile/TermsOfService';
import HomePage from './pages/HomePage';
import HealthRecordPage from './pages/HealthRecordPage';
import HealthRecordSharedWithMe from './pages/HealthRecordSharedWithMePage'
import ShareHealthRecordPage from './pages/ShareHealthRecordPage';
import InvitePage from './pages/InvitePage';
function App(props) {
  const authCtx = useContext(AuthContext);

  return (
      <Switch>
        <Route path='/' exact>
            <AuthPage />
        </Route>
        <Route path='/auth' exact>
            <AuthPage />
        </Route>
        <Route path='/home' exact>
          <Layout>
            <HomePage />
          </Layout>
        </Route>
        <Route path='/profile' exact>
         <UserProfile />
        </Route>
        <Route path='/editProfile' exact>
         <UserProfile />
        </Route>
        <Route path='/resetPassword' exact>
         <ResetPassword />
        </Route>
        <Route path='/healthRecord' exact>
         <HealthRecordPage >
            {props.children}
         </HealthRecordPage>
        </Route>
        <Route path='/shareHealthRecord' exact>
         <ShareHealthRecordPage >
            {props.children}
         </ShareHealthRecordPage >
        </Route>
        <Route path='/invite'>
         <InvitePage >
            {props.children}
         </InvitePage >
        </Route>
        <Route path='/healthRecordSharedWithMe' exact>
         <HealthRecordSharedWithMe>
            {props.children}
         </HealthRecordSharedWithMe >
        </Route>
        <Route path='*'>
          <Redirect to='/' />
        </Route>
      </Switch>
  );
}

export default App;
