import { Fragment } from 'react';
import  React,{useEffect,useState,useContext } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PersonalizedButton from "../../Layout/Button"
import FormControl from "@mui/material/FormControl";
import { useTheme } from '@mui/material/styles';
import {numbersNotAllowed,emptyCheck,dateFormatter} from "../../../methods/functions"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FamilyMedicalHistoryContext from '../FamilyMedicalHistory/familyMedicalHistory-context';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import { styled } from "@mui/material/styles";
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import { makeStyles } from "@material-ui/core/styles";
import TablePagination from '@mui/material/TablePagination';
import DeleteIcon from "@material-ui/icons/Delete";
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';

import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer
} from "@material-ui/core";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 250,
  },
  tableContainer: {
    borderRadius: 15,
    margin: "10px 10px",
    maxWidth: "1800px",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
  name: {
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
  },
  status: {
    fontWeight: "bold",
    fontSize: "0.75rem",
    color: "white",
    backgroundColor: "grey",
    borderRadius: 8,
    padding: "3px 10px",
    display: "inline-block",
  },
  hover: {
    cursor: "pointer",
  },
  center:{
    textAlign:'center'
  }
}));

const tableHeader = [
  { label: "Category", data: "category" },
  { label: "SubCategory", data: "subCategory" },
  { label: "Delete", data: "whiteSpaceUpdate" },
  { label: "Update", data: "whiteSpaceUpdate" }
];

const styleObj = {
  fontSize: 20,
  color: "black",
}

export default function FamilyMedicalHistoryRecord() {
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [familyMedicalHistoryData, setData] = useState([]);
  const deleteItem = (index) => {
    let listDisease=arrayFieldMasterCategory;
    if(index.subCategoryId==-1){
      listDisease.unshift(
          {
            "index":  Math.random(),
            "subCategoryId": index.subCategoryId,
            "subCategory": index.subCategoryName,
            "categoryId": index.categoryId,
            "category": index.categoryName,
            "label": index.categoryName,
            "key": Math.random()
        }
      )
    }
    else
    {
      if(index.subCategoryId>-1){
        listDisease.unshift(
          {
            "index":  Math.random(),
            "subCategoryId": index.subCategoryId,
            "subCategory": index.subCategoryName,
            "categoryId": index.categoryId,
            "category": index.categoryName,
            "label": index.subCategoryName + "," + index.categoryName,
            "key": Math.random()
        }
      )
      }
    }
    setArrayMasterCategory(listDisease);
    let result= window.confirm("Are you sure?");
    let familyData;
    if (result) {
      if(familyMedicalHistoryData.length==0)
      {
        setData();
      }
      else{
        familyData= familyMedicalHistoryData.filter(
          (data) =>    
           data!=index
          );
          setData(familyData);
      }
      if(index.categoryId==otherField.categoryId)
      {
        setSubNameField('');
      }
    }

  };
  const [familyRelationshipId,setFamilyRelationshipId]=  React.useState([]);
  const [showSuper,setShowSuper]=  React.useState(0);
  const [arrayFieldMasterCategory ,setArrayMasterCategory] = React.useState([]);
  const [arrayFieldCategory ,setArrayCategory] = React.useState([]);
  const [showSubCategory,setShowSubCategory]=  React.useState(1);
  const [arrayFieldSubCategory ,setArraySubCategory] = React.useState([]);
  const [arrayFieldSubCategoryObject ,setArraySubCategoryObject] = React.useState([]);
  const [recordOption ,setRecordOption] = useState(0);
  const familyMedicalHistoryCtx = useContext(FamilyMedicalHistoryContext);
  const [updated,setUpdatedField]=useState('');
  const [familyRelationshipField,setFamilyRelationshipField] = React.useState(1);
  const [arrayFieldFamilyRelationshipCategory ,setArrayFieldFamilyRelationshipCategory] = React.useState([]);
  const [subNameField, setSubNameField] = useState('');
  const [subNameFieldError, setSubNameFieldError] = useState();
  const [otherField,setOtherField]= React.useState();
  const [diseaseFieldError, setDiseaseFieldError] = useState();


  const createURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_FAMILY_RELATIONSHIP_CREATE;
  function getFamilyMembers(){
    const getCatalog=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_FAMILY_RELATIONSHIP_CATALOG;
    return fetch(getCatalog ,{
     method: 'GET',
     headers: {  
     'Content-Type': 'application/json',
     'Token': localStorage.getItem("token")    
     }
   })
   .then((response) => response.json())
   .then((data) => {   

    let arrFamilyRelationshipCategory=[];
	  let datax=[];
    let count=0;
    try
    {
     datax= data.body.relationship;

    }
    catch
    {
      datax=[];
    }

    for(let row in datax)
    {
      if(datax[row].toLowerCase()!="self")
      {
		  count=count+1;
      arrFamilyRelationshipCategory.push({
         index: count,
         category:datax[row],
         label:datax[row],
          key: parseInt(row)
         });              
        }
    }
    setArrayFieldFamilyRelationshipCategory(arrFamilyRelationshipCategory);

   });
  }
  function getData(){
    const getCatalog=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICAL_HISTORY_GET_DISEASE_CATALOG;

        return fetch(getCatalog ,{
        method: 'GET',
        headers: {  
        'Content-Type': 'application/json',
        'Token': localStorage.getItem("token"),
        'userid':localStorage.getItem("userId")     
        }
      })
      .then((response) => response.json())
      .then((data) => {   
        
        let arrCategory=[];
        let arrSubCategory=[];
        let count=0;
        let masterCount=0;
        let subCount=0;
        let datax=undefined;
        let objectSubCategory=[];
        let objectCategory=[];
        let master=[];
        let existsSubCategory=0;
        try
        {
        datax= data.body.category;

        }
        catch
        {
          datax=[];
        }

        for(let category in datax)
        {
            count=count+1;

            try
            {
              existsSubCategory = datax[category].subCategory.length; 
              }  
              catch
              {
                existsSubCategory=-1;
              }

              if(existsSubCategory>0)
              {

                for(let subCategory in datax[category].subCategory)
                { 
                    arrSubCategory.push({
                      indexSubCategory: subCount++,
                      category:category,
                      label:datax[category].subCategory[subCategory],
                      key: parseInt(subCategory)
                    });

                    master.push({
                      index: masterCount++,
                      subCategoryId:  parseInt(subCategory),
                      subCategory: datax[category].subCategory[subCategory],
                      categoryId: parseInt(category),
                      category:datax[category].name,
                      label:datax[category].name,
                      key: masterCount
                    }) ;  

                }

                objectSubCategory[category]=arrSubCategory;
                setArraySubCategoryObject(objectSubCategory);
                if(category==0)
                {
                  setArraySubCategory(arrSubCategory);
                }
      

              }
              else
              {
                if(datax[category].name.toLowerCase()=="other")
                {
                setOtherField({
                  index: masterCount++,
                  subCategoryId:  -1,
                  subCategory: "Add custom subcategory",
                  categoryId: parseInt(category),
                  category:datax[category].name,
                  label:datax[category].name,
                  key: masterCount
                }
                );
                }
              }

              if(datax[category].name.toLowerCase()=="other")
              {
                arrCategory.push({
                      index: count,
                      category:"Other",
                      label:"Other",
                      key: parseInt(category)
                    })

              }
              else
              {
                        arrCategory.push({
                          index: count,
                          category:datax[category].name,
                          label:datax[category].name,
                          key: parseInt(category)
                        }) 
                        if(existsSubCategory==-1){
                            master.push({
                              index: masterCount++,
                              subCategoryId:  "",
                              subCategory: "",
                              categoryId: parseInt(category),
                              category:datax[category].name,
                              label:datax[category].name,
                              key: masterCount
                            }) ;  
                        }             
              } 
            
              objectCategory=[];

              arrSubCategory=[];

              subCount=0;
        }
        setArrayCategory(arrCategory);
        setArrayMasterCategory(master);
        
      });
    

  }

  const handleChangeRecordOption = (event) => {
    if(event.target.value==0)
    {
      getData();
      setRecordOption(0);
      setSubNameField('');
      setShowSuper(0);
      setShowSubCategory(0);
    }
    else{
      setShowSubCategory(0);
      setShowSuper(1);
    }
    setRecordOption(event.target.value);
   
  };
  const [disease, setDisease] = React.useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
      let valuex=  typeof value === 'string' ? value.split(',') : value;
      setDisease(
        valuex
      );
  };
  const handleFamilyMedicalHistory = (event) => {
    event.preventDefault();
    setSubNameFieldError(false);
    setDiseaseFieldError(false);
    let familyMember = familyRelationshipField;
    let finalDecision=true;
    let diseaseDB=[];
    if(recordOption==0){
    if(showSubCategory==1)
    {
        if(emptyCheck(subNameField) || !numbersNotAllowed(subNameField)){
          setSubNameFieldError(true);
          finalDecision=false;
        } 
    }

    if(disease.length==0)
    {
      finalDecision=false;
      setDiseaseFieldError(true);
      
    }
  }
  else
  {
    if(disease.length==0&&familyMedicalHistoryData.length==0 )
    {
      setDiseaseFieldError(true);
      finalDecision=false;
    }
    let otherRecord= familyMedicalHistoryData.filter((data) => data.subCategoryId ==-2);

    if(otherRecord.length>0){
      if(emptyCheck(subNameField) || !numbersNotAllowed(subNameField)){
        setSubNameFieldError(true);
        finalDecision=false;
      } 
    }
    else{
          if(!emptyCheck(subNameField))
          {
            if( !numbersNotAllowed(subNameField))
            {
              setSubNameFieldError(true);
              finalDecision=false;
            }
          }
    }
  }

    if(finalDecision)
    {
          setSubNameFieldError(false);
          const getURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_FAMILY_RELATIONSHIP_GET;
          fetch(getURL ,{
            method: 'GET',
            headers: {  
            'Content-Type': 'application/json',
            'Token': localStorage.getItem("token"),
            'userid':localStorage.getItem("userId")     
            }
          })
          .then((response) => response.json())
          .then((data) => {
            let arrayX=undefined;
            try
            {
            arrayX= data.body[0].familyMembers;
            }
            catch
            {
              arrayX=[];
            }
            const result = arrayX ? arrayX.length : 0;

            if(result==0)
            {
                  for(let row in disease)
                  {
                  diseaseDB.push({
                        date: "",
                        categoryId: parseInt(disease[row].categoryId),
                        subCategoryId: parseInt(disease[row].subCategoryId)==null?"":disease[row].subCategoryId                      
                  })
                  }
                  if(showSubCategory==1)
                  {
                  diseaseDB.push({
                    date: "",
                    categoryId: otherField.categoryId,
                    subCategoryId: subNameField
                    })
      
                  }
                  const familyMedicalHistory={
                    userId:localStorage.getItem("userId"),
                    familyMembers: [
                      {
                        diseases: diseaseDB,
                        familyRelationship:familyMember
                      }
                    ]
                  };
      
      
                const requestOptions = {
                  method: 'POST',
                  headers: { 
                    
                    'Content-Type': 'application/json',
                    "Token": localStorage.getItem("token")  
                
                  },
                      body: JSON.stringify(familyMedicalHistory)
                  };
                  try{
                    fetch(createURL, requestOptions)
                        .then(response => response.json());
                        alert("Done");      
                        setSubNameField('');
                        setDisease([]);
                        setShowSubCategory(0);
                        familyMedicalHistoryCtx.items=[];
                        familyMedicalHistoryCtx.addItem(familyMedicalHistoryCtx.items);
                  }
                  catch{
                      alert("Error");
                  } 
  
            }
            else
            {
                  
              if(recordOption==1){
                //Update
                let otherRecord= familyMedicalHistoryData.filter((data) => data.subCategoryId ==-2);
                let recordId=-5;
                if(otherRecord.length>0)
                {
                    diseaseDB.push({
                      date: "",
                      categoryId: otherRecord[0].categoryId,
                      subCategoryId: subNameField
                      });
                      recordId=otherRecord[0].categoryId;
                      
                }
                else
                {
                  if(!emptyCheck(subNameField)){
                    diseaseDB.push({
                      date: "",
                      categoryId:  otherField.categoryId,
                      subCategoryId: subNameField
                      });
                  }
                }
           
              
                if(disease.length>0)
                {
                for(let row in disease)
                {
 
                diseaseDB.push({
                      date: "",
                      categoryId: parseInt(disease[row].categoryId),
                      subCategoryId: parseInt(disease[row].subCategoryId)==null || disease[row].subCategoryId==-1?"":disease[row].subCategoryId 
                })
                }
               }
               let familyData= familyMedicalHistoryData;
         

               if(familyData.length>0)
               {
                for(let row in familyData)
                {
                  if(!(familyData[row]==2))
                  {
                        if(familyData[row].categoryId!=recordId)
                        {
                            diseaseDB.push({
                              date: "",
                              categoryId: parseInt(familyData[row].categoryId),
                              subCategoryId: parseInt(familyData[row].subCategoryId)==null|| familyData[row].subCategoryId==-1?"":familyMedicalHistoryData[row].subCategoryId 
                            })
                      }
                  }
   
                }
               }
               let originalArray=[];
               originalArray=data.body;
  
               const update=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_FAMILY_RELATIONSHIP_UPDATE;
               originalArray[0].documentId=originalArray[0].id;

              for(let row in originalArray[0].familyMembers)
              {
                if(row == familyRelationshipId[0].id)
                {
                  originalArray[0].familyMembers[row].diseases=diseaseDB;
                }
              }

                    const requestOptions = {
                      method: 'PUT',
                      headers: { 
                        
                        'Content-Type': 'application/json',
                        "Token": localStorage.getItem("token")
                      },
                        body: JSON.stringify(originalArray[0])
                  };
                
                  try{
                    fetch(update, requestOptions)
                    .then(response => response.json());
                    alert("Done");      
                    familyMedicalHistoryCtx.items=[];
                    setUpdatedField(1);
                    familyMedicalHistoryCtx.report=familyMedicalHistoryCtx.report+1;
                    }
                    catch
                    {
                     alert("Error");
                    }
                
                    setSubNameField('');
                    setDisease([]);
                    familyMedicalHistoryCtx.addItem(familyMedicalHistoryCtx.items);
     
              }
              else{
               let originalArray=[];

               originalArray=data.body;

               if(showSubCategory==1)
               {
               diseaseDB.push({
                 date: "",
                 categoryId: otherField.categoryId,
                 subCategoryId: subNameField
                 })
   
               }

               for(let row in disease)
               {

               diseaseDB.push({
                     date: "",
                     categoryId: parseInt(disease[row].categoryId),
                     subCategoryId: parseInt(disease[row].subCategoryId)==null?"":disease[row].subCategoryId 
               })
               }
               originalArray[0].familyMembers.push(
                {
                  diseases: diseaseDB,
                  familyRelationship:familyMember
                }
               );

               const update=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_FAMILY_RELATIONSHIP_UPDATE;
               originalArray[0].documentId=originalArray[0].id;
               const requestOptions = {
                 method: 'PUT',
                 headers: { 
                   
                   'Content-Type': 'application/json',
                   "Token": localStorage.getItem("token")
                 },
                   body: JSON.stringify(originalArray[0])
              };

               try{
               fetch(update, requestOptions)
               .then(response => response.json());
               alert("Done");      
               familyMedicalHistoryCtx.items=[];
               setUpdatedField(1);
               familyMedicalHistoryCtx.report=familyMedicalHistoryCtx.report+1;
               }
               catch
               {
                alert("Error");
               }
               setSubNameField('');
               setDisease([]);
               setShowSubCategory(0);
              
               familyMedicalHistoryCtx.addItem(familyMedicalHistoryCtx.items);

              }

            }
          });
      
    }
  
    

  };
  
  
    useEffect(() => {
      if(updated==1)
      {
        setSubNameField('');
        setShowSubCategory(0);
        setRecordOption(0);
        setUpdatedField(0);
      }
      if(familyMedicalHistoryCtx.items.option==2)
      {
        let editMasterArray=familyMedicalHistoryCtx.items;
        let otherRecord=editMasterArray.filter((data) => data.subCategoryId ==-2);
        let listDisease=arrayFieldMasterCategory;
        for(let row in editMasterArray){

          if(editMasterArray[row]!=-2)
          {
            
            if(editMasterArray[row].subCategoryId!=-2 ){
           
                if(editMasterArray[row].subCategoryId==-1){
                    listDisease=listDisease.filter((data)=> data.categoryId!=editMasterArray[row].categoryId);
                }
                else
                {
                  listDisease=listDisease.filter(
                    (data)=> {
                        if(!(data.subCategoryId==editMasterArray[row].subCategoryId && data.categoryId==editMasterArray[row].categoryId))
                        {
                          return data;
                        }
                    }
                    );
                }
            }
          }
        }
        setArrayMasterCategory(listDisease);
        setFamilyRelationshipId(editMasterArray);
        if(otherRecord.length==1)
        {
          let rowCN= otherRecord[0].subCategoryName;
          setSubNameField(rowCN);
        }
        else{
          setSubNameField("");
        }
        setData(editMasterArray);  
        setShowSuper(1);
        setShowSubCategory(1);
        setRecordOption(1);
       
      }
      else{
    
        if(familyMedicalHistoryCtx.items.option==3)
        {
          getData();
          setRecordOption(0);
          setShowSuper(0);
          setShowSubCategory(0);
          setSubNameField("");
        }
        else{
            setShowSuper(0);
            setShowSubCategory(0);
            if(arrayFieldMasterCategory.length==0){
            getData();
            }
            getFamilyMembers();
       }

      }
      // eslint-disable-next-line
    }, [familyMedicalHistoryCtx]);


const changeSubNameField = (event) => {
    setSubNameField(event.target.value);
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  }
};

const handleChangeOptionFamilyRelationshipCategory = (event) => {
  setFamilyRelationshipField(event.target.value);
};
const theme = useTheme();
function getStyles(option, disease, theme) {
  return {
    fontWeight:
    disease.indexOf(option) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


const handleChangeSubCategory = (event) => {
  setShowSubCategory(event.target.value);
};
const updateItem = (index) => {
  familyMedicalHistoryData[index].option=2;
  setShowSubCategory(1);
  const newFamilyMedicalHistoryData = familyMedicalHistoryData.filter((data) => data.id ==familyMedicalHistoryData[index].id);
  newFamilyMedicalHistoryData.option=2;

  familyMedicalHistoryCtx.addItem(newFamilyMedicalHistoryData);
};
const ColorButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#333399",
  color:"white",
  "&:hover": {
    backgroundColor: "#333399"
  }
}));
  return (
        <Box
        component="form"
         noValidate
        onSubmit={handleFamilyMedicalHistory}
            sx={{
              my: 4,
              mx: 12,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >  
              <Typography component="h1" variant="h5">
                  Record
                </Typography>
                <FormControl sx={{ m: 2}}>
                      <RadioGroup
                        sx={{ display: 'inline' }}
                        aria-labelledby="record-radio-buttons-group-label"
                        name="record-radio-buttons-group"
                        value={recordOption}
                        onChange={handleChangeRecordOption}
                      >
                        <FormControlLabel value="0" control={<Radio />} label="Create" />
                        <FormControlLabel value="1" disabled control={<Radio />} label="Update" />
                      </RadioGroup>
              </FormControl>
              <FormControl  sx={{ mt: 0, mx: 0 }} >
              </FormControl>
              <React.Fragment>
              {
                    showSuper==0?    
                            <FormControl  sx={{ mt: 1, width: 300}} >
                            <InputLabel sx={{ mt: 1 }} id="optionFamilyRelationshipLabel">Family Relationship</InputLabel>
                            <Select
                                      labelId="optionLabel"
                                      id="option"
                                      label="Family Relationship"
                                      value={familyRelationshipField}
                                      onChange={handleChangeOptionFamilyRelationshipCategory}
                                      >
                                          {
                                            arrayFieldFamilyRelationshipCategory.map((option) => (
                                                <MenuItem  key={option.key} value={option.key}>{option.label}</MenuItem>
                                              ))
                                          }
                            </Select>
                          </FormControl>:<React.Fragment></React.Fragment>
              }
             </React.Fragment>
             <FormControl sx={{ m: 0 }}>
             {
                showSuper==1?       
                <TableContainer className={classes.tableContainer}>
                  <center><p style={styleObj}>Current diseases</p></center>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {tableHeader.map((cell) => (
                          <TableCell key={Math.random()} className={classes.tableHeaderCell}>
                            {cell.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {(rowsPerPage > 0
                        ? familyMedicalHistoryData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : familyMedicalHistoryData
                      ).map((row,i) => (
                        <TableRow key={Math.random()} >
                          <TableCell>{row.categoryName}</TableCell>
                          <TableCell>{row.subCategoryName}</TableCell>
                          <TableCell>
                            <Grid container>
                              <Grid item lg={12}>
                                <Typography>
                                  <DeleteIcon
                                    color="secondary"
                                    onClick={deleteItem.bind(this, row)}
                                    className={classes.hover}
                                  />
                                </Typography>
                            </Grid>
                            </Grid>
                          </TableCell>
                          {
                              row.subCategoryId==-2?
                              <React.Fragment>
                                      <TableCell>
                          <Grid container>
                            <Grid item lg={12}>
                              <Typography>
                                <AutoFixNormalIcon
                                  color="primary"
                                  onClick={updateItem.bind(this, i)}
                                  className={classes.hover}
                                  />
                                </Typography>
                          </Grid>
                          </Grid>
                        </TableCell>
                      
                              </React.Fragment>
                              : <TableCell>N/A</TableCell>
                            }
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={familyMedicalHistoryData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                </TableContainer>:<React.Fragment></React.Fragment>
              }
            </FormControl>
                <FormControl sx={{ m: 2, width: 300 }}>
                {
                  showSuper==0?
                <InputLabel id="demo-multiple-chip-label">Diseases</InputLabel>:<InputLabel id="demo-multiple-chip-label">Add Diseases</InputLabel>
                }
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  error= {diseaseFieldError? true:false}
                  value={disease}
                  onChange={handleChange}
                  input=      {
                    showSuper==0?
                  <OutlinedInput id="select-multiple-chip" label="Diseases" />:<OutlinedInput id="select-multiple-chip" label="Add Diseases" />
              }
                  renderValue={(selected,i) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value.index} label={value.subCategory==""? value.category : value.subCategory} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {arrayFieldMasterCategory.map((option,i) => (
                    <MenuItem
                      key={option.index}
                      value={option}
                      style={getStyles(option,disease, theme)}
                    >
                      {option.subCategory==""?  option.category: option.subCategory + ","  + option.category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
      
         
            {
              showSuper==0?
              <React.Fragment>
                <FormControl sx={{ m: 1}}>
                <FormLabel id="demo-radio-buttons-group-label">Other (optional) </FormLabel>
                                <RadioGroup
                                  sx={{ display: 'inline' }}
                                  aria-labelledby="record-radio-buttons-group-label"
                                  name="record-radio-buttons-group"
                                  value={showSubCategory}
                                  onChange={handleChangeSubCategory}
                                >
                                  <FormControlLabel value="0" control={<Radio />} label="No" />
                                  <FormControlLabel value="1" control={<Radio />} label="Yes" />
                                </RadioGroup>
                        </FormControl>
              </React.Fragment>:<React.Fragment></React.Fragment>
            }
            {
              showSubCategory==1?
              <>
              <FormControl sx={{ m: 1}}>
                    <TextField
                              
                              InputLabelProps={{ shrink: true }}
                              error= {subNameFieldError? true:false}
                              margin="normal"
                              id="subNameField"
                              label={recordOption==1?"Other (optional)":""}
                              name="subNameField"      
                              value={subNameField}
                              onChange={changeSubNameField}   
                                    
                      />   
              </FormControl>

              {subNameFieldError?
                      <p style={styleObj}>Other field can't be empty or can't contain numbers</p>
              :<></>
              }
              </>
              :   
              <React.Fragment></React.Fragment>
             }
        
        <React.Fragment>

    </React.Fragment>
              <React.Fragment></React.Fragment>
              <PersonalizedButton  
              type="submit"
                variant="contained"
                 sx={{ mt: 2, mb: 3 }}
              >
                  Save
              </PersonalizedButton>    
        </Box>
  );
}
