import React,{useState,useEffect}  from 'react';
import { Fragment } from 'react';
import MedicalHistoryRecord from './MedicalHistoryRecord';
import MedicalHistoryReport from './MedicalHistoryReport';
import ObgynCategory from './Obgyn/ObgynCategory';
import  MedicalHistoryProvider from './medicalHistory-Provider';
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import { styled } from '@mui/material/styles';
import GynecologicalHistoryCategory from './GynecologicalHistory/GynecologicalHistoryCategory';
const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& > :not(style) + :not(style)': {
    marginTop: theme.spacing(2),
  },
}));
const MedicalHistoryCategory = () => {
  const [visibleObgyin,setVisibleObgyin]=useState(false);
  function getUserGender(userId){
    const getUser=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_GET_USER;
    const getSexStatus=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_SEX_STATUS;

    fetch(getUser ,{
      method: 'GET',
      headers: {  
       'Content-Type': 'application/json',
       'Token': localStorage.getItem("token"),
        'id':  userId         
      }
    })
    .then(response=> response.json())
    .then(data => {
      fetch(getSexStatus ,{
        method: 'GET',
        headers: {  
         'Content-Type': 'application/json',
         'Token': localStorage.getItem("token")         
        }
      })
      .then(response=> response.json())
      .then(datax => {
        for(let row in datax.body.category)
        {
             let gender=datax.body.category[row];
            
             if(gender.toLowerCase()=="female")
             {
               
               if(row==data.body.gender)
               {
                setVisibleObgyin(true);
               }
             }
        }
      }); 
    }); 

  }
  useEffect(() => {
     getUserGender(localStorage.getItem("userId") )
  }, [visibleObgyin]);
  return (
      <MedicalHistoryProvider>
            <MedicalHistoryReport/>
            <MedicalHistoryRecord/>
            {
                  visibleObgyin==true?
                  <React.Fragment>
                    <GynecologicalHistoryCategory />
                    <ObgynCategory/>
                  </React.Fragment>
                :   
                <React.Fragment></React.Fragment>
            }
      </MedicalHistoryProvider>

  );
};

export default MedicalHistoryCategory;
