import  React,{useEffect,useState,useContext} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography
} from "@material-ui/core";
import VisibilityIcon from '@mui/icons-material/Visibility';
import TablePagination from '@mui/material/TablePagination';
import HealthRecordSharedWithMeContext from '../HealthRecordSharedWithMe/healthRecordSharedWithMe-context';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from "@mui/material/FormControl";
import InputAdornment from '@mui/material/InputAdornment';
import DownloadIcon from '@mui/icons-material/Download';
import Divider from "@mui/material/Divider";
import { styled } from '@mui/material/styles';
import InsuranceReport from '../../ViewSharedHealthReport/InsuranceReport'
import MedicalProviderReport from '../../ViewSharedHealthReport/MedicalProviderReport'
import SocialHistoryReport from '../../ViewSharedHealthReport/SocialHistoryReport'
import SurgicalHistoryReport from '../../ViewSharedHealthReport/SurgicalHistoryReport'
import MedicalHistoryReport from '../../ViewSharedHealthReport/MedicalHistoryReport'
import GynecologicalHistoryReport from '../../ViewSharedHealthReport/Female/GynecologicalHistoryReport'
import ObgynReport from '../../ViewSharedHealthReport/Female/ObgynReport'
import PregnancyHistoryReport from '../../ViewSharedHealthReport/Female/PregnancyHistoryReport'
import MedicationReport from '../../ViewSharedHealthReport/MedicationReport'
import FamilyMedicalHistoryReport from '../../ViewSharedHealthReport/FamilyMedicalHistoryReport'
import VaccineReport from '../../ViewSharedHealthReport/VaccineReport'
import ProfileData from '../../ViewSharedHealthReport/ProfileData'
import LabWorkReport from '../../ViewSharedHealthReport/LabWorkReport'
import Box from '@mui/material/Box';
import { getParameters } from '../../../methods/functions';
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    borderRadius: 15,
    margin: "10px 10px",
    maxWidth: "1800px",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    backgroundColor: "#4788B8",
    color: theme.palette.getContrastText(theme.palette.primary.dark)
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
  name: {
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
  },
  status: {
    fontWeight: "bold",
    fontSize: "0.75rem",
    color: "white",
    backgroundColor: "grey",
    borderRadius: 8,
    padding: "3px 10px",
    display: "inline-block",
  },
  hover: {
    cursor: "pointer",
  },
  center:{
    textAlign:'center'
  }
}));

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& > :not(style) + :not(style)': {
    marginTop: theme.spacing(2),
  },
}));


const tableHeader = [
  { label: "category", data: "category" },

];

function createData(
  userId,
  accessId,
  accessName,
  option
  ) {
  return {
    userId,
    accessId,
    accessName,
    option
  };
}

const MTable = (props) => {
  const healthRecordSharedWithCtx = useContext(HealthRecordSharedWithMeContext);
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [shareHealthRecordData, setData] = useState([]);
    const [name,setName ] = useState("");
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [isFemale, setIsFemale] = useState(false);
  const getURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_GET_SHARED_WITH;

    useEffect(() => {
      let array=[];
      let getCategoryCatalog=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_CATEGORY_CATALOG;
      setName(healthRecordSharedWithCtx.items.grantorName);
         fetch(getCategoryCatalog ,{
          method: 'GET',
          headers: {  
          'Content-Type': 'application/json',
          'Token': localStorage.getItem("token"),
          'userid':localStorage.getItem("userId")     
          }
        })
        .then((response) => response.json())
        .then((data) => {
               let datax=undefined;
                 
               try
               {
               datax= data.body.category;
       
               }
               catch
               {
                 datax=[];
               }
               let master=[];
               for(let row in datax)
               {
                  master[row]= datax[row];

               }
               for(let row in healthRecordSharedWithCtx.items.access){
                array.push(
                  createData(
                    localStorage.getItem("userId"),
                    healthRecordSharedWithCtx.items.access[row],
                    master[healthRecordSharedWithCtx.items.access[row]]
                    )
                );
               }
               setData(array); 
             }      
        );
       
        // eslint-disable-next-line
    }, [healthRecordSharedWithCtx.items.grantorName]);


  const styleObj = {
    fontSize: 30,
    color: "black",
}
const dataHandler = (index) => {
  let newRow;
  newRow=healthRecordSharedWithCtx.items;
  newRow.option=3;
  newRow.accessId=parseInt(index.accessId);
  healthRecordSharedWithCtx.addItem(newRow);
}

const functionWithSwitch = (parameter) => {
  switch(parameter.accessId){
      //Insurance
      case 0:
        return (
          <div>
          <InsuranceReport userId={parameter.userId}/>
          </div>
   
        
        )
      //"Doctor-Medical Providers"
      case 1: 
      return (
        <div>
          <MedicalProviderReport userId={parameter.userId}/>
        </div>
      )
      //"Social History"
      case 2: 
      return (
        <div>
          <SocialHistoryReport userId={parameter.userId}/>
        </div>
      )
      //"Medical History"
      case 3: 
      const getUser=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_GET_USER;
      fetch(getUser ,{
        method: 'GET',
        headers: {  
         'Content-Type': 'application/json',
         'Token': localStorage.getItem("token"),
          'id':  parameter.userId       
        }
      })
      .then(response=> response.json())
      .then(data => {
        if(parseInt(data.body.gender)==0)
        {
          setIsFemale(true);
        }
      }); 
      return (
        <div>
              <MedicalHistoryReport userId={parameter.userId}/>
              {isFemale==true?
                <div>
                  <GynecologicalHistoryReport userId={parameter.userId}/>
                  <ObgynReport userId={parameter.userId}/>
                  <PregnancyHistoryReport userId={parameter.userId}/>
                </div>
              : <React.Fragment></React.Fragment>
                }
        </div>
      )
      //"Medications"
      case 4: 
      return (
        <div>
          <MedicationReport userId={parameter.userId}/>
        </div>
      )
      //"Family Medical History"
      case 5: 
      return (
        <div>
        <FamilyMedicalHistoryReport userId={parameter.userId}/>
        </div>
      )
      //"Vaccines"
      case 6: 
      return (
        <div>
        <VaccineReport userId={parameter.userId}/>
        </div>
      )
      //"Lab Work"
      case 7: 
      return (
        <div>
          <LabWorkReport userId={parameter.userId}/>
        </div>
      )
      //"Profile data"
      case 8: 
       return (
              <div>
                <ProfileData userId={parameter.userId}/>
              </div>
       )
      //"Surgical History"
      case 9: 
      return (
        <div>
          <SurgicalHistoryReport userId={parameter.userId}/>
        </div>
      )
    default:
      return ""
  }
}

  return (
    
    <React.Fragment>
      {healthRecordSharedWithCtx.count>0?
    <React.Fragment>
        <Root>
        <Divider><h2>{name +"'s" + " data"}  </h2></Divider>
        </Root> 
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {tableHeader.map((cell) => (
                  <TableCell key={cell.data} className={classes.tableHeaderCell}>
                    {cell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
            {(rowsPerPage > 0
                ? shareHealthRecordData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : shareHealthRecordData
              ).map((row) => (
                <TableRow key={Math.random()} 
                hover
                onClick={(e) => {
                  e.stopPropagation(); 
                  dataHandler(row);
                }}
                >
                  <TableCell>{row.accessName}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={shareHealthRecordData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
        </TableContainer>
        {
          
        healthRecordSharedWithCtx.items.option==3?
              <>
                    {functionWithSwitch(healthRecordSharedWithCtx.items)}
                    </>
        :<></>
        
        }

    </React.Fragment>

          :
          <></>
      
      }
    </React.Fragment>
  );
};

export default MTable;