import React, { useState ,useContext} from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useHistory } from 'react-router-dom';
import MenuIcon from "@mui/icons-material/Menu";
import AuthContext from '../../store/auth-context';
const pages = ["Products", "Services", "ABoutUs", "Contact Us"];
const DrawerComp = () => {
  const authCtx = useContext(AuthContext);
  const [openDrawer, setOpenDrawer] = useState(false);
  const history = useHistory();
  const logoutHandler = () => {
    authCtx.logout();
    history.replace('/');
  };

  const healthRecordHandler = () => {
    history.replace('/healthRecord');
  };

  const shareHealthRecordHandler = () => {
    history.replace('/shareHealthRecord');
  };
  const healthRecordSharedWithMeHandler = () => {
    history.replace('/healthRecordSharedWithMe');
  };
  const login = () => {
    history.replace('/');
  };

  return (
    <React.Fragment>
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
            <ListItemButton key={0} onClick={healthRecordHandler} >
              <ListItemIcon>
                <ListItemText>Health Record</ListItemText>
              </ListItemIcon>
            </ListItemButton>
            <ListItemButton key={1} onClick={shareHealthRecordHandler}>
              <ListItemIcon>
                <ListItemText>Share</ListItemText>
              </ListItemIcon>
            </ListItemButton>
            <ListItemButton key={2}  onClick={healthRecordSharedWithMeHandler} >
              <ListItemIcon>
                <ListItemText> Shared with me</ListItemText>
              </ListItemIcon>
            </ListItemButton>
            <ListItemButton key={5} onClick={login}>
              <ListItemIcon>
                <ListItemText> Login</ListItemText>
              </ListItemIcon>
            </ListItemButton>
            <ListItemButton key={3} onClick={logoutHandler}>
              <ListItemIcon>
                <ListItemText> Logout</ListItemText>
              </ListItemIcon>
            </ListItemButton>

        </List>
      </Drawer>
      <IconButton
        sx={{ color: "black", marginLeft: "auto" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon color="black" />
      </IconButton>
    </React.Fragment>
  );
};

export default DrawerComp;
