import { Fragment } from 'react';
import  React,{useEffect,useState,useContext } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PersonalizedButton from "../../Layout/Button"
import FormControl from "@mui/material/FormControl";
import {numbersNotAllowed,emptyCheck,dateFormatter} from "../../../methods/functions"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MedicalProviderContext from '../MedicalProvider/medicalProvider-context';

export default function InsuranceRecord() {
  const medicalCtx = useContext(MedicalProviderContext);

  const [nameField, setNameField] = useState('');
  const [officeField, setOfficeField] = useState('');
  const [specialtyField, setSpecialtyField] = useState();
  const [addressField, setAddressField] = useState();
  const [contactNumberField, setContactNumberField] = useState();

  const [nameFieldError, setNameFieldError] = useState();
  const [officeFieldError, setOfficeFieldError] = useState();
  const [specialtyFieldError, setSpecialtyFieldError] = useState();
  const [addressFieldError, setAddressFieldError] = useState();
  const [contactNumberFieldError, setContactNumberFieldError] = useState();																							  
  const [recordOption ,setRecordOption] = useState(0);

  const createMedicalURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICAL_PROVIDER_CREATE;
  
  const handleChangeRecordOption = (event) => {
    if(event.target.value==0)
    {
      setRecordOption(0);
      setNameField('');
      setOfficeField('');
      setSpecialtyField('');
      setAddressField('');
      setContactNumberField('');
    }
    setRecordOption(event.target.value);
  };

  const handleSubmitInsurance = (event) => {
    event.preventDefault();
    setNameFieldError(false);
    setOfficeFieldError(false);
    setSpecialtyFieldError(false);
    setAddressFieldError(false);
    setContactNumberFieldError(false);

    let name=nameField;
    let office= officeField;
    let specialty= specialtyField;
    let address = addressField;
    let contactNumber=contactNumberField;

    let finalDecision=true;

    name= name? name :'';
    if(emptyCheck(name)){
      setNameFieldError(true);
      finalDecision=false;
    }

    office= office? office :'';
    if(emptyCheck(office)){
      setOfficeFieldError(true);
      finalDecision=false;
    }

    specialty= specialty? specialty :'';
    if(emptyCheck(specialty)){
      setSpecialtyFieldError(true);
      finalDecision=false;
    }

    address= address? address :'';
    if(emptyCheck(address)){
      setAddressFieldError(true);
      finalDecision=false;
    }

    contactNumber= contactNumber? contactNumber:'';
    if(contactNumber.trim().length>1)
    {
      if(numbersNotAllowed(contactNumber)){
        setContactNumberFieldError(true);
        finalDecision=false;
      }
      else
      {
          if(contactNumber<0 || contactNumber ==-0)
          {
            setContactNumberFieldError(true);
            finalDecision=false;
          }
      }
    }
    else
    {
      setContactNumberFieldError(true);
      finalDecision=false;
    }


    if(finalDecision)
    {					 
      setNameFieldError(false);
      setOfficeFieldError(false);
      setSpecialtyFieldError(false);
      setAddressFieldError(false);
      setContactNumberFieldError(false);

      if(recordOption==0){
          const createData = 
          {
            userId:localStorage.getItem("userId"),
            doctorOffice: office,
            doctorName: name,
            specialty: specialty,
            address: address,
            contactNumber: contactNumber
          }

          const requestOptions = {
            method: 'POST',
            headers: { 
              
              'Content-Type': 'application/json',
              "Token": localStorage.getItem("token")  
          
            },
                body: JSON.stringify(createData)
            };
            try{
              fetch(createMedicalURL, requestOptions)
                  .then(response => response.json());
                  alert("Done");
                  setNameField('');
                  setOfficeField('');
                  setSpecialtyField('');
                  setAddressField('');
                  setContactNumberField('');
                  medicalCtx.items=[];
                  medicalCtx.addItem(medicalCtx.items);
            }
            catch{
                alert("Error");
            }       
      }
      else
      {
        setRecordOption(0);
        const updateData = 
        {
          userId:localStorage.getItem("userId"),
          documentId:medicalCtx.items.documentNumber,
          doctorOffice: office,
          doctorName: name,
          specialty: specialty,
          address: address,
          contactNumber: contactNumber
        };

        const userUpdate=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICAL_PROVIDER_UPDATE;
        const requestOptions = {
          method: 'PUT',
          headers: { 
            
            'Content-Type': 'application/json',
            "Token": localStorage.getItem("token")
          },
            body: JSON.stringify(updateData)
       };
       try{
       fetch(userUpdate, requestOptions)
       .then(response => response.json());
       }
       catch
       {
        alert("Error");
       }
        setNameField('');
        setOfficeField('');
        setSpecialtyField('');
        setAddressField('');
        setContactNumberField('');
        alert("Done");
        medicalCtx.items=[];
        medicalCtx.addItem(medicalCtx.items);
      }

    }

    

  };
  
  
    useEffect(() => {
      if(medicalCtx.items.option==2)
      {
        setRecordOption(1);
      }
       setNameField(medicalCtx.items.name);
       setOfficeField(medicalCtx.items.office);
       setSpecialtyField(medicalCtx.items.specialty);
       setAddressField(medicalCtx.items.address);
       setContactNumberField(medicalCtx.items.contactNumber);
      // eslint-disable-next-line
    }, [medicalCtx.count]);

  const changeNameField = (event) => {
    setNameField(event.target.value);
  };

  const changeContactNumberField = (event) => {
    setContactNumberField(event.target.value);
  };
			
  const changeOfficeField = (event) => {
    setOfficeField(event.target.value);
  };

  const changespecialtyField = (event) => {
    setSpecialtyField(event.target.value);
  };

  const changeAddressField = (event) => {
    setAddressField(event.target.value);
  };

 
  return (
        <Box
        component="form"
         noValidate
        onSubmit={handleSubmitInsurance}
            sx={{
              my: 4,
              mx: 12,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >  
              <Typography component="h1" variant="h5">
                  Record
                </Typography>
                <FormControl sx={{ m: 2}} fullWidth>
                      <RadioGroup
                        sx={{ display: 'inline' }}
                        aria-labelledby="record-radio-buttons-group-label"
                        name="record-radio-buttons-group"
                        value={recordOption}
                        onChange={handleChangeRecordOption}
                      >
                        <FormControlLabel value="0" control={<Radio />} label="Create" />
                        <FormControlLabel value="1" disabled control={<Radio />} label="Update" />
                      </RadioGroup>
              </FormControl>
                <TextField
                        InputLabelProps={{ shrink: true }}
                        error= {nameFieldError? true:false}
                        sx={{ m: 2}}
                        margin="normal"
                        fullWidth
                        id="name"
                        label="Doctor Name"
                        name="name"
                        value={nameField}
                        onChange={changeNameField}
                                        
                />      
                <TextField
                        InputLabelProps={{ shrink: true }}
                        error= {officeFieldError? true:false}
                        sx={{ m: 2}}
                        margin="normal"
                        fullWidth
                        id="name"
                        label="Doctor Office"
                        name="name"
                        value={officeField}
                        onChange={changeOfficeField}
                                        
                />    
                <TextField
                        InputLabelProps={{ shrink: true }}
                        error= {specialtyFieldError? true:false}
                        sx={{ m: 2}}
                        margin="normal"
                        fullWidth
                        id="specialty"
                        label="Specialty"
                        name="specialty"
                        value={specialtyField}
                        onChange={changespecialtyField}
                                        
                />        
                <TextField
                        InputLabelProps={{ shrink: true }}
                        error= {addressFieldError? true:false}
                        sx={{ m: 2}}
                        margin="normal"
                        fullWidth
                        id="address"
                        label="Address"
                        name="address"
                        value={addressField}
                        onChange={changeAddressField}
                                        
                />       
               <TextField
                error= {contactNumberFieldError? true:false}
                margin="normal"
                fullWidth
                id="contactNumber"
                label="Contact Number"
                InputLabelProps={{ shrink: true }}
                type="number"
                name="contactNumber"
                value={contactNumberField}
                onChange={changeContactNumberField}
                
              />       
              <PersonalizedButton  
              type="submit"
                variant="contained"
                 sx={{ mt: 1, mb: 2 }}
              >
                  Save
              </PersonalizedButton>    
        </Box>
  );
}
