import  React,{useEffect,useState,useContext} from 'react';
import { Fragment } from 'react';
import HealthRecordSharedWithMeReport from './HealthRecordSharedWithMeReport';
import HealthRecordSharedWithMeProvider from './healthRecordSharedWithMe-Provider';
import ViewSharedHealthReport from '../ViewSharedHealthReport/ViewSharedHealthReport'
import Divider from "@mui/material/Divider";
import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& > :not(style) + :not(style)': {
    marginTop: theme.spacing(2),
  },
}));

const ShareHealthRecordCategory = () => {
  return (
      <HealthRecordSharedWithMeProvider>
            <Root>
            <Divider><h2>Shared with me</h2></Divider>
            </Root> 
            <HealthRecordSharedWithMeReport />
            <ViewSharedHealthReport/>

          
      </HealthRecordSharedWithMeProvider>

  );
};

export default ShareHealthRecordCategory;
