import  React,{useEffect,useState,useContext} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography
} from "@material-ui/core";
import TablePagination from '@mui/material/TablePagination';
import DeleteIcon from "@material-ui/icons/Delete";
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import ObgynContext from './obgyn-context';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    borderRadius: 15,
    margin: "10px 10px",
    maxWidth: "1800px",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    backgroundColor: "#4788B8",
    color: theme.palette.getContrastText(theme.palette.primary.dark)
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
  name: {
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
  },
  status: {
    fontWeight: "bold",
    fontSize: "0.75rem",
    color: "white",
    backgroundColor: "grey",
    borderRadius: 8,
    padding: "3px 10px",
    display: "inline-block",
  },
  hover: {
    cursor: "pointer",
  },
  center:{
    textAlign:'center'
  }
}));

const tableHeader = [
  { label: "Age at first period", data: "ageAtFirstPeriod" },
  { label: "Number of pregnancies", data: "numberOfPregnancies" },
  { label: "Concern Sexual Activity", data: "concernSexualActivity" },
  { label: "Abnormal Pap Smears", data: "abnormalPapSmear" },
  { label: "Treatment for abnormal smears", data: "treatmentForAbnormalSmear" },
  { label: "Date of last mammogram", data: "dateOfLastMammogram" },
  { label: "Abnormal Mammogram", data: "abnormalMammogram" },
  { label: "Update", data: "whiteSpaceDelete" }
];

function createData(
  document,
  id,
  ageFirstPeriod,
  numberOfPregnancies,
  concernSexualActivity,
  abnormalPapSmear,
  dateOfLastMammogram,
  abnormalMammogram,
  treatmentForAbnormalSmear,
  option
  ) {
  return {
    document,
    id,
    ageFirstPeriod,
    numberOfPregnancies,
    concernSexualActivity,
    abnormalPapSmear,
    dateOfLastMammogram,
    abnormalMammogram,
    treatmentForAbnormalSmear,
    option
  };
}

const MTable = (props) => {
  const obgynCtx = useContext(ObgynContext);
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [medicalHistoryData, setData] = useState([]);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const getURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICAL_HISTORY_GET;
  useEffect(() => {
      let array=[];
      fetch(getURL ,{
        method: 'GET',
        headers: {  
        'Content-Type': 'application/json',
        'Token': localStorage.getItem("token"),
        'userid':localStorage.getItem("userId")     
        }
      })
      .then((response) => response.json())
      .then((datax) => { 
           let arrayX=undefined;
           let dateOfLastMammogram=undefined;
           try
           {
           arrayX= datax.body[0].obgyn;
           dateOfLastMammogram= arrayX.mammogram.dateOfLastMammogram;
           }
           catch
           {
             arrayX=0;
           }

           if(arrayX!=0){
                        
           array.push(
            createData(
              datax.body[0].id,
              1,
              arrayX.ageFirstPeriod,
              arrayX.numberOfPregnancies,
              arrayX.concernSexualActivity,
              arrayX.abnormalPapSmear,
              dateOfLastMammogram,
              arrayX.mammogram.abnormalMammogram,
              arrayX.abnormalPapSmear.treatmentForAPS
              )
          );
           }
          
            setData(array);     
        })

      // eslint-disable-next-line
    }, [obgynCtx.count]);

  const updateItem = (index) => {
    medicalHistoryData[index].option=2;
    obgynCtx.addItem(medicalHistoryData[index]);
    //console.log(medicalHistoryData[index]);
  };

  const styleObj = {
    fontSize: 30,
    color: "black",
}
  return (
    <React.Fragment>
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableHeader.map((cell) => (
              <TableCell key={cell.data} className={classes.tableHeaderCell}>
                {cell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
        {(rowsPerPage > 0
            ? medicalHistoryData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : medicalHistoryData
          ).map((row,i) => (
            <TableRow key={row.id}>
              <TableCell>{row.ageFirstPeriod}</TableCell>
              <TableCell>{row.numberOfPregnancies}</TableCell>
              <TableCell>{row.concernSexualActivity==1?"Yes":"No"}</TableCell>
              <TableCell>{row.abnormalPapSmear==1?"Yes":"No"}</TableCell>
              <TableCell>{row.treatmentForAbnormalSmear}</TableCell>
              <TableCell>{row.dateOfLastMammogram}</TableCell>
              <TableCell>{row.abnormalMammogram==1?"Yes":"No"}</TableCell>

              <TableCell>
                <Grid container>
                  <Grid item lg={12}>
                    <Typography>
                      <AutoFixNormalIcon
                        color="primary"
                        onClick={updateItem.bind(this, i)}
                        className={classes.hover}
                        />
                      </Typography>
                </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={medicalHistoryData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
    </TableContainer>

    </React.Fragment>
  );
};

export default MTable;