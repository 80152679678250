import classes from './StartingPageContent.module.css';
import React  from 'react';
const StartingPageContent = () => {
  return (
    <section className={classes.starting}>
      
    </section>
  );
};

export default StartingPageContent;
