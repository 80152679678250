import { Fragment } from 'react';
import  React,{useEffect,useState,useContext } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import PersonalizedButton from "../../Layout/Button"
import FormControl from "@mui/material/FormControl";
import { useTheme } from '@mui/material/styles';
import {numbersNotAllowed,validateEmail,emptyCheck,dateFormatter} from "../../../methods/functions"
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ShareHealthRecordContext from './shareHealthRecord-context';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';

export default function ShareHealthRecordInvite() {

  const [arrayFieldMasterCategory ,setArrayMasterCategory] = React.useState([]);
  const [relationshipField ,setFieldRelationshipCategory] = React.useState([]);
  const [arrayRelationshipFieldCategory ,setArrayRelationshipCategory] = React.useState([]);
  const shareHealthRecordCtx = useContext(ShareHealthRecordContext);
  const [familyRelationshipField,setFamilyRelationshipField] = React.useState(1);
  const [arrayFieldFamilyRelationshipCategory ,setArrayFieldFamilyRelationshipCategory] = React.useState([]);
  const [accessFieldError, setAccessFieldError] = useState();
  const [showFamilyList,setShowFamilyList]= useState(0);
  const [emailError, setEmailError] = useState();
  const [emailField, setEmailField] = useState('');

  const createURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_FAMILY_RELATIONSHIP_CREATE;
  function getFamilyMembers(){
    const getCatalog=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_FAMILY_RELATIONSHIP_CATALOG;
    return fetch(getCatalog ,{
     method: 'GET',
     headers: {  
     'Content-Type': 'application/json',
     'Token': localStorage.getItem("token")    
     }
   })
   .then((response) => response.json())
   .then((data) => {   

    let arrFamilyRelationshipCategory=[];
	  let datax=[];
    let count=0;
    try
    {
     datax= data.body.relationship;

    }
    catch
    {
      datax=[];
    }

    for(let row in datax)
    {
      if(datax[row].toLowerCase()!="self")
      {
		  count=count+1;
      arrFamilyRelationshipCategory.push({
         index: count,
         category:datax[row],
         label:datax[row],
          key: parseInt(row)
         });              
        }
    }
    setArrayFieldFamilyRelationshipCategory(arrFamilyRelationshipCategory);
    setFieldRelationshipCategory(0);
   });
  }

  function getCategoryCatalog(){
    let getCategoryCatalog=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_CATEGORY_CATALOG;
    return fetch(getCategoryCatalog ,{
     method: 'GET',
     headers: {  
     'Content-Type': 'application/json',
     'Token': localStorage.getItem("token"),
     'userid':localStorage.getItem("userId")     
     }
   })
   .then((response) => response.json())
   .then((data) => {
          let datax=undefined;
            
          try
          {
          datax= data.body.category;
  
          }
          catch
          {
            datax=[];
          }
          let master=[];
          for(let row in datax)
          {
            master.push({
              index: row,
              subCategoryId: row,
              subCategory: "",
              categoryId: row,
              category:datax[row],
              label:datax[row],
              key: row
              }) ;  

          }
              setArrayMasterCategory(master);
          }      
   );
  
        
  }


  function getData(){          
    const getCatalog=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_RELATIONSHIP_GET;
     return fetch(getCatalog ,{
     method: 'GET',
     headers: {  
     'Content-Type': 'application/json',
     'Token': localStorage.getItem("token"),
     'userid':localStorage.getItem("userId")     
     }
   })
   .then((response) => response.json())
   .then((data) => {
    let arrCategory=[];
    let count=0;
    let datax=undefined;
    
    try
    {
     datax= data.body.category;

    }
    catch
    {
      datax=[];
    }

    for(let category in datax)
    {
        count=count+1;
          arrCategory.push({
            index: count,
            category:datax[category],
            label:datax[category],
            key: parseInt(category)
           })    
    }

    setArrayRelationshipCategory(arrCategory);
   });

  }
  const changeEmailField = (event) => {
    setEmailField(event.target.value);
};
  const [access, setAccess] = React.useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
      let valuex=  typeof value === 'string' ? value.split(',') : value;
      setAccess(
        valuex
      );
  };
  const handleShareHealthRecord = (event) => {
    event.preventDefault();
    setAccessFieldError(false);
    let familyMember = familyRelationshipField;
    let finalDecision=true;

    if(access.length==0)
    {
      setAccessFieldError(true);
      finalDecision=false;
    }
    else
    {
      setAccessFieldError(false);
    }
    if(!validateEmail(emailField)){
      setEmailError(true);
      finalDecision=false;
    }
    else
    {
      setEmailError(false);
    }
  
    if(finalDecision)
    {
      const getUser=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_GET_USER;
      fetch(getUser ,{
        method: 'GET',
        headers: {  
         'Content-Type': 'application/json',
         'Token': localStorage.getItem("token"),
          'id':  localStorage.getItem("userId")           
        }
      })
      .then(response=> response.json())
      .then(data => {
        let categories=access;
        categories=categories.map((item) => {
          return parseInt(item.categoryId)
        
        });

        const create = 
        {
          recipientEmail:emailField,
          userId:localStorage.getItem("userId"),
          userName:data.body.name + " " + data.body.lastName,
          familyRelationship:showFamilyList==1?familyMember:"",
          relationship:relationshipField,
          categories:categories
        };
        const createURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_INVITE;

        const requestOptions = {
          method: 'POST',
          headers: { 
            
            'Content-Type': 'application/json',
            "Token": localStorage.getItem("token")  
        
          },
              body: JSON.stringify(create)
          };
          try{
            fetch(createURL, requestOptions)
                .then(response => response.json());
                alert("Done");      
                setAccess([]);
                shareHealthRecordCtx.items=[];
                setEmailField("");
                shareHealthRecordCtx.addItem(shareHealthRecordCtx.items);
          }
          catch{
              alert("Error");
          } 
         
      }); 



    
    }
  
    

  };
  
  
    useEffect(() => {
      if(arrayFieldMasterCategory.length==0){
        getData();
        getCategoryCatalog();
        getFamilyMembers();
       }
      // eslint-disable-next-line
    }, [shareHealthRecordCtx]);


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  }
};

const handleChangeOptionRelationshipCategory = (event) => {

  setFieldRelationshipCategory(event.target.value);
  if(arrayRelationshipFieldCategory[event.target.value].category.toLowerCase()=="family member")
  {
    setShowFamilyList(1);
  }
  else
  {
    setShowFamilyList(0);
  }
};

const handleChangeOptionFamilyRelationshipCategory = (event) => {
  setFamilyRelationshipField(event.target.value);
};
const theme = useTheme();
function getStyles(option, access, theme) {
  return {
    fontWeight:
    access.indexOf(option) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}





  return (
        <Box
        component="form"
         noValidate
        onSubmit={handleShareHealthRecord}
            sx={{
              my: 4,
              mx: 12,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          > 
              <FormControl  sx={{ mt: -4, mx: 0 }} >
              <TextField
                margin="normal"
                fullWidth
                id="email"
                label="Email"
                name="email"
                onChange={changeEmailField}
                value={emailField}
              error= {emailError? true:false}
              />  
               </FormControl>
              <React.Fragment>

              <FormControl  sx={{ mt: 2}} >
              <InputLabel sx={{ mt: 1 }} id="optionLabel">Relationship</InputLabel>
              <Select
                        labelId="relationship"
                        id="relationship"
                        label="Relationship"
                        value={relationshipField}
                        onChange={handleChangeOptionRelationshipCategory}
                        >
                            {
                              arrayRelationshipFieldCategory.map((option) => (
                                  <MenuItem  key={option.key} value={option.key}>{option.label}</MenuItem>
                                ))
                            }
              </Select>
             </FormControl>
             </React.Fragment>
             {
              showFamilyList==1?
              <React.Fragment>
                            <FormControl  sx={{ mt: 3, width: 300}} >
                            <InputLabel sx={{ mt: 1 }} id="optionFamilyRelationshipLabel">Family Relationship</InputLabel>
                            <Select
                                      labelId="optionLabel"
                                      id="option"
                                      label="Family Relationship"
                                      value={familyRelationshipField}
                                      onChange={handleChangeOptionFamilyRelationshipCategory}
                                      >
                                          {
                                            arrayFieldFamilyRelationshipCategory.map((option) => (
                                                <MenuItem  key={option.key} value={option.key}>{option.label}</MenuItem>
                                              ))
                                          }
                            </Select>
                          </FormControl>
             </React.Fragment>:
             <React.Fragment></React.Fragment>
} 
                <FormControl sx={{ m: 2, width: 300 }}>
                <InputLabel id="demo-multiple-chip-label">Access</InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  error= {accessFieldError? true:false}
                  value={access}
                  onChange={handleChange}
                  input=      {
                  <OutlinedInput id="select-multiple-chip" label="Access" /> 
              }
                  renderValue={(selected,i) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value.index} label={value.subCategory==""? value.category : value.subCategory} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {arrayFieldMasterCategory.map((option,i) => (
                    <MenuItem
                      key={option.index}
                      value={option}
                      style={getStyles(option,access, theme)}
                    >
                      {option.subCategory==""?  option.category: option.subCategory + ","  + option.category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
        <React.Fragment>

    </React.Fragment>
              <React.Fragment></React.Fragment>
              <PersonalizedButton  
              type="submit"
                variant="contained"
                 sx={{ mt: 2, mb: 3 }}
              >
                  Save
              </PersonalizedButton>    
        </Box>
  );
}
