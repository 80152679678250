import React,{useState,useEffect}  from 'react';
import { Fragment } from 'react';
import PregnancyHistoryRecord from './PregnancyHistoryRecord';
import PregnancyHistoryReport from './PregnancyHistoryReport';
import  PregnancyHistoryProvider from './pregnancyHistory-Provider';
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import { styled } from '@mui/material/styles';
const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& > :not(style) + :not(style)': {
    marginTop: theme.spacing(2),
  },
}));
const PregnancyHistoryCategory = () => {
  return (
      <PregnancyHistoryProvider>
            <PregnancyHistoryReport />
            <PregnancyHistoryRecord/>
      </PregnancyHistoryProvider>

  );
};

export default PregnancyHistoryCategory;
