import React  from 'react';
import { Fragment } from 'react';
import SocialHistoryRecord from './SocialHistoryRecord';
import SocialHistoryReport from './SocialHistoryReport';
import SocialHistoryProvider from './socialHistory-Provider';
import Box from '@mui/material/Box';
const SocialHistoryCategory = () => {
  return (
      <SocialHistoryProvider>
            <SocialHistoryReport/>
            <SocialHistoryRecord/>
      </SocialHistoryProvider>

  );
};

export default SocialHistoryCategory;
