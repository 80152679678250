import { Fragment } from 'react';
import MainNavigation from './MainNavigation';
import  React,{ useRef,useEffect,useState,useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PersonalizedButton from "../Layout/Button"
import { useHistory } from 'react-router-dom';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {validateEmail,numbersNotAllowed,emptyCheck,dateFormatter,dateIsValid} from "../../methods/functions"
import image from '../../assets/defaultImage.jpg';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AuthContext from '../../store/auth-context';

const theme = createTheme();
function RedBar(props) {
  return (
    <Box
      sx={{
        height: props.height,
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? 'rgba(255, 0, 0, 0.1)'
            : 'rgb(255 132 132 / 25%)',
      }}
    > 
    <div align="left" 
    style=
    {{
      mt: props.mt, 
      mb: props.mb,
      paddingTop:props.paddingTop,
      paddingLeft:props.paddingLeft,
      paddingBottom:props.paddingBottom
    }}
    > 
    {props.children} 
    </div> 
 
    
    </Box>
   
  )
}

export default function Layout() {
  const [render, setRender] = useState();
  const [prevFile, setPrevFile] = useState();
  const [isPrev, setIsPrev] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const inputRef = useRef(null);
  const [imageError, setImageError] = useState(false);
  const [imageField, setImageField] = useState('');
  const authCtx = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [emailError, setEmailError] = useState();
  const [countryError, setCountryError] = useState();
  const [nameNullError, setNameNullError] = useState();
  const [lastNameNullError, setLastNameNullError] = useState();
  const [zipCodeError, setZipCodeError] = useState();
  const [telephoneNumberError, setTelephoneNumberError] = useState();
  const [genderError, setGenderError] = useState();
  const [dateError, setDateError] = useState();
  const [gender, setGender] = React.useState(0);
  const [dateValue, setDateValue] = React.useState(new Date());
  const [user, setUser] = useState([]);

 const [countryField, setCountryField] = useState('');
  const [nameField, setNameField] = useState('');
  const [middleNameField, setMiddleNameField] = useState('');
  const [lastNameField, setLastNameField] = useState('');
  const [emailField, setEmailField] = useState('');
  const [genderField, setGenderField] = useState(1);
  const [dataIdDocumentField, setDataIdDocumentField] = useState('');
  const [cityField, setCityField] = useState('');
  const [dateOfBirthField, setDateOfBirthField] = useState('');
  const [stateField, setStateField] = useState('');
  const [streetField, setStreetField] = useState('');
  const [telePhoneNumberField, setTelePhoneNumberField] = useState();
  const [urlField, setURLField] = useState('');
  const [zipCodeField, setZipCodeField] = useState();
  const changeCountryField= (event) => {
    setCountryField(event.target.value);
  };

  const changeNameField= (event) => {
    setNameField(event.target.value);
  };

  const changeMiddleNameField= (event) => {
    setMiddleNameField(event.target.value);
  };
  const changeLastNameField= (event) => {
    setLastNameField(event.target.value);
  };

  const changeEmailField= (event) => {
    setEmailField(event.target.value);
  };

  const changeGenderField= (event) => {
    setGenderField(event.target.value);
  };
  const changeCityField= (event) => {
    setCityField(event.target.value);
  };
  const changeDateOfBirthField= (event) => {
    setDateOfBirthField(event.target.value);
  };
  const changeStreetField= (event) => {
    setStreetField(event.target.value);
  };
  const changeStateField= (event) => {
    setStateField(event.target.value);
  };
  const changeTelePhoneNumberField= (event) => {
    setTelePhoneNumberField(event.target.value);
  };
  const changeZipCodeField= (event) => {
    setZipCodeField(event.target.value);
  };


  
  const downloadURL= process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_USER_DOWNLOAD;
  const getUserURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_GET_USER;
  function getUser() {
    setLoading(true);
    fetch(getUserURL,{
      method: 'GET',
      headers: {  
       'Content-Type': 'application/json',
       'Token': localStorage.getItem("token"),
        'id':  localStorage.getItem("userId")           
      }
    })
    .then(response=> response.json())
    .then(data => {
      const items = [];
      setDataIdDocumentField(data.body.documentId);
      setCountryField(data.body.country);
      setNameField(data.body.name);
      setMiddleNameField(data.body.middleName);
      setLastNameField(data.body.lastName);
      setEmailField(data.body.email);
      setTelePhoneNumberField(data.body.telephoneNumber);
      setStreetField(data.body.street);
      setCityField(data.body.city);
      setStateField(data.body.state);
      setZipCodeField(data.body.zipCode);
      setGender(data.body.gender);
      setDateValue(new Date(data.body.dateOfBirth));
      let url= data.body.url?data.body.url.toString() : '';
      if(!emptyCheck(url))
      {
        const downloadURL= process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_USER_DOWNLOAD;
    
        var myHeaders = new Headers();
        myHeaders.append("Token", localStorage.getItem("token"));
        myHeaders.append("userid", localStorage.getItem("userId") );
        myHeaders.append("filename", data.body.url);
        
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
        };
       
     
        fetch(downloadURL, requestOptions)
          .then(response => response.json())
          .then(result => {
            let file= new Uint8Array(result.body.content[0].data);
            const blob = new Blob([file]); 
            const url= window.URL.createObjectURL(blob);
            setIsPrev(0);
            setPrevFile(url);
          }
        
          
          )
          .catch(error => console.log('error', error));
      }
      setURLField(url);
      setLoading(false);
    }); 
  }

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);

  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };

  const datax = authCtx.token;

  const dateHandleChange = (newValue) => {
    setDateValue(newValue);
  };

  const history = useHistory();
  const handleSubmit = (event) => {
    event.preventDefault();
    setError(false);
    setEmailError(false);
    setCountryError(false);
    setLastNameNullError(false);
    setNameNullError(false);
    setDateError(false);
    setZipCodeError(false);
    setTelephoneNumberError(false);
    setGenderError(false);
    setImageError(false);
    let name=nameField;
    let middleName=middleNameField;
    let lastName=lastNameField;
    let email=emailField;
    let telephoneNumber=telePhoneNumberField;
    let street=streetField;
    let city=cityField;
    let state=stateField;
    let country=countryField;
    let dateOfBirth=dateValue;
    let zipCode = zipCodeField;
    let finalDecision=true;
    let genderD = gender;
    let lastFileName=urlField;
    fetch(getUserURL ,{
      method: 'GET',
      headers: {  
       'Content-Type': 'application/json',
       'Token': localStorage.getItem("token"),
        'id':  localStorage.getItem("userId")           
      }
    })
    .then(response=> response.json())
    .then(data => {
      lastFileName=data.body.url;
        if(!validateEmail(email)){
          setEmailError(true);
          finalDecision=false;
        }
    
        if(!numbersNotAllowed(country)){
          setCountryError(true);
          finalDecision=false;
        }
    
        if(numbersNotAllowed(genderD))
        {
          setGenderError(true);
          finalDecision=false;
        }
        zipCode= zipCode? zipCode:'';
        if(zipCode.trim().length>0)
        {
          if(numbersNotAllowed(zipCode) && zipCode.trim().length){
            setZipCodeError(true);
            finalDecision=false;
          }
        }
    
      lastName= lastName? lastName :'';
        if(emptyCheck(lastName)){
          setLastNameNullError(true);
          finalDecision=false;
        }
        name= name? name :'';
        if(emptyCheck(name)){
          setNameNullError(true);
          finalDecision=false;
        }
        telephoneNumber= telephoneNumber? telephoneNumber:'';
        if(telephoneNumber.trim().length>0)
        {
          if(numbersNotAllowed(telephoneNumber) && telephoneNumber.trim().length){
            setTelephoneNumberError(true);
            finalDecision=false;
          }
        }
        let existsImage=0;
        let actualFilename="";
        try
        {
          let indexExtension=selectedFile.type.indexOf("/") +1;
          let length=selectedFile.type.length;
          let extension= selectedFile.type.substring(indexExtension,length);

          existsImage=1;
          lastFileName=selectedFile.name;
          actualFilename=selectedFile.name;
          switch(extension)
          {
            case "jpg":
              setImageError(false);
              break;
            case "png":
              setImageError(false);
              break;
            case "jpeg":
              setImageError(false);
              break;
            default:
              setImageError(true);
              finalDecision=false;
            break;
          }
        }
        catch
        {
          existsImage=0;
        }
        
        if(isNaN(dateOfBirth) || dateOfBirth==null || dateIsValid(dateOfBirth) == false)
        {
          setDateError(true);
          finalDecision=false;
        }

        if(finalDecision)
        {
          setRender(1);
          let birthDatee = dateFormatter(dateOfBirth);
          setDateError(false);
          setImageError(false);
          setEmailError(false);
          setCountryError(false);
          setNameNullError(false);
          setLastNameNullError(false);
          setZipCodeError(false);
          setTelephoneNumberError(false);
          setError(false);
    
        const userUpdate=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_USER_UPDATE;
    
        let formdata = new FormData();
  
        if(existsImage==1)
        {
            formdata.append("existsImage", 1);
            formdata.append("file", selectedFile);
            let url= data.body.url?data.body.url.toString() : '';
            if(!emptyCheck(url))
            {
                const deleteUserPictureURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_USER_PICTURE_DELETE;
                const deleteData = 
                {
                  userId:localStorage.getItem("userId"), 
                  lastFileName: data.body.url
                };
      
                const requestOptionsDelete = {
                  method: 'DELETE',
                  headers: { 
                  'Content-Type': 'application/json',
                  "Token": localStorage.getItem("token")  
                  },
                  body: JSON.stringify(deleteData)
                };
                try{
                  fetch(deleteUserPictureURL, requestOptionsDelete)
                  .then(response => response.json());
                  }
                  catch{
                alert("Error");
                  }
            }
     
        }
        else{
          formdata.append("existsImage", 0);
          formdata.append("lastFileName", lastFileName);
        }
    
          formdata.append("userId", localStorage.getItem("userId"));
          formdata.append("documentId",dataIdDocumentField);
          formdata.append("name", name);
          formdata.append("middleName", middleName);
          formdata.append("lastName",lastName);
          formdata.append("telephoneNumber",  telephoneNumber);
          formdata.append("street", street);
          formdata.append("city", city);
          formdata.append("country", country);
          formdata.append("state", state);
          formdata.append("dateOfBirth",birthDatee);
          formdata.append("zipCode", zipCode);
          formdata.append("gender", gender);
    
          let myHeaders = new Headers();
          myHeaders.append("Token",localStorage.getItem("token"));
          let requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: formdata
          };
    
        
          try{
            fetch(userUpdate, requestOptions)
                .then(response => response.json());
                alert("Done");
                setURLField("");
                setSelectedFile(null);
                resetFileInput()
          }
          catch{
              alert("Error");
          }  
          fetch(getUserURL,{
            method: 'GET',
            headers: {  
             'Content-Type': 'application/json',
             'Token': localStorage.getItem("token"),
              'id':  localStorage.getItem("userId")           
            }
          })
          .then(response=> response.json())
          .then(data => {
            let url= data.body.url?data.body.url.toString() : '';
            if(!emptyCheck(url))
            {
          
              var myHeaders = new Headers();
              myHeaders.append("Token", localStorage.getItem("token"));
              myHeaders.append("userid", localStorage.getItem("userId") );
              myHeaders.append("filename", data.body.url);
              
              var requestOptions = {
                method: 'GET',
                headers: myHeaders,
              };
             
              setTimeout(
              fetch(downloadURL, requestOptions)
                .then(response => response.json())
                .then(result => {
                  let file= new Uint8Array(result.body.content[0].data);
                  const blob = new Blob([file]); 
                  const url= window.URL.createObjectURL(blob);
                  setPrevFile(url);
                }
              
                
                )
                .catch(error => console.log('error', error)),3000);
            }
          }); 
        }
        else
        {
          setError(true);
        }
    } 
    );
  };

  const resetFileInput = () => {
    // 👇️ reset input value
    inputRef.current.value = null;
  };
  return (
   <Fragment>
    <ThemeProvider theme={theme}>
    <MainNavigation />
    <Box
            sx={{
              my: 1,
              mx: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
           <Grid container component="main"  sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={3}
          md={7}
          sx={{
            backgroundImage: `url(${image})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper}  square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
             {
              isPrev==0 ?
                <div sx={{ mt: 2, mx: 0 }}>
                <img alt="profilePicture" style={{borderRadius: 15 + 'px'}} width={"250px"} height={"250px"} src={prevFile} />
                <br />
                </div>
               :<React.Fragment></React.Fragment>
             }
                 {
              isPrev==1 ?
                <div sx={{ mt:2, mx: 0 }}>
                <img alt="profilePicture" style={{borderRadius: 15 + 'px'}} width={"250px"} height={"250px"}  />
                <br />
                </div>
               :<React.Fragment></React.Fragment>
             }
            {selectedFile && (
              <div sx={{ mt: 2, mx: 0 }}>
              <img alt="profilePicture" style={{borderRadius: 15 + 'px'}} width={"250px"} height={"250px"} src={URL.createObjectURL(selectedFile)} />
              <br />
              <button onClick={()=>{resetFileInput();setSelectedFile(null);}}>Remove</button>
              </div>
            )}
            <FormControl  sx={{ mt: 3, mx: 0 }} >
                <input
                ref={inputRef}
                  type="file"
                  name="file"
                  onChange={(event) => {
                    setSelectedFile(event.target.files[0]);
                  }}
                />
             </FormControl>
            <Typography component="h1" variant="h5"  sx={{ mt: 4}}>
              Profile settings
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>  
                  
              <Fragment > 
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="First Name"
                name="name"
                value={nameField}
                onChange={changeNameField}
                autoComplete="name"
                autoFocus
              />       
                <TextField
                margin="normal"
                fullWidth
                id="middleName"
                label="Middle Name"
                name="middleName"
                value={middleNameField}
                onChange={changeMiddleNameField}
                
              />      
                <TextField
                margin="normal"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                value={lastNameField}
                onChange={changeLastNameField}
                autoComplete="family-name"
                
              />       
              <TextField
                margin="normal"
                fullWidth
                id="email"
                label="Email"
                name="email"
                disabled
                value={emailField}
              />  
              <TextField
                margin="normal"
                fullWidth
                id="telephoneNumber"
                label="Telephone Number"
                InputLabelProps={{ shrink: true }}
                type="number"
                name="telephoneNumber"
                value={telePhoneNumberField}
                onChange={changeTelePhoneNumberField}
                autoComplete="phone"
                
              />
               <TextField
                margin="normal"
                fullWidth
                id="street"
                label="Street"
                name="street"
                value={streetField}
                onChange={changeStreetField}
                autoComplete="street-address"
                
              />
              <TextField
                margin="normal"
                fullWidth
                id="city"
                label="City"
                name="city"
                value={cityField}
                onChange={changeCityField}
                autoComplete="address-level2"
                
              />
              <TextField
                margin="normal"
                fullWidth
                id="state"
                label="State"
                name="state"
                value={stateField}
                onChange={changeStateField}
                autoComplete="address-level1"
                
              />
               <TextField
                margin="normal"
                fullWidth
                id="country"
                label="Country"
                name="country"
                value={countryField}
                onChange={changeCountryField}
                autoComplete="country-name"
                
              />
                <TextField
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                id="zipCode"
                label="Zip Code"
                min="0"
                value={zipCodeField }
                onChange={changeZipCodeField}
                name="zipCode"
                type="number"
                autoComplete="postal-code"
              />
              <Stack sx={{ mt: 3 }} spacing={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns} >
                
                <DesktopDatePicker
              
                required
                error={false}
                label="Date of Birth *"
                id="dateOfBirth"
                inputFormat="MM/dd/yyyy"
                value={dateValue}
                onChange={dateHandleChange}
                renderInput={(params) => <TextField  {...params} />}
              />
            </LocalizationProvider>
            </Stack>
            </Fragment>       
            <FormControl sx={{ mt: 3 }} fullWidth>
            <InputLabel id="genderLabel">Gender *</InputLabel>
            <Select
              labelId="genderLabel"
              id="gender"
              value={gender}
              label="Gender"
              onChange={handleChangeGender}
            >
              <MenuItem value={0}>Female</MenuItem>
              <MenuItem value={1}>Male</MenuItem>
              <MenuItem value={2}>Other</MenuItem>
              <MenuItem value={3}>Prefer not to say</MenuItem>
            </Select>
            </FormControl>
              <PersonalizedButton  
              type="submit"
                variant="contained"
                 sx={{ mt: 1, mb: 2 }}
                >
                  Save
                </PersonalizedButton>
                {error &&(
                <RedBar
                    paddingTop={"0.1em"}
                    paddingBottom={"0.1em"}
                >
                  <ul> 
                  {  emailError &&(<li>Invalid Email</li>)}
                  {  dateError &&(<li>Date of Birth Required</li>)}
                  {  nameNullError &&(<li>Name Required</li>)}
                  {  genderError &&(<li>Gender Required</li>)}
                  {  lastNameNullError &&(<li>Lastname Required</li>)}
                  {  countryError &&(<li>Numbers are not allowed in the Country field.</li>)}
                  {  zipCodeError &&(<li>Invalid Zip Code</li>)}
                  {  telephoneNumberError &&(<li>Invalid Telephone Number</li>)}
                  {  imageError &&(<li>Only images in these formats .jpg, .png and .jpeg are allowed.</li>)}
                 </ul>
                </RedBar>
                )}
            </Box>
          </Box>
        </Grid>
      </Grid>     
    </Box>

    </ThemeProvider>
	 </Fragment>
  );
}
