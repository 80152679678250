import { Fragment } from 'react';
import  React,{useEffect,useState,useContext ,useRef} from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PersonalizedButton from "../../Layout/Button"
import FormControl from "@mui/material/FormControl";
import {numbersNotAllowed,emptyCheck,dateFormatter,dateIsValid} from "../../../methods/functions"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Stack from "@mui/material/Stack";
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import VaccineContext from '../Vaccine/vaccine-context';
import CircularProgress from '@mui/material/CircularProgress';

export default function InsuranceRecord() {
  const inputRef = useRef(null);
  const [arrayFieldCategory ,setArrayCategory] = React.useState([]);
  const [field,setFieldCategory] = React.useState(0);
  const [showSubCategory,setShowSubCategory]=  React.useState(0);
  const [arrayFieldSubCategory ,setArraySubCategory] = React.useState([]);
  const [arrayFieldSubCategoryObject ,setArraySubCategoryObject] = React.useState([]);
  const [fieldSubCategory,setFieldSubCategory] = React.useState(0);
  const [vaccineDateValue, setVaccineDateValue] = React.useState(new Date());
  const [recordOption ,setRecordOption] = useState(0);
  const vaccineCtx = useContext(VaccineContext);
  const [updated,setUpdatedField]=useState('');
  const [showSubName,setShowSubName]=  React.useState(0);
  const [subNameField, setSubNameField] = useState('');
  const [subNameFieldError, setSubNameFieldError] = useState();
	const [selectedFile, setSelectedFile] = useState();
  const [downloadingMessage,setDownloadingMessage]= useState();
  const [dateError, setDateError] = useState();
  const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
	};

  const createURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_VACCINE_CREATE;
  const updateURL= process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_VACCINE_UPDATE;

  function getData(){
    const getCatalog=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_VACCINE_GET_CATALOG;
    return fetch(getCatalog ,{
     method: 'GET',
     headers: {  
     'Content-Type': 'application/json',
     'Token': localStorage.getItem("token"),
     'userid':localStorage.getItem("userId")     
     }
   })
   .then((response) => response.json())
   .then((data) => {   
    let arrCategory=[];
    let arrSubCategory=[];
    let count=0;
    let subCount=0;
    let datax=undefined;
    let objectSubCategory=[];
    let objectCategory=[];
    let existsSubCategory=0;
    try
    {
     datax= data.body[0].category;

    }
    catch
    {
      datax=[];
    }

    for(let category in datax)
    {
        count=count+1;
        try
        {
          existsSubCategory = datax[category].subCategory.length; 
          }  
          catch
          {
            existsSubCategory=-1;
          }

          if(datax[category].name.toLowerCase()=="other")
          {
            arrCategory.push({
                  index: count,
                  category:"Other",
                  label:"Other",
                  key: parseInt(category)
                })

          }
          else
          {
                    arrCategory.push({
                      index: count,
                      category:datax[category].name,
                      label:datax[category].name,
                      key: parseInt(category)
                    })              
          } 
        
          objectCategory=[];

          if(existsSubCategory>0)
          {
       
            for(let subCategory in datax[category].subCategory)
            { 
                arrSubCategory.push({
                  indexSubCategory: subCount++,
                  category:category,
                  label:datax[category].subCategory[subCategory],
                  key: parseInt(subCategory)
                });

            }
            objectSubCategory[category]=arrSubCategory;
            setArraySubCategoryObject(objectSubCategory);
            if(category==0)
            {
              setArraySubCategory(arrSubCategory);
            }

          }
          arrSubCategory=[];

          subCount=0;
    }
    setArrayCategory(arrCategory);
    setShowSubCategory(1);
   });
  }

  const handleChangeRecordOption = (event) => {
    if(event.target.value==0)
    {
      setRecordOption(0);
      setVaccineDateValue(new Date());
      setSubNameField('');
      setShowSubCategory(0);
      setShowSubName(0);
      setFieldCategory(0);
      setFieldSubCategory(0);
    }
    setRecordOption(event.target.value);
  };
  const resetFileInput = () => {
    // 👇️ reset input value
    if (inputRef.current != null) {
      inputRef.current.value = null;
    }
  };
  const handleSubmitVaccine = (event) => {
    event.preventDefault();
    setSubNameFieldError(false);
    setDateError(false);
    let category=field;
    let subCategory=fieldSubCategory;
    let vaccineDate=vaccineDateValue;
    let finalDecision=true;
    let existsImage=0;
    try
    {
      let exists=selectedFile.name.split(".")[0];
      existsImage=1;
    }
    catch
    {
      existsImage=0;
    }
    
  
    if(showSubCategory==0)
    {
      if(arrayFieldCategory[category].category.toLowerCase().toString()=="other"){
        if(emptyCheck(subNameField) || !numbersNotAllowed(subNameField)){
          setSubNameFieldError(true);
          finalDecision=false;
        }
      }
    }
    if (isNaN(vaccineDate) || vaccineDate == null) {
          try {
            if (dateIsValid(vaccineDate) == false) {
              setDateError(true);
              finalDecision = false;
            }
          } catch {
            let today = new Date(vaccineDate);
            let yyyy = today.getFullYear();
            if (vaccineDate == null) {
              setDateError(true);
              finalDecision = false;
            }
            if (yyyy <= 1899) {
              setDateError(true);
              finalDecision = false;
            }
          }
        } else {
            let today = new Date(vaccineDate);
            let yyyy = today.getFullYear();
            if (yyyy <= 1899) {
              setDateError(true);
              finalDecision = false;
            }
        }

    if(finalDecision)
    { 
          setDateError(false);
         setDownloadingMessage(true);
         setSubNameFieldError(false);
          let vaccineDatee= dateFormatter(vaccineDate);
          const getURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_VACCINE_GET;
          fetch(getURL ,{
            method: 'GET',
            headers: {  
            'Content-Type': 'application/json',
            'Token': localStorage.getItem("token"),
            'userid':localStorage.getItem("userId")     
            }
          })
          .then((response) => response.json())
          .then((data) => {
            let arrayX=undefined;
            try
            {
            arrayX= data.body[0].vaccine;
            }
            catch
            {
              arrayX=[];
            }
            const result = arrayX ? arrayX.length : 0;
            if(showSubName!=1)
            {
              subCategory=subCategory==-1?"":parseInt(subCategory);
            }
            else
            {
              subCategory=subNameField;
            }

            if(result==0)
            {
                  let formdata = new FormData();
                  if(existsImage==1)
                  {
                    formdata.append("file", selectedFile);
                    formdata.append("existsImage", 1);
                  }
                  else{
                    formdata.append("existsImage", 0);
                  }
                  formdata.append("userId", localStorage.getItem("userId"));
                  formdata.append("category", parseInt(category));
                  formdata.append("tradeProvider", subCategory);
                  formdata.append("date", vaccineDatee);
                  let myHeaders = new Headers();
                  myHeaders.append("Token",localStorage.getItem("token"));
                  let requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata
                  };

                  try{
                    fetch(createURL, requestOptions)
                        .then(response => response.json())
                        .then( pleaseWait=> {
                              if(pleaseWait.message==="ok")
                              {
                                alert("Done");
                                setDownloadingMessage(false);
                                setVaccineDateValue(new Date());
                                setFieldCategory(0);
                                setFieldSubCategory(0);
                                setSubNameField('');
                                setShowSubName(0);
                                setShowSubCategory(0);
                                setSelectedFile([]);
                                resetFileInput();
                                vaccineCtx.items=[];
                                vaccineCtx.addItem(vaccineCtx.items);
                              }
                              else
                              {
                                alert("Error");
                              }
                        });

                  }
                  catch{
                      alert("Error");
                  }  
            }
            else
            {          
                   if(recordOption==1){
                     //Update
                    setRecordOption(0);
                    const valuex= vaccineCtx;
                    let vaccineDB=[];
                    let originalArray=[];
                    originalArray=data.body;

                    for(let m in arrayX )
                    {
                      if(m==valuex.items.id)
                      {
                        vaccineDB.push(
                              {
                               date: vaccineDatee,
                               category: parseInt(category),
                               tradeProvider: subCategory,
                               filename: valuex.items.filename
                              }  
                         );
                      }
                      else
                      {
                        vaccineDB.push(
                          {
                           date: arrayX[m].date,
                           category: arrayX[m].category,
                           tradeProvider: arrayX[m].tradeProvider,
                           filename:arrayX[m].filename
                          }  
                      );
                      }
       
                     
                    }
                    let formdata = new FormData();

                    formdata.append("newRecord",0);
                    formdata.append("userId", localStorage.getItem("userId"));
                    formdata.append("documentId", originalArray[0].id);

                    formdata.append("vaccineArray", JSON.stringify(vaccineDB));
                    let myHeaders = new Headers();
                    myHeaders.append("Token",localStorage.getItem("token"));
                    let requestOptions = {
                      method: 'PUT',
                      headers: myHeaders,
                      body: formdata
                    };
                    try{
                      fetch(updateURL, requestOptions)
                          .then(response => response.json())
                          .then( pleaseWait=> {
                                  if(pleaseWait.message==="ok")
                                  {
                                      alert("Done");
                                      setDownloadingMessage(false);
                                      setVaccineDateValue(new Date());
                                      setFieldCategory(0);
                                      setFieldSubCategory(0);
                                      setSubNameField('');
                                      setShowSubName(0);
                                      setShowSubCategory(0);
                                      setSelectedFile([]);
                                      resetFileInput();
                                      vaccineCtx.items=[];
                                      vaccineCtx.addItem(vaccineCtx.items);
                                  }
                                  else
                                  {
                                    alert("Error");
                                  }
                            }
                          );
                    }
                    catch{
                        alert("Error");
                    }  
                   
                   vaccineCtx.addItem(vaccineCtx.items);
                   }
                   else{
                    let formdata = new FormData();
                    let extension;
                    if(existsImage==1)
                    {
                      let indexExtension=selectedFile.type.indexOf("/") +1;
                      let length=selectedFile.type.length;
                      extension= selectedFile.type.substring(indexExtension,length);
                      formdata.append("file", selectedFile);
                      formdata.append("filename", selectedFile.name.split(".")[0] + "_"+ Date.now() +"."+ extension );
                      formdata.append("existsImage", 1);
                    }
                    else
                    {
                      formdata.append("existsImage", 0);
                    }

                    const valuex= vaccineCtx;
                    let originalArray=[];
 
                    originalArray=data.body; 
                    originalArray[0].vaccine.push(
                      {
                        date: vaccineDatee,
                        category: parseInt(category),
                        tradeProvider: subCategory,
                        filename: existsImage==1?selectedFile.name.split(".")[0] + "_"+ Date.now() +"."+ extension :""
                      }
                    );
                 
                    formdata.append("vaccineArray",JSON.stringify(originalArray[0].vaccine));
                    formdata.append("newRecord",1);
                
                    formdata.append("userId", localStorage.getItem("userId"));
                    formdata.append("documentId", originalArray[0].id);
                    let myHeaders = new Headers();
                    myHeaders.append("Token",localStorage.getItem("token"));
                    let requestOptions = {
                      method: 'PUT',
                      headers: myHeaders,
                      body: formdata
                    };

                    try{
                      fetch(updateURL, requestOptions)
                          .then(response => response.json())
                          .then( pleaseWait=> {
                              if(pleaseWait.message==="ok")
                              {
                                  alert("Done");
                                  setDownloadingMessage(false);
                                  setVaccineDateValue(new Date());
                                  setFieldCategory(0);
                                  setFieldSubCategory(0);
                                  setSubNameField('');
                                  setShowSubName(0);
                                  setSelectedFile([]);
                                  resetFileInput();
                                  setShowSubCategory(0);
                                  vaccineCtx.items=[];
                                  vaccineCtx.addItem(vaccineCtx.items);
                              }
                              else
                              {
                                alert("Error");
                              }
                          });
                    }
                    catch{
                        alert("Error");
                    }  
                   
                   vaccineCtx.addItem(vaccineCtx.items);
                   }

            }
          }
          );
    }

    

  };
  
  
    useEffect(() => {
      setShowSubName(0);
      if(updated==1)
      {
        setVaccineDateValue(new Date());
        setSubNameField('');
        setShowSubName(0);
        setShowSubCategory(0);
        setFieldCategory(0);
        setFieldSubCategory(0);
        setRecordOption(0);
        setUpdatedField(0);
      }
      if(vaccineCtx.items.option==2)
      {
        let date= vaccineCtx.items.date?vaccineCtx.items.date:new Date();
        setVaccineDateValue(date);
        setFieldCategory(vaccineCtx.items.categoryId);
        let subCount=0;
        let verification=-1;
        let arrXSubCategory=[];
        for(let row in arrayFieldSubCategoryObject[vaccineCtx.items.categoryId])
        {
            verification=0;
            arrXSubCategory.push({
              indexSubCategory: subCount++,
              category:parseInt(vaccineCtx.items.categoryId),
              label:arrayFieldSubCategoryObject[vaccineCtx.items.categoryId][row].label,
              key: parseInt(arrayFieldSubCategoryObject[vaccineCtx.items.categoryId][row].key)
            });
        }
        if(verification!=-1)
        {
          setArraySubCategory(arrXSubCategory);
          setFieldSubCategory(vaccineCtx.items.subCategoryId);
          setShowSubCategory(1);
          setShowSubName(0);
        }
        else
        {
          if(vaccineCtx.items.subCategoryId==-2){
             setShowSubName(1);
             setShowSubCategory(0);
             setSubNameField(vaccineCtx.items.subCategoryName);
          }
          else
          {
            setShowSubCategory(0);
          }
        }

        setRecordOption(1);
      }
      else{
        setShowSubName(0);
        setShowSubCategory(0);
        getData();
      }
      // eslint-disable-next-line
    }, [vaccineCtx]);


  const vaccineDateHandleChange = (newValue) => {
      setVaccineDateValue(newValue);
  };

  const handleChangeOptionCategory = (event) => {
    const newArray= arrayFieldCategory;
    let arrSubCategoryX=[];

    let subCount=0;
    let categoryId=event.target.value;

    for(let row in arrayFieldSubCategoryObject[categoryId])
    {
        arrSubCategoryX.push({
          indexSubCategory: subCount++,
          category:newArray[(event.target.value)].key,
          label:arrayFieldSubCategoryObject[categoryId][row].label,
          key:arrayFieldSubCategoryObject[categoryId][row].key
        });

    }

    if(arrSubCategoryX.length>0 )
    {
      setArraySubCategory(arrSubCategoryX);
      setShowSubCategory(1);
      setShowSubName(0);
    }
    else
    {
      setShowSubCategory(0);
      setArraySubCategory([]);
      setFieldSubCategory(-1);
      if(newArray[(event.target.value)].label.toLowerCase()=="other")
      {
          setShowSubName(1);
      }
      else
      {
        setShowSubName(0);
      }
    }

    setFieldCategory(event.target.value);
  };

  const handleChangeOptionSubCategory = (event) => {

    setFieldSubCategory(event.target.value);
  };
  const changeSubNameField = (event) => {
    setSubNameField(event.target.value);
};
  return (
        <Box
        component="form"
         noValidate
        onSubmit={handleSubmitVaccine}
            sx={{
              my: 4,
              mx: 12,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >  
          { 
            downloadingMessage?
              <div>
                <center>
                  <Typography component="h2" variant="h5">
                  Please wait
                  </Typography> 
                  <div><CircularProgress /></div>
                </center>
              </div>:<React.Fragment></React.Fragment>
          }
             <FormControl sx={{ m: 4}}>
                <Typography component="h1" variant="h5">
                    Record
                  </Typography>
                </FormControl>  
                <FormControl sx={{ m: 2}}>
                      <RadioGroup
                        sx={{ display: 'inline' }}
                        aria-labelledby="record-radio-buttons-group-label"
                        name="record-radio-buttons-group"
                        value={recordOption}
                        onChange={handleChangeRecordOption}
                      >
                        <FormControlLabel value="0" control={<Radio />} label="Create" />
                        <FormControlLabel value="1" disabled control={<Radio />} label="Update" />
                      </RadioGroup>
              </FormControl>       
              <FormControl  sx={{ mt: 0, mx: 0 }} >
              </FormControl>
              <React.Fragment>
              <FormControl  sx={{ mt: 1}} >
              <InputLabel sx={{ mt: 1 }} id="optionLabel">Category</InputLabel>
              <Select
                        labelId="optionLabel"
                        id="option"
                        label="Category"
                        value={field}
                        onChange={handleChangeOptionCategory}
                        >
                            {
                               arrayFieldCategory.map((option) => (
                                  <MenuItem  key={option.key} value={option.key}>{option.label}</MenuItem>
                                ))
                            }
              </Select>
             </FormControl>
             </React.Fragment>
             {
                  showSubCategory==1?
              <FormControl  sx={{ mt: 2}} >
              <InputLabel sx={{ mt: 1 }} id="optionSubCategoryLabel">SubCategory</InputLabel>
              <Select
                        labelId="optionSubCategoryLabel"
                        id="optionSubCategory"
                        label="Trade Provider"
                        value={fieldSubCategory}
                        onChange={handleChangeOptionSubCategory}
                        >
                            {
                               arrayFieldSubCategory.map((optionSubCategory) => (
                                  <MenuItem  key={optionSubCategory.key} value={optionSubCategory.key}>{optionSubCategory.label}</MenuItem>
                                ))
                            }
              </Select>
             </FormControl>
                :   
                <React.Fragment></React.Fragment>
            }
             {
              showSubName==1?
             <FormControl  sx={{ mt: 3, mx: 0 }} >
             <TextField
                        InputLabelProps={{ shrink: true }}
                        error= {subNameFieldError? true:false}
                        sx={{ m: 0}}
                        margin="normal"
                        id="subNameField"
                        label="Category"
                        name="subNameField"      
                        value={subNameField}
                        onChange={changeSubNameField}      
                />    
              </FormControl>                        :   
              <React.Fragment></React.Fragment>
              }

              {
                recordOption==0?
                <FormControl  sx={{ mt: 3, mx: 0 }} >
                <input ref={inputRef} type="file" name="file" onChange={changeHandler} />
                </FormControl>
                :
                <React.Fragment></React.Fragment>
              }

              <FormControl  sx={{ m: 3}}>
              <Stack  spacing={4} sx={{ m: 1 , mx: 0}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} >
                              <DesktopDatePicker
                              label="Vaccine Date"
                              id="vaccineDate"
                              value={vaccineDateValue}
                              onChange={vaccineDateHandleChange}
                              inputFormat="MM/dd/yyyy"
                              renderInput={(params) => (
                                <TextField {...params} error={dateError ? true : false} />
                              )}
                            />
                            </LocalizationProvider>
                          </Stack>   
              </FormControl>
              <PersonalizedButton  
              type="submit"
                variant="contained"
                 sx={{ mt: 2, mb: 2 }}
              >
                  Save
              </PersonalizedButton>    
        </Box>
  );
}
