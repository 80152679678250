import  React,{useEffect,useState,useContext} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography
} from "@material-ui/core";
import TablePagination from '@mui/material/TablePagination';
import DeleteIcon from "@material-ui/icons/Delete";
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 250,
  },
  tableContainer: {
    borderRadius: 15,
    margin: "10px 10px",
    maxWidth: "400px",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    backgroundColor: "#4788B8",
    color: theme.palette.getContrastText(theme.palette.primary.dark)
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
  name: {
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
  },
  status: {
    fontWeight: "bold",
    fontSize: "0.75rem",
    color: "white",
    backgroundColor: "grey",
    borderRadius: 8,
    padding: "3px 10px",
    display: "inline-block",
  },
  hover: {
    cursor: "pointer",
  },
  center:{
    textAlign:'center'
  }
}));

const tableHeader = [
  { label: "Document", data: "id" },
  { label: "Category", data: "category" },
  { label: "SubCategory", data: "subCategory" }
];

function createData(
  document,
  id,
  categoryId,
  subCategoryId,
  categoryName,
  subCategoryName,
  option
  ) {
  return {
    document,
    id,
    categoryId,
    subCategoryId,
    categoryName,
    subCategoryName,
    option
  };
}

const MTable = (props) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [medicalHistoryData, setData] = useState([]);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getCatalog=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICAL_HISTORY_GET_DISEASE_CATALOG;
  const getURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICAL_HISTORY_GET;
  useEffect(() => {
      let array=[];
      fetch(getCatalog ,{
       method: 'GET',
       headers: {  
       'Content-Type': 'application/json',
       'Token': localStorage.getItem("token"),
       'userid':props.userId    
       }
     })
     .then((response) => response.json())
     .then((data) => {   
      let arrSubCategory=[];
      let count=0;
      let subCount=0;
      let datax=undefined;
      let objectSubCategory=[];
      let objectCategory=[];
      let existsSubCategory=0;
      try
      {
       datax= data.body.category;
  
      }
      catch
      {
        datax=[];
      }
  
      for(let category in datax)
      {
          count=count+1;
          try
          {
            existsSubCategory = datax[category].subCategory.length; 
            }  
            catch
            {
              existsSubCategory=-1;
            }
  
            if(datax[category].name.toLowerCase()=="other")
            {
                  objectCategory[category]= {
                    index: count,
                    category:datax[category].name,
                    label:datax[category].name,
                    key: category 
                  };    
            }
            else
            {
      
                  objectCategory[category]= {
                    index: count,
                    category:datax[category].name,
                    label:datax[category].name,
                    key: category 
                  };       
            } 
  
            if(existsSubCategory>0)
            {
         
              for(let subCategory in datax[category].subCategory)
              { 
                  arrSubCategory.push({
                    indexSubCategory: subCount++,
                    category:category,
                    label:datax[category].subCategory[subCategory],
                    key: subCategory
                  });
  
              }

              objectSubCategory[category]=arrSubCategory;
  
            }
            arrSubCategory=[];
  
            subCount=0;
      }

      fetch(getURL ,{
        method: 'GET',
        headers: {  
        'Content-Type': 'application/json',
        'Token': localStorage.getItem("token"),
        'userid':props.userId  
        }
      })
      .then((response) => response.json())
      .then((datax) => { 
           let arrayX=undefined;
           try
           {
           arrayX= datax.body[0].diseases;
           }
           catch
           {
             arrayX=[];
           }

           for(let row in arrayX )
           {
             let subCategory="";
             let subCategoryId=0;
             if(!(isNaN(parseInt(arrayX[row].subCategory))))
             { 
               subCategoryId=parseInt(arrayX[row].subCategory);
               subCategory=objectSubCategory[arrayX[row].category][subCategoryId].label.toString();
             }
             else
             {
               if(arrayX[row].subCategory=="")
               {
                subCategoryId=-1;
                subCategory="";
               }
               else
               {
                 subCategory=arrayX[row].subCategory;
                 subCategoryId=-2;
               }
             }
             /*
                 function createData(
                    document,
                    id,
                    categoryId,
                    subCategoryId,
                    categoryName,
                    subCategoryName,
                    option
                    ) {
                    return {
                      document,
                      id,
                      categoryId,
                      subCategoryId,
                      categoryName,
                      subCategoryName,
                      option
                    };
                  }
             */
              array.push(
                createData(
                  datax.body[0].id,
                  parseInt(row),
                  parseInt(arrayX[row].category),
                  subCategoryId,
                  objectCategory[arrayX[row].category].category,
                  subCategory
                  )
              );
          
           }
            setData(array);     
        })
     });

      // eslint-disable-next-line
    }, []);

  const styleObj = {
    fontSize: 30,
    color: "black",
}
  return (
    <React.Fragment>
       <center><p>Medical History</p></center>
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableHeader.map((cell) => (
              <TableCell key={cell.data} className={classes.tableHeaderCell}>
                {cell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
        {(rowsPerPage > 0
            ? medicalHistoryData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : medicalHistoryData
          ).map((row,i) => (
            <TableRow key={row.id}>
              <TableCell>{row.document}</TableCell>
              <TableCell>{row.categoryName}</TableCell>
              <TableCell>{row.subCategoryName}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={medicalHistoryData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
    </TableContainer>

    </React.Fragment>
  );
};

export default MTable;