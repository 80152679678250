import React  from 'react';
import { Fragment } from 'react';
import ShareHealthRecordEdit from './ShareHealthRecordEdit';
import ShareHealthRecordReport from './ShareHealthRecordReport';
import ShareHealthRecordProvider from './shareHealthRecord-Provider';
import Divider from "@mui/material/Divider";
import { styled } from '@mui/material/styles';
import ShareHealthRecordInvite from './ShareHealthRecordInvite';
const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& > :not(style) + :not(style)': {
    marginTop: theme.spacing(2),
  },
}));


const ShareHealthRecordCategory = () => {
  return (
      <ShareHealthRecordProvider>
            <Root>
            <Divider><h2>Share my health record</h2></Divider>
            </Root> 
            <ShareHealthRecordInvite/>
            <center>
            <Root>
            <Divider><h2>Manage Health Record Access</h2></Divider>
            </Root>
            <ShareHealthRecordReport />
            <ShareHealthRecordEdit />
            </center>
      </ShareHealthRecordProvider>

  );
};

export default ShareHealthRecordCategory;
