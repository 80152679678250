import { useReducer } from 'react';
import React  from 'react';
import VaccineContext from './vaccine-context';
import Divider from "@mui/material/Divider";
import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& > :not(style) + :not(style)': {
      marginTop: theme.spacing(2),
    },
  }));

const defaultVaccineState = {
  items: [],
  count: 0,
  report:0
};

const cartReducer = (state, action) => {
  
  if (action.type === 'ADD') {

    if(typeof action.item !== 'undefined' && action.item.length === 0)
    {
      return {
        count:state.count+1,
        items: action.item,
        report:state.report+1
      };
    }
    else
    {
      return {
        count:state.count+1,
        items: action.item,
        report:0
      };
    }

  }

  return defaultVaccineState;
};

const VaccineProvider = (props) => {
  const [vaccineState, dispatchCartAction] = useReducer(
    cartReducer,
    defaultVaccineState
  );

  const addItemToRecordHandler = (item) => {
    dispatchCartAction({ type: 'ADD', item: item });
  };

  const cartContext = {
    items: vaccineState.items,
    count: vaccineState.count,
    report: vaccineState.report,
    addItem: addItemToRecordHandler
  };

  return (
    <VaccineContext.Provider value={cartContext}>
      <Root>
      <Divider><h2>Vaccine</h2></Divider>
      </Root>
      <React.Fragment >
      {props.children}
      </React.Fragment>
    </VaccineContext.Provider>
  );
};

export default VaccineProvider;
