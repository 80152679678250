import { Fragment } from 'react';
import  React,{useEffect,useState,useContext } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PersonalizedButton from "../../Layout/Button"
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {numbersNotAllowed,emptyCheck,dateFormatter,dateIsValid} from "../../../methods/functions"
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import SocialHistoryContext from '../SocialHistory/socialHistory-context';

export default function SocialHistoryRecord() {
  const socialHistoryCtx = useContext(SocialHistoryContext);
  const [tobaccoOptionField,setTobaccoOptionField] = useState(0);

  const [packsPerDayField, setPacksPerDayField] = useState(0);
  const [tobaccoYearsOfUseField, setTobaccoYearsOfUseField] = useState(0);
  const [packsPerDayFieldError, setPacksPerDayFieldError] = useState()
  const [tobaccoYearsOfUseFieldError, setTobaccoYearsOfUseFieldError] = useState();
  const [tobaccoQuitDateField, setTobacoQuitDateField] = React.useState(new Date());

  const [smokelessTobaccoOptionField,setSmokelessTobaccoOptionField] = useState(0);

  const [smokelessYearsOfUseField, setSmokelessYearsOfUseField] = useState(0);
  const [smokelessYearsOfUseFieldError, setSmokelessYearsOfUseFieldError] = useState();
  const [smokelessDateField,  setSmokelessDateField] = React.useState(new Date());

  const [alcoholUseOptionField,setAlcoholUseOptionField] = useState(0);
  const [drinkPerWeekField, setDrinkPerWeekField] = useState(0);
  const [drinkPerWeekFieldError, setDrinkPerWeekFieldError] = useState();

  const [drugUseOptionField,setDrugUseOptionField] = useState(0);

  const [drugUsePerWeekField, setDrugUsePerWeekField] = useState(0);
  const [drugUsePerWeekFieldError, setDrugUsePerWeekFieldError] = useState();

  const [drugTypeField, setDrugTypeField] = useState('');
  const [drugTypeFieldError, setDrugTypeFieldError] = useState('');

  const [maritalStatusField, setMaritalStatusField] = useState(0);
  const [maritalStatusFieldError, setMaritalStatusFieldError] = useState('');

  const [currentPartnerOptionField,setCurrentPartnerOptionField] = useState(0);
  const [sexOfCurrentPartner, setSexOfCurrentPartner] = React.useState(3);

  const [recordOption ,setRecordOption] = useState(0);

  const [tobaccoQuitDateError, setTobaccoQuitDateError] = useState();
  const [smokelessDateError, setSmokelessDateFieldError] = useState();

  const createSocialHistoryURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_SOCIAL_HISTORY_CREATE;

  const [arrayFieldCategory ,setArrayCategory] = React.useState([]);

  const handleChangeTobaccoUse= (event) => {
    setTobaccoOptionField(event.target.value);
  };

  const handleChangePacksPerDayField= (event) => {
    setPacksPerDayField(event.target.value);
  };

  const handleChangeTobaccoYearsOfUseField = (event) => {
    setTobaccoYearsOfUseField(event.target.value);
  };

  const handleChangeTobacoQuitDateField = (newValue) => {
    setTobacoQuitDateField(newValue);
  };

  const handleChangeSmokelessTobacco= (event) => {
    setSmokelessTobaccoOptionField(event.target.value);
  };

  const handleChangeSmokelessYearsOfUseField= (event) => {
    setSmokelessYearsOfUseField(event.target.value);
  };
  const handleChangeSmokelessDateField = (newValue) => {
    setSmokelessDateField(newValue);
  };

  const handleChangeAlcoholUse= (event) => {
    setAlcoholUseOptionField(event.target.value);
  };
  
  const handleChangeDrinkPerWeekField = (event) => {
    setDrinkPerWeekField(event.target.value);
  };

  const handleChangeDrugUse= (event) => {
    setDrugUseOptionField(event.target.value);
  };

  const handleChangeDrugUsePerWeekChild= (event) => {
    setDrugUsePerWeekField(event.target.value);
  };

  const handleChangeDrugTypeField= (event) => {
    setDrugTypeField(event.target.value);
  };
 
  const handleChangeMaritalStatusField= (event) => {
    setMaritalStatusField(event.target.value);
  };

  const handleChangeCurrentPartner = (event)=>
  {
    setCurrentPartnerOptionField(event.target.value);
  }

  const handleChangeSexOfCurrentPartner = (event) => {
    setSexOfCurrentPartner(event.target.value);
  };

  const handleChangeRecordOption = (event) => {
    if(event.target.value==0)
    {
      setRecordOption(0);
      setTobaccoOptionField(0);
      setPacksPerDayField(0);
      setTobaccoYearsOfUseField(0);
      setTobacoQuitDateField(new Date());

      setSmokelessTobaccoOptionField(0);
      setSmokelessYearsOfUseField("");
      setSmokelessDateField(new Date());

      setAlcoholUseOptionField(0);
      setDrinkPerWeekField(0);

      setDrugUseOptionField(0);
      setDrugUsePerWeekField(0);  
      setDrugTypeField('');

      setMaritalStatusField(0);
      setCurrentPartnerOptionField(0);
      setSexOfCurrentPartner(3);

    }
    setRecordOption(event.target.value);
  };
  function getData(){
    const getSocialHistoryURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_SOCIAL_HISTORY_GET;
    return fetch(getSocialHistoryURL ,{
     method: 'GET',
     headers: {  
     'Content-Type': 'application/json',
     'Token': localStorage.getItem("token"),
     'userid':localStorage.getItem("userId")     
     }
   })
   .then((response) => response.json())
   .then((data) => {   
     try{
       return data.body[0].id?1:0;
     }
     catch{
       return 0;
     }
    
   });
  }
  function getMaritalStatus()
  {
    const getMaritalStatusURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MARITAL_STATUS_GET;
    return fetch(getMaritalStatusURL ,{
     method: 'GET',
     headers: {  
     'Content-Type': 'application/json',
     'Token': localStorage.getItem("token"),
     'userid':localStorage.getItem("userId")     
     }
   })
   .then((response) => response.json())
   .then((data) => {   
       return data.body;
    
   });
  }
  const handleSubmitInsurance = (event) => {
    event.preventDefault();
 
    setPacksPerDayFieldError(false);
    setTobaccoYearsOfUseFieldError(false);
    setSmokelessYearsOfUseFieldError(false);
    setDrinkPerWeekFieldError(false);
    setDrugUsePerWeekFieldError(false);
    setDrugTypeFieldError(false);
    setMaritalStatusFieldError(false);
    setTobaccoQuitDateError(false);
    setSmokelessDateFieldError(false);
    let packsPerDay = packsPerDayField;
    let tobaccoYearsOfUse= tobaccoYearsOfUseField;
    let tobaccoQuitDate= tobaccoQuitDateField;
    let smokelessYearsOfUse=smokelessYearsOfUseField;
    let smokelessTobaccoQuitDate= smokelessDateField;
    let drinkPerWeek=drinkPerWeekField;
    let drugUsePerWeek= drugUsePerWeekField;
    let drugType=drugTypeField;
    let maritalStatus=maritalStatusField;
    let finalDecision=true;
    if(tobaccoOptionField==1)
    {
      packsPerDay= packsPerDay? packsPerDay:'';
      if(packsPerDay>0)
      {
        if(numbersNotAllowed(packsPerDay)){
          setPacksPerDayFieldError(true);
          finalDecision=false;
        }
        else
        {
          if(packsPerDay<0 || packsPerDay ==-0)
          {
            setPacksPerDayFieldError(true);
            finalDecision=false;
          }
        }
      }
      else
      {
        setPacksPerDayFieldError(true);
        finalDecision=false;
      }
      tobaccoYearsOfUse= tobaccoYearsOfUse? tobaccoYearsOfUse:'';
      if(tobaccoYearsOfUse>0)
      {
        if(numbersNotAllowed(tobaccoYearsOfUse)){
          setTobaccoYearsOfUseFieldError(true);
          finalDecision=false;
        }
        else
        {
          if(tobaccoYearsOfUse<0 || tobaccoYearsOfUse ==-0)
          {
            setTobaccoYearsOfUseFieldError(true);
            finalDecision=false;
          }
           
        }
      }
      else
      {
        setTobaccoYearsOfUseFieldError(true);
        finalDecision=false;
      }
    }
    smokelessYearsOfUse=smokelessYearsOfUse?smokelessYearsOfUse:'';
    if(smokelessTobaccoOptionField==1)
    {
        if(smokelessYearsOfUse>0)
        {
          if(numbersNotAllowed(smokelessYearsOfUse)){
            setSmokelessYearsOfUseFieldError(true);
            finalDecision=false;
          }
          else
          {
            if(smokelessYearsOfUse<0 || smokelessYearsOfUse ==-0)
            {
              setSmokelessYearsOfUseFieldError(true);
              finalDecision=false;
            }
          }
        }
        else
        {
          setSmokelessYearsOfUseFieldError(true);
          finalDecision=false;
        }
    }
    drinkPerWeek=drinkPerWeek?drinkPerWeek:'';
    if(alcoholUseOptionField==1){
        if(drinkPerWeek>0)
        {
          if(numbersNotAllowed(drinkPerWeek)){
            setDrinkPerWeekFieldError(true);
            finalDecision=false;
          }
          else
          {
            if(drinkPerWeek<0 || drinkPerWeek ==-0)
            {
              setDrinkPerWeekFieldError(true);
              finalDecision=false;
            }
          }
        }
        else
        {
          setDrinkPerWeekFieldError(true);
          finalDecision=false;
        }
    }

    drugUsePerWeek=drugUsePerWeek?drugUsePerWeek:'';

    if(drugUseOptionField==1){
      if(drugUsePerWeek>0)
      {
        if(numbersNotAllowed(drugUsePerWeek)){
          setDrugUsePerWeekFieldError(true);
          finalDecision=false;
        }
        else
        {
            if(drugUsePerWeek<0 || drugUsePerWeek ==-0)
            {
              setDrugUsePerWeekFieldError(true);
              finalDecision=false;
            }
        }
      }
      else
      {
        setDrugUsePerWeekFieldError(true);
        finalDecision=false;
      }

    drugType=drugType?drugType:'';
      if(emptyCheck(drugType)){
        setDrugTypeFieldError(true);
        finalDecision=false;
      }
    }

    if (isNaN(tobaccoQuitDate) || tobaccoQuitDate == null) {
      try {
        if (dateIsValid(tobaccoQuitDate) == false) {
          setTobaccoQuitDateError(true);
          finalDecision = false;
        }
      } catch {
        let today = new Date(tobaccoQuitDate);
        let yyyy = today.getFullYear();
        if (tobaccoQuitDate == null) {
          setTobaccoQuitDateError(true);
          finalDecision = false;
        }
        if (yyyy <= 1899) {
          setTobaccoQuitDateError(true);;
          finalDecision = false;
        }
      }
    } else {
      let today = new Date(tobaccoQuitDate);
      let yyyy = today.getFullYear();
      if (yyyy <= 1899) {
        setTobaccoQuitDateError(true);
        finalDecision = false;
      }
    }

    if (isNaN(smokelessTobaccoQuitDate) || smokelessTobaccoQuitDate == null) {
      try {
        if (dateIsValid(smokelessTobaccoQuitDate) == false) {
          setSmokelessDateFieldError(true);
          finalDecision = false;
        }
      } catch {
        let today = new Date(smokelessTobaccoQuitDate);
        let yyyy = today.getFullYear();
        if (smokelessTobaccoQuitDate == null) {
          setSmokelessDateFieldError(true);
          finalDecision = false;
        }
        if (yyyy <= 1899) {
          setSmokelessDateFieldError(true);;
          finalDecision = false;
        }
      }
    } else {
      let today = new Date(smokelessTobaccoQuitDate);
      let yyyy = today.getFullYear();
      if (yyyy <= 1899) {
        setSmokelessDateFieldError(true);
        finalDecision = false;
      }
    }

    getData().then(response => {
      if((response==1 && recordOption==1) || (response==0 && recordOption==0))
      {
        if(finalDecision)
        {
          setTobaccoQuitDateError(false);
          setSmokelessDateFieldError(false);
          let tobaccoQuitDatee= dateFormatter(tobaccoQuitDate);
          let smokelessTobaccoQuitDatee= dateFormatter(smokelessTobaccoQuitDate);
          setPacksPerDayFieldError(false);
          setTobaccoYearsOfUseFieldError(false);
          setSmokelessYearsOfUseFieldError(false);
          setDrinkPerWeekFieldError(false);
          setDrugUsePerWeekFieldError(false);
          setDrugTypeFieldError(false);
          setMaritalStatusFieldError(false);
          if(recordOption==0){
              const createData = 
              {
                userId:localStorage.getItem("userId"),
                tobacco :
                tobaccoOptionField==1? 
                {
                "packsPerDay": parseInt(packsPerDay),
                "quitDate":tobaccoQuitDatee,
                "yearsOfUse": parseInt(tobaccoYearsOfUse)
                }:  0,
                smokelessTobacco :
                smokelessTobaccoOptionField==1? 
                {
                "quitDate": smokelessTobaccoQuitDatee,
                "yearsOfUse": parseInt(smokelessYearsOfUse)
                }:  0,
                alcohol :
                alcoholUseOptionField==1? 
                {
                "drinksPerWeek": parseInt(drinkPerWeek)
                }:  0,
                currentPartner:
                currentPartnerOptionField==1? 
                {
                "sexOfCurrentPartner": parseInt(sexOfCurrentPartner)
                }:  0,
                drug:
                drugUseOptionField==1? 
                {
                "drugType": drugType,
                "usePerWeek": parseInt(drugUsePerWeek)
                }:  0,
                marital: parseInt(maritalStatus)
  
              }
  
              const requestOptions = {
                method: 'POST',
                headers: { 
                  
                  'Content-Type': 'application/json',
                  "Token": localStorage.getItem("token")  
              
                },
                    body: JSON.stringify(createData)
                };
                try{
                  fetch(createSocialHistoryURL, requestOptions)
                      .then(response => response.json());
                      alert("Done");
                      setRecordOption(0);
                      setTobaccoOptionField(0);
                      setPacksPerDayField(0);
                      setTobaccoYearsOfUseField(0);
                      setTobacoQuitDateField(new Date());
                      setSmokelessTobaccoOptionField(0);
                      setSmokelessYearsOfUseField("");
                      setSmokelessDateField(new Date());      
                      setAlcoholUseOptionField(0);
                      setDrinkPerWeekField(0);
                      setDrugUseOptionField(0);
                      setDrugUsePerWeekField(0);  
                      setDrugTypeField('');
                      setMaritalStatusField('');
                      setCurrentPartnerOptionField(0);
                      setSexOfCurrentPartner(3);
                      socialHistoryCtx.items=[];
                      socialHistoryCtx.addItem(socialHistoryCtx.items);
                }
                catch{
                    alert("Error");
                }       
          }
          else
          {
            setRecordOption(0);
            let tobaccoQuitDatee= dateFormatter(tobaccoQuitDate);
            let smokelessTobaccoQuitDatee= dateFormatter(smokelessTobaccoQuitDate);
            const updateData = 
            {
                documentId:socialHistoryCtx.items.documentNumber,
                userId:localStorage.getItem("userId"),
                tobacco :
                tobaccoOptionField==1? 
                {
                "packsPerDay": parseInt(packsPerDay),
                "quitDate":tobaccoQuitDatee,
                "yearsOfUse": parseInt(tobaccoYearsOfUse)
                }:  0,
                smokelessTobacco :
                smokelessTobaccoOptionField==1? 
                {
                "quitDate": smokelessTobaccoQuitDatee,
                "yearsOfUse": parseInt(smokelessYearsOfUse)
                }:  0,
                alcohol :
                alcoholUseOptionField==1? 
                {
                "drinksPerWeek": parseInt(drinkPerWeek)
                }:  0,
                currentPartner:
                currentPartnerOptionField==1? 
                {
                "sexOfCurrentPartner": parseInt(sexOfCurrentPartner)
                }:  0,
                drug:
                drugUseOptionField==1? 
                {
                "drugType": drugType,
                "usePerWeek": parseInt(drugUsePerWeek)
                }:  0,
                marital: parseInt(maritalStatus)
  
            }
  
            const socialHistoryUpdate=process.env.REACT_APP_GET_SERVER_URL+ process.env.REACT_APP_SOCIAL_HISTORY_UPDATE;
            const requestOptions = {
              method: 'PUT',
              headers: { 
                
                'Content-Type': 'application/json',
                "Token": localStorage.getItem("token")
              },
                body: JSON.stringify(updateData)
          };
          try{
          fetch(socialHistoryUpdate, requestOptions)
          .then(response => response.json());
          }
          catch
          {
            alert("Error");
          }
          setRecordOption(0);
          setTobaccoOptionField(0);
          setPacksPerDayField(0);
          setTobaccoYearsOfUseField(0);
          setTobacoQuitDateField(new Date());
          setSmokelessTobaccoOptionField(0);
          setSmokelessYearsOfUseField("");
          setSmokelessDateField(new Date());      
          setAlcoholUseOptionField(0);
          setDrinkPerWeekField(0);
          setDrugUseOptionField(0);
          setDrugUsePerWeekField(0);  
          setDrugTypeField('');
          setMaritalStatusField('');
          setCurrentPartnerOptionField(0);
          setSexOfCurrentPartner(3);
            alert("Done");
            socialHistoryCtx.items=[];
            socialHistoryCtx.addItem(socialHistoryCtx.items);
          }
  
        }
      }
      else
      {
        alert("You can't add more records, because you already have one Social History record. If you want to apply further changes, please update it.");

      }
    }
    
    );

    

  };
  
  useEffect(() => {
    getMaritalStatus().then(response => {
      let arrCategory=[];
      let count=0;
      for(let row in response.category)
      {
        arrCategory.push({
          index: count++,
          category:response.category[row],
          label:response.category[row],
          key: row
        })
      }
      setArrayCategory(arrCategory);
      setMaritalStatusField(socialHistoryCtx.items.marital?socialHistoryCtx.items.marital:0);
    }
    );
    if(socialHistoryCtx.items.option==2)
    {
      setRecordOption(1);
    }

     setTobaccoOptionField(socialHistoryCtx.items.tobacco?socialHistoryCtx.items.tobacco:0);
     setPacksPerDayField(socialHistoryCtx.items.tobaccoPacksPerDay);
     setTobaccoYearsOfUseField(socialHistoryCtx.items.tobaccoYearsOfUse);
     setTobacoQuitDateField(new Date(socialHistoryCtx.items.tobaccoQuitDate?socialHistoryCtx.items.tobaccoQuitDate:new Date()));

     setSmokelessTobaccoOptionField(socialHistoryCtx.items.smokeless?socialHistoryCtx.items.smokeless:0);
     setSmokelessYearsOfUseField(socialHistoryCtx.items.smokelessYearsOfUse);
     setSmokelessDateField(new Date(socialHistoryCtx.items.smokelessTobaccoQuitDate?socialHistoryCtx.items.smokelessTobaccoQuitDate:new Date())); 

     setAlcoholUseOptionField(socialHistoryCtx.items.alcohol?socialHistoryCtx.items.alcohol:0);

     setDrinkPerWeekField(socialHistoryCtx.items.drinkPerWeek);
     
     setDrugUseOptionField(socialHistoryCtx.items.drug?socialHistoryCtx.items.drug:0);

     setDrugUsePerWeekField(socialHistoryCtx.items.usePerWeek); 

     setDrugTypeField(socialHistoryCtx.items.drugType);

     setCurrentPartnerOptionField(socialHistoryCtx.items.currentPartner?socialHistoryCtx.items.currentPartner:0);
     setSexOfCurrentPartner(socialHistoryCtx.items.sexOfCurrentPartner?socialHistoryCtx.items.sexOfCurrentPartner:0);
    // eslint-disable-next-line
  }, [socialHistoryCtx.count]);

  const handleChangeOptionCategory = (event) => {
  }
  return (
        <Box
        component="form"
         noValidate
        onSubmit={handleSubmitInsurance}
            sx={{
              my: 4,
              mx: 12,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
            }}
          >  
              <Typography component="h1" variant="h5">
                  Record
                </Typography>
                <FormControl sx={{ m: 2}} >
                      <RadioGroup
                        sx={{ display: 'inline' }}
                        aria-labelledby="record-radio-buttons-group-label"
                        name="record-radio-buttons-group"
                        value={recordOption}
                        onChange={handleChangeRecordOption}
                      >
                        <FormControlLabel value="0" control={<Radio />} label="Create" />
                        <FormControlLabel value="1" disabled control={<Radio />} label="Update" />
                      </RadioGroup>
                </FormControl>
                <FormControl sx={{ m: 2}}>
                      <FormLabel id="radio-buttons-group-label">Tobacco Use</FormLabel>
                      <RadioGroup
                        aria-labelledby="radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={tobaccoOptionField}
                        onChange={handleChangeTobaccoUse}
                      >
                        <FormControlLabel value="1" control={<Radio />} label="Yes" />
                        <FormControlLabel value="0" control={<Radio />} label="No" />
                      </RadioGroup>
                </FormControl>
                <div>
                     {
                        tobaccoOptionField==1?
                      <React.Fragment>
                      <TextField
                      error= {packsPerDayFieldError? true:false}
                        margin="normal"
                        id="packsPerDayTobacco"
                        label="Packs Per Day"
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        name="packsPerDay"
                        value={packsPerDayField}
                        onChange={handleChangePacksPerDayField}
                        
                      />
                        <TextField
                        error= {tobaccoYearsOfUseFieldError? true:false}
                        margin="normal"
                        id="tobaccoYearsOfUseField"
                        label="Years of Use"
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        name="tobaccoYearsOfUseField}"
                        value={tobaccoYearsOfUseField}
                        onChange={handleChangeTobaccoYearsOfUseField}
                      />
                      <Stack  spacing={4} sx={{ m: 1 , mx: 0,mt: 2}}>
                      <LocalizationProvider dateAdapter={AdapterDateFns} >
                        <DesktopDatePicker
                        label="Tobaco Quit Date"
                        id="tobacoQuitDateField"
                        inputFormat="MM/dd/yyyy"
                        value={tobaccoQuitDateField}
                        onChange={handleChangeTobacoQuitDateField}
                        renderInput={(params) => (
                          <TextField {...params} error={tobaccoQuitDateError ? true : false} />
                        )}
                      />
                      </LocalizationProvider>
                    </Stack>    
                    </React.Fragment>:
                     <React.Fragment></React.Fragment>
                  }
                </div>
                <FormControl sx={{ m: 2}} >
                      <FormLabel id="radio-buttons-group-label">Smokeless Tobacco</FormLabel>
                      <RadioGroup
                        aria-labelledby="radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={smokelessTobaccoOptionField}
                        onChange={handleChangeSmokelessTobacco}
                      >
                        <FormControlLabel value="1" control={<Radio />} label="Yes" />
                        <FormControlLabel value="0" control={<Radio />} label="No" />
                      </RadioGroup>
                </FormControl>
                        <React.Fragment>
                          {
                                smokelessTobaccoOptionField==1?
                                <React.Fragment>
                            <FormControl sx={{ m: 2}} >
                            <TextField
                              error= {smokelessYearsOfUseFieldError? true:false}
                              margin="normal"
                              id="smokelessYearsOfUse"
                              label="Years of Use"
                              InputLabelProps={{ shrink: true }}
                              type="number"
                              name="smokelessYearsOfUse"
                              value={smokelessYearsOfUseField}
                              onChange={handleChangeSmokelessYearsOfUseField}
                              
                            />
                            <Stack  spacing={4} sx={{ m: 1 , mx: 0,mt: 2}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} >
                              <DesktopDatePicker
                              label="Smokeless Tobacco Quit Date"
                              id="smokelessTobaccoQuitDate"
                              inputFormat="MM/dd/yyyy"
                              value={smokelessDateField}
                              onChange={handleChangeSmokelessDateField}
                              renderInput={(params) => (
                                <TextField {...params} error={smokelessDateError ? true : false} />
                              )}
                            />
                            </LocalizationProvider>
                          </Stack>    
                        </FormControl>
                        </React.Fragment>
                         :   
                        <React.Fragment></React.Fragment>
                        }
                      </React.Fragment>
                <FormControl sx={{ m: 2}} >
                      <FormLabel id="radio-buttons-group-label">Alcohol Use</FormLabel>
                      <RadioGroup
                        aria-labelledby="radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={alcoholUseOptionField}
                        onChange={handleChangeAlcoholUse}
                      >
                        <FormControlLabel value="1" control={<Radio />} label="Yes" />
                        <FormControlLabel value="0" control={<Radio />} label="No" />
                      </RadioGroup>
                </FormControl>
                <React.Fragment>
                  {      
                      alcoholUseOptionField==1?  
                      <FormControl sx={{ m: 2}} >
                            <TextField
                            error= {drinkPerWeekFieldError? true:false}
                            margin="normal"
                            id="drinkPerWeek"
                            label="Drinks per week"
                            InputLabelProps={{ shrink: true }}
                            type="number"
                            name="drinkPerWeek"
                            value={drinkPerWeekField}
                            onChange={handleChangeDrinkPerWeekField}
                            
                          />  
                      </FormControl>
                       :   
                        <React.Fragment></React.Fragment>
                }
                </React.Fragment>
                <FormControl sx={{ m: 2}} >
                      <FormLabel id="radio-buttons-group-label">Drug Use</FormLabel>
                      <RadioGroup
                        aria-labelledby="radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={drugUseOptionField}
                        onChange={handleChangeDrugUse}
                      >
                        <FormControlLabel value="1" control={<Radio />} label="Yes" />
                        <FormControlLabel value="0" control={<Radio />} label="No" />
                      </RadioGroup>
                </FormControl>
                   <React.Fragment>
                     {      
                      drugUseOptionField==1?  
                    <React.Fragment>
                      <TextField
                        error= {drugUsePerWeekFieldError? true:false}
                        margin="normal"
                        id="drugUsePerWeek"
                        label="Use Per Week"
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        name="drugUsePerWeek"
                        value={drugUsePerWeekField}
                        onChange={handleChangeDrugUsePerWeekChild}
                        
                      />  
                       <TextField
                       error= {drugTypeFieldError? true:false}
                        margin="normal"
                        id="drugTypeField"
                        label="Types"
                        name="drugTypeField"
                        value={drugTypeField}
                        onChange={handleChangeDrugTypeField}
                        
                      />  
                      </React.Fragment>
                      :   
                        <React.Fragment></React.Fragment>
                    }
                </React.Fragment>
                
                <FormControl sx={{ m: 2}}>
                      <FormLabel id="radio-buttons-group-label">Current Partner</FormLabel>
                      <RadioGroup
                        aria-labelledby="radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={currentPartnerOptionField}
                        onChange={handleChangeCurrentPartner}
                      >
                        <FormControlLabel value="1" control={<Radio />} label="Yes" />
                        <FormControlLabel value="0" control={<Radio />} label="No" />
                      </RadioGroup>
                </FormControl>
                  <React.Fragment>
                     {      
                    currentPartnerOptionField==1?  
                    <React.Fragment>
                    <FormControl sx={{ mt: 2 }}>
                    <InputLabel id="genderLabel">Sex of Current Partner *</InputLabel>
                    <Select
                        labelId="genderLabel"
                        id="sexOfCurrentPartner"
                        value={sexOfCurrentPartner}
                        label="Sex of Current Partner"
                        onChange={handleChangeSexOfCurrentPartner}
                      >
                        <MenuItem value={0}>Female</MenuItem>
                        <MenuItem value={1}>Male</MenuItem>
                        <MenuItem value={2}>Other</MenuItem>
                        <MenuItem value={3}>Prefer not to say</MenuItem>
                      </Select>
                    </FormControl>
                    </React.Fragment>
                      :   
                        <React.Fragment></React.Fragment>
                    }
                </React.Fragment>
                <FormControl  sx={{ mt: 2}} >
                  <InputLabel  id="optionLabel">Marital Status</InputLabel>
                  <Select
                            labelId="optionLabel"
                            id="option"
                            label="Marital Status"
                            value={maritalStatusField}
                            onChange={handleChangeMaritalStatusField}
                            >
                                {
                                  arrayFieldCategory.map((option) => (
                                      <MenuItem  key={option.key} value={option.key}>{option.label}</MenuItem>
                                    ))
                                }
                  </Select>
              </FormControl>
              <PersonalizedButton  
              type="submit"
                variant="contained"
                 sx={{ mt: 1, mb: 2 }}
              >
                  Save
              </PersonalizedButton>    
        </Box>
  );
}
