import React,{useState,useEffect}  from 'react';
import { Fragment } from 'react';
import ObgynRecord from './ObgynRecord';
import ObgynReport from './ObgynReport';
import  ObgynProvider from './obgyn-Provider';
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import { styled } from '@mui/material/styles';
const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& > :not(style) + :not(style)': {
    marginTop: theme.spacing(2),
  },
}));
const ObgynCategory = () => {
  return (
      <ObgynProvider>
                    <ObgynReport/>
            <ObgynRecord/>
      </ObgynProvider>

  );
};

export default ObgynCategory;
