import { useReducer } from 'react';
import React  from 'react';
import InsuranceContext from './insurance-context';
import Divider from "@mui/material/Divider";
import { styled } from '@mui/material/styles';
const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& > :not(style) + :not(style)': {
    marginTop: theme.spacing(2),
  },
}));

const defaultInsuranceState = {
  items: [],
  count: 0,
  report:0
};

const cartReducer = (state, action) => {
  
  if (action.type === 'ADD') {

    if(typeof action.item !== 'undefined' && action.item.length === 0)
    {
      return {
        count:state.count+1,
        items: action.item,
        report:state.report+1
      };
    }
    else
    {
      return {
        count:state.count+1,
        items: action.item,
        report:0
      };
    }

  }

  return defaultInsuranceState;
};

const insuranceProvider = (props) => {
  const [insuranceState, dispatchCartAction] = useReducer(
    cartReducer,
    defaultInsuranceState
  );

  const addItemToRecordHandler = (item) => {
    dispatchCartAction({ type: 'ADD', item: item });
  };

  const cartContext = {
    items: insuranceState.items,
    count: insuranceState.count,
    report: insuranceState.report,
    addItem: addItemToRecordHandler
  };

  return (
    <InsuranceContext.Provider value={cartContext}>
      <Root>
      <Divider fullWidth={true}><h2>Insurance</h2></Divider>
      </Root>
      <React.Fragment>
      {props.children}
      </React.Fragment>
    </InsuranceContext.Provider>
  );
};

export default insuranceProvider;
