import  React,{useEffect,useState,useContext} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography
} from "@material-ui/core";
import TablePagination from '@mui/material/TablePagination';

import DeleteIcon from "@material-ui/icons/Delete";
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import FamilyMedicalHistoryContext from '../FamilyMedicalHistory/familyMedicalHistory-context';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    borderRadius: 15,
    margin: "10px 10px",
    maxWidth: "1800px",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    backgroundColor: "#4788B8",
    color: theme.palette.getContrastText(theme.palette.primary.dark)
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
  name: {
    fontWeight: "bold",
    color: "#A42C12",
  },
  status: {
    fontWeight: "bold",
    fontSize: "0.75rem",
    color: "white",
    backgroundColor: "grey",
    borderRadius: 8,
    padding: "3px 10px",
    display: "inline-block",
  },
  hover: {
    cursor: "pointer",
  },
  center:{
    textAlign:'center'
  }
}));

const tableHeader = [
  { label: "Id", data: "id" },
  { label: "Category", data: "category" },
  { label: "SubCategory", data: "subCategory" },
  { label: "Family Relationship", data: "familyRelationship" },
  { label: "Update", data: "whiteSpaceDelete" },
  { label: "Delete", data: "whiteSpaceUpdate" }
];

function createData(
  document,
  id,
  categoryId,
  subCategoryId,
  categoryName,
  subCategoryName,
  dateOfSurgery,
  familyRelationship,
  familyRelationshipName,
  option
  ) {
  return {
    document,
    id,
    categoryId,
    subCategoryId,
    categoryName,
    subCategoryName,
    dateOfSurgery,
    familyRelationship,
    familyRelationshipName,
    option
  };
}

const MTable = (props) => {
  const familyMedicalHistoryCtx = useContext(FamilyMedicalHistoryContext);
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [familyMedicalHistoryData, setData] = useState([]);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getCatalog=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICAL_HISTORY_GET_DISEASE_CATALOG;
  const getURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_FAMILY_RELATIONSHIP_GET;
  const getFamilyRelationshipCatalog=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_FAMILY_RELATIONSHIP_CATALOG;

    useEffect(() => {
     
      let array=[];
      fetch(getCatalog ,{
       method: 'GET',
       headers: {  
       'Content-Type': 'application/json',
       'Token': localStorage.getItem("token"),
       'userid':localStorage.getItem("userId")     
       }
     })
     .then((response) => response.json())
     .then((data) => {   
      let arrSubCategory=[];
      let count=0;
      let subCount=0;
      let datax=undefined;
      let objectSubCategory=[];
      let objectCategory=[];
      let existsSubCategory=0;
      try
      {
       datax= data.body.category;
  
      }
      catch
      {
        datax=[];
      }
  
      for(let category in datax)
      {
          count=count+1;
            try
            {
            existsSubCategory = datax[category].subCategory.length; 
            }  
            catch
            {
              existsSubCategory=-1;
            }
  
            if(datax[category].name.toLowerCase()=="other")
            {
                  objectCategory[category]= {
                    index: count,
                    category:datax[category].name,
                    label:datax[category].name,
                    key: category 
                  };    
            }
            else
            {
      
                  objectCategory[category]= {
                    index: count,
                    category:datax[category].name,
                    label:datax[category].name,
                    key: category 
                  };       
            } 
  
            if(existsSubCategory>0)
            {
         
              for(let subCategory in datax[category].subCategory)
              { 
                  arrSubCategory.push({
                    indexSubCategory: subCount++,
                    category:category,
                    label:datax[category].subCategory[subCategory],
                    key: subCategory
                  });
  
              }

              objectSubCategory[category]=arrSubCategory;
  
            }
            arrSubCategory=[];
  
            subCount=0;
      }

      fetch(getFamilyRelationshipCatalog ,{
        method: 'GET',
        headers: {  
        'Content-Type': 'application/json',
        'Token': localStorage.getItem("token"),
        'userid':localStorage.getItem("userId")     
        }
      })
      .then((response) => response.json())
      .then((datax) => {  
        let objectFamilyCategory=[];

        for(let index in datax.body.relationship)
        {
          objectFamilyCategory[index]={
            index: index,
            category:datax.body.relationship[index],
            label:datax.body.relationship[index],
            key: index
          };
        }

        fetch(getURL ,{
          method: 'GET',
          headers: {  
          'Content-Type': 'application/json',
          'Token': localStorage.getItem("token"),
          'userid':localStorage.getItem("userId")     
          }
        })
        .then((response) => response.json())
        .then((datax) => { 
 
             let arrayX=undefined;
             try
             {
             arrayX= datax.body[0].familyMembers;
             }
             catch
             {
               arrayX=[];
             }
             let flag=0;
             let index=0;
             for(let row in arrayX )
             {
                 index=row;
                 for(let index in arrayX[row].diseases)
                 {
                   let subCategory="";
                   let subCategoryId=0;
  
                   if(!(isNaN(parseInt(arrayX[row].diseases[index].subCategoryId))))
                   { 
                     subCategoryId=parseInt(arrayX[row].diseases[index].subCategoryId);
                     subCategory=objectSubCategory[arrayX[row].diseases[index].categoryId][subCategoryId].label.toString();
                   }
                   else
                   {
                     if(arrayX[row].diseases[index].subCategoryId=="")
                     {
                      subCategoryId=-1;
                      subCategory="";
                     }
                     else
                     {
                       subCategory=arrayX[row].diseases[index].subCategoryId;
                       subCategoryId=-2;
                     }
                   }
                    let famName=objectFamilyCategory[arrayX[row].familyRelationship].category;
                    array.push(
                      createData(
                        datax.body[0].id,
                        parseInt(row),
                        parseInt(arrayX[row].diseases[index].categoryId),
                        subCategoryId,
                        objectCategory[arrayX[row].diseases[index].categoryId].category,
                        subCategory,
                        arrayX[row].diseases[index].date,
                        arrayX[row].familyRelationship,
                        famName
                        )
                    );
                 }
  
                
             }

             setData(array);  
          })
      })

     });

      // eslint-disable-next-line
    }, [familyMedicalHistoryCtx.count]);

  const updateItem = (index) => {
    const newFamilyMedicalHistoryData = familyMedicalHistoryData.filter((data) => data.id ==familyMedicalHistoryData[index].id);
    newFamilyMedicalHistoryData.option=2;

    familyMedicalHistoryCtx.addItem(newFamilyMedicalHistoryData);
  };
  const deleteFamilyMedicalHistoryURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_FAMILY_RELATIONSHIP_DELETE;

  const deleteItem = (index) => {
    let array=[];
    array.option=3;
    familyMedicalHistoryCtx.addItem(array);
    let result= window.confirm('Are you sure? This will DELETE ALL the records for ID:'+ index.id +' - '+ index.familyRelationshipName +'. If you want to delete a specific disease, click on update, and delete it individually.' );
    if (result) {
      const newFamilyMedicalHistoryData = familyMedicalHistoryData.filter((data) => data.id !==index.id);
      const reportFamilyMedicalHistoryData=newFamilyMedicalHistoryData;
      fetch(getURL ,{
        method: 'GET',
        headers: {  
        'Content-Type': 'application/json',
        'Token': localStorage.getItem("token"),
        'userid':localStorage.getItem("userId")     
        }
      })
      .then((response) => response.json())
      .then((data) => { 
           let originalArray=[];
           let arrayX;
           try
           {
           arrayX= data.body[0].familyMembers;
           }
           catch
           {
             arrayX=[];
           }
           originalArray=data.body[0];
       
           let objCount=[];
           let diseaseDB=[];
           let storage=[];
           let dbStorage=[];
           let flag=0;
           let familyRelationshipId=0;
           let id=0;
           if(!(arrayX.length==1))
           {  

               const update=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_FAMILY_RELATIONSHIP_UPDATE;
            
               for(let row in newFamilyMedicalHistoryData)
               {
                 familyRelationshipId=parseInt(newFamilyMedicalHistoryData[row].familyRelationship);
                 id=parseInt(newFamilyMedicalHistoryData[row].id);
                 objCount[id]={"familyRelationshipId":familyRelationshipId,"id":id};
               }
         

               for(let x in objCount)
               {
                    storage= newFamilyMedicalHistoryData.filter((data) => 
                    {
                      if(data.id==objCount[x].id)
                      {
                      if(data.subCategoryId==-1 || data.subCategoryId==-2){
                          data.subCategoryId=data.subCategoryName;
                      }
                      return data;
                       }
                     
                    });
                
                 familyRelationshipId= objCount[x].familyRelationshipId;

                 dbStorage= storage.map(({id,document,categoryName,subCategoryName,dateOfSurgery,familyRelationship,familyRelationshipName,option, ...rest}) => {
                  return ({...rest,date:''})
                 });
              
                 diseaseDB[flag] = {"familyRelationship":familyRelationshipId,"diseases": dbStorage};
                 dbStorage=[];
                 storage=[];
                 flag++;
               }

               originalArray.familyMembers=diseaseDB;
               originalArray.documentId=originalArray.id;

               const requestOptions = {
                   method: 'PUT',
                   headers: { 
                     'Content-Type': 'application/json',
                     "Token": localStorage.getItem("token")
                   },
                     body: JSON.stringify(originalArray)
                };
        
             try{
               fetch(update, requestOptions)
               .then(response => response.json());
               }
               catch
               {
                 alert("Error");
               }

               setData(reportFamilyMedicalHistoryData);
            
           }
           else
           {
                const deleteData = 
                {
                documentId:  index.document
                };
                const requestOptions = {
                  method: 'DELETE',
                  headers: { 
                    
                    'Content-Type': 'application/json',
                    "Token": localStorage.getItem("token")  
                
                  },
                      body: JSON.stringify(deleteData)
                  };
                  try{
                    fetch(deleteFamilyMedicalHistoryURL, requestOptions)
                        .then(response => response.json());
                        alert("Done");

                  }
                  catch{
                      alert("Error");
                  }

                  setData(reportFamilyMedicalHistoryData);
           }
  }); 

    }
  };

  const styleObj = {
    fontSize: 30,
    color: "black",
}
  return (
    <React.Fragment>
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableHeader.map((cell) => (
              <TableCell key={cell.data} className={classes.tableHeaderCell}>
                {cell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
        {(rowsPerPage > 0
            ? familyMedicalHistoryData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : familyMedicalHistoryData
          ).map((row,i) => (
            <TableRow key={Math.random()} >
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.categoryName}</TableCell>
              <TableCell>{row.subCategoryName}</TableCell>
              <TableCell>{row.familyRelationshipName}</TableCell>
              <TableCell>
                <Grid container>
                  <Grid item lg={12}>
                    <Typography>
                      <AutoFixNormalIcon
                        color="primary"
                        onClick={updateItem.bind(this, i)}
                        className={classes.hover}
                        />
                      </Typography>
                </Grid>
                </Grid>
              </TableCell>
              <TableCell>
                <Grid container>
                  <Grid item lg={12}>
                    <Typography>
                      <DeleteIcon
                        color="secondary"
                        onClick={deleteItem.bind(this, row)}
                        className={classes.hover}
                      />
                    </Typography>
                </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={familyMedicalHistoryData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
    </TableContainer>

    </React.Fragment>
  );
};

export default MTable;