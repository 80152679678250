import  React,{ useRef,useState } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PersonalizedButton from "../Layout/Button"
import { useHistory } from 'react-router-dom';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {validateEmail,checkPassword} from "../../methods/functions"
import image from '../../assets/defaultImage.jpg';
import Checkbox from '@mui/material/Checkbox';
import { v4 as uuidv4 } from 'uuid';
import Link from '@mui/material/Link';
const theme = createTheme();
function RedBar(props) {
  return (
    <Box
      sx={{
        height: props.height,
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? 'rgba(255, 0, 0, 0.1)'
            : 'rgb(255 132 132 / 25%)',
      }}
    > 
    <div align="left" 
    style=
    {{
      mt: props.mt, 
      mb: props.mb,
      paddingTop:props.paddingTop,
      paddingLeft:props.paddingLeft,
      paddingBottom:props.paddingBottom
    }}
    > 
    {props.children} 
    </div> 
 
    
    </Box>
   
  )
}

export default function AuthForm() {
  const passwordInputRef = useRef();
  const confirmPasswordInputRef = useRef();
  const [error, setError] = useState();
  const [emailError, setEmailError] = useState();
  const [passwordError, setPasswordError] = useState();
  const [confirmPasswordError, setConfirmPasswordError] = useState();
  const [notEqualPasswordError, setNotEqualPasswordError] = useState();
  const [checked, setChecked] = React.useState(true);
  const [finalChecked, setFinalChecked] = React.useState();
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false
  });
  
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [confirmValues, confirmSetValues] = React.useState({
    amountConfirm: "",
    passwordConfirm: "",
    weightConfirm: "",
    weightConfirmRange: "",
    showConfirmPassword: false
  });


  const handleClickConfirmShowPassword = () => {
    confirmSetValues({
      ...confirmValues,
      showConfirmPassword: !confirmValues.showConfirmPassword
    });
   
  };

  const handleConfirmMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const history = useHistory();
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const signUp=process.env.REACT_APP_SIGN_UP_URL + process.env.REACT_APP_WEB_API_KEY;
    const emailVerification= process.env.REACT_APP_SEND_EMAIL_VERIFICATION + process.env.REACT_APP_WEB_API_KEY;
    const email=data.get('email');
    const password=data.get('password');
    const confirmPassword=data.get('confirmPassword');
    let finalDecision=true;
    const customSignUp=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_SIGN_UP;
    if(!validateEmail(email)){
      setEmailError(true);
    }
    else
    {
      setEmailError(false);
    }
    if(!checkPassword(password)){
      setPasswordError(true);
      finalDecision=false;
    }
    else
    {
      setPasswordError(false);
    }
    if(!checkPassword(confirmPassword)){
      setConfirmPasswordError(true);
      finalDecision=false;
    }
    else
    {
      setConfirmPasswordError(false);
    }
    if(!(password===confirmPassword))
    {
      setNotEqualPasswordError(true);
      finalDecision=false;
    }
    else
    {
      setNotEqualPasswordError(false);
    }
   

    if(finalDecision)
    {
      if(checked)
      {
        setError(false);
        fetch(signUp ,{
          method: 'POST',
          body: JSON.stringify({
            email: email,
            password:password,
            returnSecureToken:true
          }),
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then( response=> response.json())
        .then(data => {
            fetch(emailVerification, {
              method: 'POST',
              body: JSON.stringify({
                requestType:'VERIFY_EMAIL',
                idToken: data.idToken
              }),
              headers: {
                'Content-Type': 'application/json'
              }
            }).then(res => {
              const createData = {
                id: data.localId,
                name: "",
                middleName: "",
                lastName: "",
                email: email,
                telephoneNumber: "",
                street: "",
                city: "",
                state: "",
                country:"",
                dateOfBirth: "",
                zipCode :"",
                url:"",
                gender:3,
                acceptedTermsPrivacyPolicy:true,
              };
              let dataVerification= data.email? false:true;
              if (!dataVerification)
              {
                const requestOptions = {
                  method: 'POST',
                  headers: { 
                    
                    'Content-Type': 'application/json',
                    "Token": data.idToken
                
                },
                  body: JSON.stringify(createData)
               };
              fetch(customSignUp, requestOptions)
                  .then(response => response.json());
          

              }
              
              alert("Please confirm your email");

              setEmailError(false);
              setPasswordError(false);
              setConfirmPasswordError(false);
              setNotEqualPasswordError(false);
              history.replace('/');
            })
            .catch((err) => {
              alert(err.message);
            });
        } 
        );
      }
      else
      {
        setFinalChecked(true);
        setError(true);
      }
    }
    else{
    
      if(checked)
      {         
        setFinalChecked(false);   
        setError(true);
      }
      else
      {
        setFinalChecked(true);
        setError(true);
      }
    }

  };


  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '120vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${image})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: '#4788B8' }}>
            </Avatar>
            <Typography component="h1" variant="h5">
              Create account
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />            
              <FormControl fullWidth variant="outlined" sx={{ mt: 0 }}>
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    required
                    fullWidth
                    id="password"
                    name="password"
                    label="Password"
                    autoComplete="password"
                    type={values.showPassword ? "text" : "password"}
                    ref={passwordInputRef}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
              </FormControl>
              <FormControl fullWidth variant="outlined" sx={{ mt: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-confirmPassword">
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    required
                    fullWidth
                    id="confirmPassword"
                    name="confirmPassword"
                    label="confirmPassword"
                    autoComplete="confirmPassword"
                    type={confirmValues.showConfirmPassword ? "text" : "password"}
                    ref={confirmPasswordInputRef}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickConfirmShowPassword}
                          onMouseDown={handleConfirmMouseDownPassword}
                          edge="end"
                        >
                          {confirmValues.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
              </FormControl>
              <FormControl sx={{ mt: 1, mb: 1 }}> 
              <div>

                  <div>
                  <Checkbox  
                  checked={checked}
                  onChange={handleChange}
                   ></Checkbox>
                      I agree to &nbsp;
                      <Link color="inherit" href="https://oneme.app/ourpolicies/terms-of-use-and-privacy-policy">
                      Legal & Privacy Policy Terms
                      </Link>
                      </div>
              </div>
              </FormControl>
              <PersonalizedButton  
              type="submit"
                variant="contained"
                 sx={{ mt: 1, mb: 2 }}
                >
                  Sign up
                </PersonalizedButton>
                {error &&(
                <RedBar
                    paddingTop={"0.1em"}
                    paddingBottom={"0.1em"}
                >
                  <ul> 
                  {finalChecked && (<li>Please accept the terms of service and privacy policy</li>)}
                  {  emailError &&(<li>Invalid Email</li>)}
                  {  passwordError &&(<li>Invalidad Password</li>)}
                  {  confirmPasswordError &&(<li>Invalidad Confirm Password</li>)}
                  {  notEqualPasswordError &&(<li>Password and Confirm Password are different</li>)}
                  {  passwordError &&(<li>Password must contain at least: 
                      <ul style={{paddingLeft:"2em"}}>
                      <li>One lowercase alphabetical character</li>
                      <li>One uppercase alphabetical character</li>
                      <li>One numeric character</li>
                      <li>One special character and must be 12 characters or longer</li>
                      </ul>
                   </li>
                   )}
                  
                 </ul>
                </RedBar>
                )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
