import React  from 'react';
import { Fragment } from 'react';
import SurgicalHistoryRecord from './SurgicalHistoryRecord';
import SurgicalHistoryReport from './SurgicalHistoryReport';
import SurgicalHistoryProvider from './surgicalHistory-Provider';

const SurgicalHistoryCategory = () => {
  return (
      <SurgicalHistoryProvider>
            <SurgicalHistoryReport/>
            <SurgicalHistoryRecord/>
      </SurgicalHistoryProvider>

  );
};

export default SurgicalHistoryCategory;
