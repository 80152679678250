import HealthRecord from '../components/HealthRecord/HealthRecord';
import InsuranceCategory from '../components/HealthRecord/Insurance/InsuranceCategory';
import  React,{useEffect,useState,useContext } from 'react';
import MainNavigation from '../../src/components/Layout/MainNavigation';
import { Fragment } from 'react';
import MedicalProviderCategory from '../components/HealthRecord/MedicalProvider/MedicalProviderCategory';
import MedicationCategory from '../components/HealthRecord/Medication/MedicationCategory';
import SocialHistoryCategory from '../components/HealthRecord/SocialHistory/SocialHistoryCategory';
import SurgicalHistoryCategory from '../components/HealthRecord/SurgicalHistory/SurgicalHistoryCategory';
import FamilyMedicalHistoryCategory from '../components/HealthRecord/FamilyMedicalHistory/FamilyMedicalHistoryCategory';
import MedicalHistoryCategory from '../components/HealthRecord/MedicalHistory/MedicalHistoryCategory';
import VaccineCategory from '../components/HealthRecord/Vaccine/VaccineCategory';
import LabWorkCategory from '../components/HealthRecord/LabWork/LabWorkCategory';
import { useHistory } from 'react-router-dom';
import AuthContext from '../store/auth-context';
const HealthRecordPage = () => {
  return (
    <Fragment>
      <MainNavigation />
      <HealthRecord>
        <LabWorkCategory/>
        <VaccineCategory/>
        <MedicalHistoryCategory/>
        <FamilyMedicalHistoryCategory/>
        <InsuranceCategory/>
        <MedicalProviderCategory/>
        <MedicationCategory/>
        <SocialHistoryCategory/>
        <SurgicalHistoryCategory/>
     </HealthRecord>
  </Fragment>
  );
};

export default HealthRecordPage;
