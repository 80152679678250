import  React,{useEffect,useState,useContext} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography
} from "@material-ui/core";
import TablePagination from '@mui/material/TablePagination';

import DeleteIcon from "@material-ui/icons/Delete";
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import MedicalProviderContext from '../MedicalProvider/medicalProvider-context';
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    borderRadius: 15,
    margin: "10px 10px",
    maxWidth: "1800px",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    backgroundColor: "#4788B8",
    color: theme.palette.getContrastText(theme.palette.primary.dark)
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
  name: {
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
  },
  status: {
    fontWeight: "bold",
    fontSize: "0.75rem",
    color: "white",
    backgroundColor: "grey",
    borderRadius: 8,
    padding: "3px 10px",
    display: "inline-block",
  },
  hover: {
    cursor: "pointer",
  },
  center:{
    textAlign:'center'
  }
}));

const tableHeader = [
  { label: "Document", data: "documentNumber" },
  { label: "Doctor Name", data: "name" },
  { label: "Doctor Office", data: "office" },
  { label: "Specialty", data: "specialty" },
  { label: "Address", data: "address" },
  { label: "Contact Number", data: "contactNumber" },
  { label: "Update", data: "whiteSpaceDelete" },
  { label: "Delete", data: "whiteSpaceUpdate" }
];

function createData(
  documentNumber,
  name,
  office,
  specialty,
  address,
  contactNumber,
  option
  ) {
  return {
    documentNumber,
    name,
    office,
    specialty,
    address,
    contactNumber,
    option
  };
}

const MTable = (props) => {
  const medicalCtx = useContext(MedicalProviderContext);
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [medicalData, setData] = useState([]);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
    const getMedicalProviderURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICAL_PROVIDER_GET;
    useEffect(() => {
      let array=[];
          fetch(getMedicalProviderURL ,{
            method: 'GET',
            headers: {  
            'Content-Type': 'application/json',
            'Token': localStorage.getItem("token"),
            'userid':localStorage.getItem("userId")     
            }
          })
          .then((response) => response.json())
          .then((data) => {   
            for(let i in data.body) {     
              array.push(
                  createData(
                    data.body[i].id,
                    data.body[i].doctorName,
                    data.body[i].doctorOffice,
                    data.body[i].specialty,
                    data.body[i].address,
                    data.body[i].contactNumber
                    )
                );
          }
          array.push(...[]);
          setData(array);     
      })

      // eslint-disable-next-line
    }, [medicalCtx.report]);

  const updateItem = (index) => {
    medicalData[index].option=2;
    medicalCtx.addItem(medicalData[index]);
  };
  
  const deleteMedicalProviderURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICAL_PROVIDER_DELETE;

  const deleteItem = (index) => {
    let result= window.confirm("Are you sure?");
    if (result) {
      const valuex= medicalData[index];
      const newMedicalData = medicalData.filter((user, i) => i !== index);
      const {documentNumber}= valuex;
      
      const deleteData = 
      {
       documentId:  documentNumber.toString()
      };

      const requestOptions = {
        method: 'DELETE',
        headers: { 
          
          'Content-Type': 'application/json',
          "Token": localStorage.getItem("token")  
      
        },
            body: JSON.stringify(deleteData)
        };
        try{
          fetch(deleteMedicalProviderURL, requestOptions)
              .then(response => response.json());
              alert("Done");

        }
        catch{
            alert("Error");
        }

      setData(newMedicalData);
    }
  };
  const styleObj = {
    fontSize: 30,
    color: "black",
}
  return (
    <React.Fragment>
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableHeader.map((cell) => (
              <TableCell key={cell.data} className={classes.tableHeaderCell}>
                {cell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
        {(rowsPerPage > 0
            ? medicalData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : medicalData
          ).map((row,i) => (
            <TableRow key={row.documentNumber}>
              <TableCell>{row.documentNumber}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.office}</TableCell>
              <TableCell>{row.specialty}</TableCell>
              <TableCell>{row.address}</TableCell>
              <TableCell>{row.contactNumber}</TableCell>
              <TableCell>
                <Grid container>
                  <Grid item lg={12}>
                    <Typography>
                      <AutoFixNormalIcon
                        color="primary"
                        onClick={updateItem.bind(this, i)}
                        className={classes.hover}
                        />
                      </Typography>
                </Grid>
                </Grid>
              </TableCell>
              <TableCell>
                <Grid container>
                  <Grid item lg={12}>
                    <Typography>
                      <DeleteIcon
                        color="secondary"
                        onClick={deleteItem.bind(this, i)}
                        className={classes.hover}
                      />
                    </Typography>
                </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={medicalData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
    </TableContainer>

    </React.Fragment>
  );
};

export default MTable;