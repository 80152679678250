import { Fragment } from 'react';
import  React,{useEffect,useState,useContext } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PersonalizedButton from "../../Layout/Button"
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {numbersNotAllowed,emptyCheck,dateFormatter,dateIsValid} from "../../../methods/functions"
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import InsuranceContext from '../Insurance/insurance-context';

export default function InsuranceRecord() {
  const insuranceCtx = useContext(InsuranceContext);
  const [insuranceType ,setInsuranceType] = React.useState([]);
  const [insuranceTypeX ,setInsuranceTypeX] = React.useState(0);
  const [relationshipToInsurer ,setRelationshipToInsurer] = React.useState(1);
  const [coverageDateValue, setCoverageDateValue] = React.useState(new Date());
  const [expirationDateValue, setExpirationDateValue] = React.useState(new Date());
  const [nameField, setNameField] = useState('');
  const [primaryInsuranceField, setPrimaryInsuranceField] = useState(0);
  const [addressField, setAddressField] = useState();
  const [phoneNumberField, setPhoneNumberField] = useState();
  const [memberIdField, setMemberIdField] = useState();
  const [groupNumberField, setGroupNumberField] = useState();
  const [providerNameField, setProviderNameField] = useState();
  const [providerContactNumberField, setProviderContactNumberField] = useState();
	const [relationshipWithInsurerField, setRelationshipWithInsurerField] = useState();																											   
  const [insurerDateBirth, setInsurerDateBirth] = React.useState(new Date());
  const [nameFieldError, setNameFieldError] = useState();
  const [addressFieldError, setAddressFieldError] = useState()
  const [phoneNumberFieldError, setPhoneNumberFieldError] = useState()
  const [memberIdFieldError, setMemberIdFieldError] = useState()
  const [groupNumberFieldError, setGroupNumberFieldError] = useState()
  const [providerNameFieldError, setProviderNameFieldError] = useState()
  const [providerContactNumberFieldError, setProviderContactNumberFieldError] = useState();
	const [relationshipWithInsurerFieldError, setRelationshipWithFieldInsurerError] = useState();																								  
  const [recordOption ,setRecordOption] = useState(0);
  const [coverageDateError, setCoverageDateError] = useState();
  const [expirationDateUseError, setExpirationDateError] = useState();
  const getInsuranceTypeURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_GET_INSURANCE_TYPE;
  const createInsuranceURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_INSURANCE_CREATE;

  const handleChangePrimaryInsurance = (event) => {
    setPrimaryInsuranceField(event.target.value);
  };

  const handleChangeRecordOption = (event) => {
    if(event.target.value==0)
    {
      setRecordOption(0);
      setInsuranceTypeX(0);
      setRelationshipToInsurer(1);
      setCoverageDateValue(new Date());
      setExpirationDateValue(new Date());
      setNameField('');
      setPrimaryInsuranceField(0);
      setAddressField('');
      setPhoneNumberField('');
      setMemberIdField('');
      setGroupNumberField('');
      setProviderNameField('');
      setProviderContactNumberField('');
      setRelationshipWithInsurerField('');
      setInsurerDateBirth(new Date());
    }
    setRecordOption(event.target.value);
  };

  const handleSubmitInsurance = (event) => {
    event.preventDefault();
    setCoverageDateError(false);
    setExpirationDateError(false);
    setNameFieldError(false);
    setAddressFieldError(false);
    setPhoneNumberFieldError(false);
    setMemberIdFieldError(false);
    setGroupNumberFieldError(false);
    setProviderNameFieldError(false);
    setProviderContactNumberFieldError(false);
    setRelationshipWithFieldInsurerError(false);
    let relationshipWithInsurer=relationshipWithInsurerField;
    let insuranceTypeO=insuranceTypeX;
    let primaryOption=primaryInsuranceField;
    let name=nameField;
    let address= addressField;
    let phoneNumber=phoneNumberField;
    let relationshipToInsurerO= relationshipToInsurer;
    let memberId=memberIdField;
    let groupNumber= groupNumberField;
    let coverageDate=coverageDateValue;
    let expirationDate= expirationDateValue;
    let providerContactNumber= providerContactNumberField;
    let providerName=providerNameField;
    let finalDecision=true;

    if(relationshipToInsurerO==0)
    {
      relationshipWithInsurer=  relationshipWithInsurer?  relationshipWithInsurer :'';
      if(emptyCheck(relationshipWithInsurer)){
        setRelationshipWithFieldInsurerError(true);
        finalDecision=false;
      }
    }

    name= name? name :'';
    if(emptyCheck(name)){
      setNameFieldError(true);
      finalDecision=false;
    }

    address= address? address :'';
    if(emptyCheck(address)){
      setAddressFieldError(true);
      finalDecision=false;
    }

    phoneNumber= phoneNumber? phoneNumber:'';
    if(phoneNumber.trim().length>1)
    {
      if(numbersNotAllowed(phoneNumber)){
        setPhoneNumberFieldError(true);
        finalDecision=false;
      }
      else
      {
        if(phoneNumber<0|| phoneNumber==-0 )
        {
          setPhoneNumberFieldError(true);
          finalDecision=false;
        }
      }
    }
    else
    {
      setPhoneNumberFieldError(true);
      finalDecision=false;
    }

    memberId= memberId? memberId :'';
    if(emptyCheck(memberId)){
      setMemberIdFieldError(true);
      finalDecision=false;
    }

    groupNumber= groupNumber? groupNumber :'';
    if(emptyCheck(groupNumber)){
      setGroupNumberFieldError(true);
      finalDecision=false;
    }

    providerName= providerName? providerName :'';
    if(emptyCheck(providerName)){
      setProviderNameFieldError(true);
      finalDecision=false;
    }

    providerContactNumber= providerContactNumber? providerContactNumber:'';

    if(providerContactNumber.trim().length>1)
    {
      if(numbersNotAllowed(providerContactNumber)){
        setProviderContactNumberFieldError(true);
        finalDecision=false;
      }
      else
      {
          if(providerContactNumber<0 || providerContactNumber ==-0)
          {
            setProviderContactNumberFieldError(true);
            finalDecision=false;
          }
      }
    }
    else
    {
      setProviderContactNumberFieldError(true);
      finalDecision=false;
    }
   
      if (isNaN(coverageDate) || coverageDate == null) {
        try {
          if (dateIsValid(coverageDate) == false) {
            setCoverageDateError(true);
            finalDecision = false;
          }
        } catch {
          let today = new Date(coverageDate);
          let yyyy = today.getFullYear();
          if (coverageDate == null) {
            setCoverageDateError(true);
            finalDecision = false;
          }
          if (yyyy <= 1899) {
            setCoverageDateError(true);
            finalDecision = false;
          }
        }
      } else {
        let today = new Date(coverageDate);
        let yyyy = today.getFullYear();
        if (yyyy <= 1899) {
          setCoverageDateError(true);
          finalDecision = false;
        }
    }

    if (isNaN(expirationDate) || expirationDate == null) {
      try {
        if (dateIsValid(expirationDate) == false) {
          setExpirationDateError(true);
          finalDecision = false;
        }
      } catch {
        let today = new Date(expirationDate);
        let yyyy = today.getFullYear();
        if (expirationDate == null) {
          setExpirationDateError(true);
          finalDecision = false;
        }
        if (yyyy <= 1899) {
          setExpirationDateError(true);
          finalDecision = false;
        }
      }
    } else {
      let today = new Date(expirationDate);
      let yyyy = today.getFullYear();
      if (yyyy <= 1899) {
        setExpirationDateError(true);
        finalDecision = false;
      }
    }

    if(finalDecision)
    {
      setCoverageDateError(false);
      setExpirationDateError(false);
      let coverageDatee= dateFormatter(coverageDate);
      let expirationDatee= dateFormatter(expirationDate);
      let insurerDateOfBirth=dateFormatter(insurerDateBirth);
      setRelationshipWithFieldInsurerError(false);							 
      setNameFieldError(false);
      setAddressFieldError(false);
      setPhoneNumberFieldError(false);
      setMemberIdFieldError(false);
      setGroupNumberFieldError(false);
      setProviderNameFieldError(false);
      setProviderContactNumberFieldError(false);
      if(recordOption==0){
          const createData = 
          {
            userId:localStorage.getItem("userId"),
            insuranceType:insuranceTypeO,
            primary:  primaryOption,
            name: name,
            address: address,
            phoneNumber: phoneNumber,
            memberId: memberId,
            groupNumber: groupNumber,
            coverageDate: coverageDatee,
            expirationDate: expirationDatee,
            relationship :relationshipToInsurerO==0? 
            {"relationship": relationshipWithInsurer,"insurerDateOfBirth":insurerDateOfBirth}:  
            [],
            providerName :providerName,
            providerContactNumber :providerContactNumber
          }

          const requestOptions = {
            method: 'POST',
            headers: { 
              
              'Content-Type': 'application/json',
              "Token": localStorage.getItem("token")  
          
            },
                body: JSON.stringify(createData)
            };
            try{
              fetch(createInsuranceURL, requestOptions)
                  .then(response => response.json());
                  alert("Done");
                  setInsuranceTypeX(0);
                  setRelationshipToInsurer(1);
                  setCoverageDateValue(new Date());
                  setExpirationDateValue(new Date());
                  setNameField('');
                  setPrimaryInsuranceField(0);
                  setAddressField('');
                  setPhoneNumberField('');
                  setMemberIdField('');
                  setGroupNumberField('');
                  setProviderNameField('');
                  setProviderContactNumberField('');
                  setRelationshipWithInsurerField('');
                  setInsurerDateBirth(new Date());
                  insuranceCtx.items=[];
                  insuranceCtx.addItem(insuranceCtx.items);
            }
            catch{
                alert("Error");
            }       
      }
      else
      {
        setRecordOption(0);
        const updateData = 
        {
          userId:localStorage.getItem("userId"),
          documentId:insuranceCtx.items.documentNumber,
          insuranceType:insuranceTypeO,
          primary:  primaryOption,
          name: name,
          address: address,
          phoneNumber: phoneNumber,
          memberId: memberId,
          groupNumber: groupNumber,
          coverageDate: coverageDatee,
          expirationDate: expirationDatee,
          relationship :relationshipToInsurerO==0? 
          {"relationship": relationshipWithInsurer,"insurerDateOfBirth":insurerDateOfBirth}:  
          [],
          providerName :providerName,
          providerContactNumber :providerContactNumber
        };

        const userUpdate=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_INSURANCE_UPDATE;
        const requestOptions = {
          method: 'PUT',
          headers: { 
            
            'Content-Type': 'application/json',
            "Token": localStorage.getItem("token")
          },
            body: JSON.stringify(updateData)
       };
       try{
       fetch(userUpdate, requestOptions)
       .then(response => response.json());
       }
       catch
       {
        alert("Error");
       }
        setInsuranceTypeX(0);
        setRelationshipToInsurer(1);
        setCoverageDateValue(new Date());
        setExpirationDateValue(new Date());
        setNameField('');
        setPrimaryInsuranceField(0);
        setAddressField('');
        setPhoneNumberField('');
        setMemberIdField('');
        setGroupNumberField('');
        setProviderNameField('');
        setProviderContactNumberField('');
        setRelationshipWithInsurerField('');
        setInsurerDateBirth(new Date());
        alert("Done");
        insuranceCtx.items=[];
        insuranceCtx.addItem(insuranceCtx.items);
      }

    }

    

  };
  
  
    useEffect(() => {
      fetch(getInsuranceTypeURL ,{
        method: 'GET',
        headers: {  
        'Content-Type': 'application/json',
        'Token': localStorage.getItem("token")        
        }
      })
      .then((response) => response.json())
      .then((data) => {    
        const datax= data.body;
        const arr = Object.keys(datax).map(
          (key) => [{
            "Key": key,
            "Type": datax[key]
            }]
          );
          const personalizedArray=[];
          for (let i = 0; i < arr.length; i++) {
            personalizedArray.push(
              {
                value: parseInt(arr[i][0].Key),
                label:arr[i][0].Type
              }
            )
          }
          setInsuranceType(personalizedArray);

      })

      if(insuranceCtx.items.option==2)
      {
        setRecordOption(1);
      }

      let coverageDateValueX= insuranceCtx.items.coverageDate?insuranceCtx.items.coverageDate:new Date();
      let expirationDateValueX= insuranceCtx.items.expirationDate?insuranceCtx.items.expirationDate:new Date();
      let insurerDateBirthX= insuranceCtx.items.relationshipDateOfBirth?insuranceCtx.items.relationshipDateOfBirth:new Date();
      let jsonNode= insuranceCtx.items.relationship?insuranceCtx.items.relationship:"1";
  
      let insuranceTypeX= insuranceCtx.items.insuranceType?insuranceCtx.items.insuranceType:0;
      let primaryInsuranceValueX= insuranceCtx.items.primaryInsuranceValue?insuranceCtx.items.primaryInsuranceValue:0;
       setPrimaryInsuranceField(primaryInsuranceValueX);
       setInsuranceTypeX(insuranceTypeX);
       setNameField(insuranceCtx.items.name);
       setAddressField(insuranceCtx.items.address);
       setPhoneNumberField(insuranceCtx.items.phoneNumber);
       setMemberIdField(insuranceCtx.items.memberId);
       setGroupNumberField(insuranceCtx.items.groupNumber);
       setCoverageDateValue(coverageDateValueX);
       setExpirationDateValue(expirationDateValueX);
       setProviderNameField(insuranceCtx.items.providerName);
       setProviderContactNumberField(insuranceCtx.items.providerContactNumber);
       setRelationshipWithInsurerField(insuranceCtx.items.relationship);
       if(jsonNode=="1")
       {
        setRelationshipToInsurer(1);
       }
       else
       {
        setRelationshipToInsurer(0);
       }
       setInsurerDateBirth(insurerDateBirthX);
      // eslint-disable-next-line
    }, [insuranceCtx.count]);

  const changeNameField = (event) => {
      setNameField(event.target.value);
  };

  const changeAddressField = (event) => {
    setAddressField(event.target.value);
  };
					
  const changePhoneNumberField = (event) => {
    setPhoneNumberField(event.target.value);
  };

  const changeMemberIdField = (event) => {
    setMemberIdField(event.target.value);
  };
  const changeGroupNumberField = (event) => {
    setGroupNumberField(event.target.value);
  };
  const changeProviderNameField= (event) => {
    setProviderNameField(event.target.value);
  };
  const changeProviderContactNumberField= (event) => {
    setProviderContactNumberField(event.target.value);
  };
  const handleChangeInsuranceType = (event) => {
    setInsuranceTypeX(event.target.value);
  };

  const coverageDateHandleChange = (newValue) => {
      setCoverageDateValue(newValue);
  };

  const insurerDateBirthHandleChange = (newValue) => {
    setInsurerDateBirth(newValue);
};

  const expirationDateHandleChange = (newValue) => {
    setExpirationDateValue(newValue);
};

  const handleChangeRelationshipInsurer = (event) => {
    setRelationshipToInsurer(event.target.value);
  };

  const handleChangeRelationshipInsurerText= (event) => {
    setRelationshipWithInsurerField(event.target.value);
  };
  return (
        <Box
        component="form"
         noValidate
        onSubmit={handleSubmitInsurance}
            sx={{
              my: 4,
              mx: 12,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >  
              <Typography component="h1" variant="h5">
                  Record
                </Typography>
                <FormControl sx={{ m: 2}} fullWidth>
                      <RadioGroup
                        sx={{ display: 'inline' }}
                        aria-labelledby="record-radio-buttons-group-label"
                        name="record-radio-buttons-group"
                        value={recordOption}
                        onChange={handleChangeRecordOption}
                      >
                        <FormControlLabel value="0" control={<Radio />} label="Create" />
                        <FormControlLabel value="1" disabled control={<Radio />} label="Update" />
                      </RadioGroup>
                </FormControl>
                <FormControl  sx={{ mt: 3, mx: 0 }} fullWidth>
                <InputLabel id="insuranceTypeLabel">Insurance Type</InputLabel>
                        <Select
                        labelId="insuranceTypeLabel"
                        id="insuranceType"
                        label="Insurance Type"
                        value={insuranceTypeX}
                        onChange={handleChangeInsuranceType}
                        >
                            {
                                insuranceType.map((option) => (
                                  <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                ))
                              
                            }
                          </Select>

                </FormControl>
                <FormControl sx={{ m: 2}} fullWidth>
                      <FormLabel id="radio-buttons-group-label">Primary Insurance</FormLabel>
                      <RadioGroup
                        aria-labelledby="radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={primaryInsuranceField}
                        onChange={handleChangePrimaryInsurance}
                      >
                        <FormControlLabel value="1" control={<Radio />} label="Yes" />
                        <FormControlLabel value="0" control={<Radio />} label="No" />
                      </RadioGroup>
                </FormControl>
                <TextField
                        InputLabelProps={{ shrink: true }}
                        error= {nameFieldError? true:false}
                        sx={{ m: 0}}
                        margin="normal"
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        value={nameField}
                        onChange={changeNameField}
                                        
                />      
                <TextField
                        InputLabelProps={{ shrink: true }}
                        error= {addressFieldError? true:false}
                        margin="normal"
                        fullWidth
                        id="address"
                        label="Address"
                        name="Address"
                        value={addressField}
                        onChange={changeAddressField}
                        
                />      
               <TextField
                error= {phoneNumberFieldError? true:false}
                margin="normal"
                fullWidth
                id="phoneNumber"
                label="Phone Number"
                InputLabelProps={{ shrink: true }}
                type="number"
                name="phoneNumber"
                value={phoneNumberField}
                onChange={changePhoneNumberField}
                
              />       
             <FormControl  sx={{ mt: 3, mx: 0 }} fullWidth>
                <InputLabel id="relationshipToInsurerLabel">Relationship to Insurer</InputLabel>
                        <Select
                        labelId="insuranceTypeLabel"
                        id="relationshipToInsurer"
                        label="Relationship to Insurer"
                        value={relationshipToInsurer}
                        onChange={handleChangeRelationshipInsurer}
                        >
                            <MenuItem value={0}>Other</MenuItem>
                            <MenuItem value={1}>Self</MenuItem>
                        </Select>

                </FormControl>
                <div>
                    {
                      relationshipToInsurer==0?
                      <React.Fragment>
                      <TextField
                      error= {relationshipWithInsurerFieldError? true:false}
                      margin="normal"
                      fullWidth
                      sx={{ mt: 3, mx: 0 }}
                      id="relationshipwithInsurerId"
                      label="Relationship with Insurer"
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      name="relationshipWithInsurer"
                      value={relationshipWithInsurerField}
                      onChange={handleChangeRelationshipInsurerText}
                    />   
                    <Stack  spacing={4} sx={{ m: 1 , mx: 0,mt: 2}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} >
                      <DesktopDatePicker
                      label="Insurer Date of Birth*"
                      id="insurerDateOfBirth"
                      inputFormat="MM/dd/yyyy"
                      value={insurerDateBirth}
                      onChange={insurerDateBirthHandleChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    </LocalizationProvider>
                   </Stack>    
                   </React.Fragment>
                        :   
                        <React.Fragment></React.Fragment>
                    }
                </div>
                <TextField
                error= {memberIdFieldError? true:false}
                margin="normal"
                fullWidth
                sx={{ mt: 3, mx: 0 }}
                id="memberId"
                label="Member ID"
                InputLabelProps={{ shrink: true }}
                type="text"
                name="memberId"
                value={memberIdField}
                onChange={changeMemberIdField}
              />   
              <TextField
                error= {groupNumberFieldError? true:false}
                margin="normal"
                fullWidth
                id="groupNumber"
                label="Group Number"
                InputLabelProps={{ shrink: true }}
                type="text"
                name="groupNumber"
                value={groupNumberField}
                onChange={changeGroupNumberField}
              />
              <Stack  spacing={4} sx={{ m: 1 , mx: 0,mt: 2}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} >
                          <DesktopDatePicker
                          label="Coverage Date *"
                          id="coverageDate"
                          inputFormat="MM/dd/yyyy"
                          value={coverageDateValue}
                          onChange={coverageDateHandleChange}
                          renderInput={(params) => (
                            <TextField {...params} error={coverageDateError ? true : false} />
                          )}
                        />
                        </LocalizationProvider>
              </Stack>   
              <Stack  spacing={4} sx={{ m: 1 , mx: 0,mt: 2}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} >
                          <DesktopDatePicker
                          label="Expiration Date *"
                          id="expirationDate"
                          inputFormat="MM/dd/yyyy"
                          value={expirationDateValue}
                          onChange={expirationDateHandleChange}
                          renderInput={(params) => (
                            <TextField {...params} error={expirationDateUseError ? true : false} />
                          )}
                        />
                        </LocalizationProvider>
              </Stack>  
              <TextField
                error= {providerNameFieldError? true:false}
                margin="normal"
                fullWidth
                id="providerName"
                label="Provider Name"
                InputLabelProps={{ shrink: true }}
                type="text"
                name="providerName"
                value={providerNameField}
                onChange={changeProviderNameField}
                
              />
                <TextField
                error= {providerContactNumberFieldError? true:false}
                margin="normal"
                fullWidth
                id="providerContactNumber"
                label="Provider Contact Number"
                InputLabelProps={{ shrink: true }}
                type="number"
                name="providerContactNumber"
                value={providerContactNumberField}
                onChange={changeProviderContactNumberField}
                
              />
              <PersonalizedButton  
              type="submit"
                variant="contained"
                 sx={{ mt: 1, mb: 2 }}
              >
                  Save
              </PersonalizedButton>    
        </Box>
  );
}
