import  React,{useEffect,useState,useContext} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography
} from "@material-ui/core";
import TablePagination from '@mui/material/TablePagination';

import DeleteIcon from "@material-ui/icons/Delete";
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import SocialHistoryContext  from './socialHistory-context';
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    borderRadius: 15,
    margin: "10px 10px",
    maxWidth: "1800px",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    backgroundColor: "#4788B8",
    color: theme.palette.getContrastText(theme.palette.primary.dark)
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
  name: {
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
  },
  status: {
    fontWeight: "bold",
    fontSize: "0.75rem",
    color: "white",
    backgroundColor: "grey",
    borderRadius: 8,
    padding: "3px 10px",
    display: "inline-block",
  },
  hover: {
    cursor: "pointer",
  },
  center:{
    textAlign:'center'
  }
}));

const tableHeader = [
  { label: "Document", data: "documentNumber" },
  { label: "Tobacco", data: "tobacco" },
  { label: "Smokeless Tobacco", data: "smokelessTobacco" },
  { label: "Alcohol", data: "alcohol" },
  { label: "Drug Use", data: "drugUse" },
  { label: "Marital", data: "marital" },
  { label: "Current Partner", data: "currentPartner" },
  { label: "Update", data: "whiteSpaceDelete" },
  { label: "Delete", data: "whiteSpaceUpdate" }
];

function createData(
  documentNumber,
  tobacco,
  tobaccoPacksPerDay,
  tobaccoQuitDate,
  tobaccoYearsOfUse,
  smokeless,
  smokelessQuitDate,
  smokelessYearsOfUse,
  alcohol,
  drinkPerWeek,
  currentPartner,
  sexOfCurrentPartner,
  drug,
  drugType,
  usePerWeek,
  marital,
  maritalName,
  option
  ) {
  return {
    documentNumber,
    tobacco,
    tobaccoPacksPerDay,
    tobaccoQuitDate,
    tobaccoYearsOfUse,
    smokeless,
    smokelessQuitDate,
    smokelessYearsOfUse,
    alcohol,
    drinkPerWeek,
    currentPartner,
    sexOfCurrentPartner,
    drug,
    drugType,
    usePerWeek,
    marital,
    maritalName,
    option
  };
}

const MTable = (props) => {
  const socialHistoryCtx = useContext(SocialHistoryContext);
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [socialHistoryData, setData] = useState([]);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
    const getSocialHistoryURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_SOCIAL_HISTORY_GET;
    function getMaritalStatus()
    {
      const getMaritalStatusURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MARITAL_STATUS_GET;
      return fetch(getMaritalStatusURL ,{
       method: 'GET',
       headers: {  
       'Content-Type': 'application/json',
       'Token': localStorage.getItem("token")   
       }
     })
     .then((response) => response.json())
     .then((data) => {   
         return data.body;
      
     });
    }

    useEffect(() => {
      let array=[];
      let objectCategory=[];
          getMaritalStatus().then(response => {
            let count=0;
            for(let row in response.category)
            {

              objectCategory[row]={
                index: count++,
                category:response.category[row],
                label:response.category[row],
                key: row
              };
            }
            fetch(getSocialHistoryURL ,{
              method: 'GET',
              headers: {  
              'Content-Type': 'application/json',
              'Token': localStorage.getItem("token"),
              'userid':localStorage.getItem("userId")     
              }
            })
            .then((response) => response.json())
            .then((data) => {   
              for(let i in data.body) {     
                let maritalName= objectCategory[data.body[i].marital].label;
    
                array.push(
                    createData(
                      data.body[i].id,
                      data.body[i].tobacco==0?0:1,
                      data.body[i].tobacco.packsPerDay?data.body[i].tobacco.packsPerDay:0,
                      data.body[i].tobacco.quitDate?data.body[i].tobacco.quitDate:'',
                      data.body[i].tobacco.yearsOfUse?data.body[i].tobacco.yearsOfUse:0,
                      data.body[i].smokelessTobacco==0?0:1,
                      data.body[i].smokelessTobacco.quitDate?data.body[i].smokelessTobacco.quitDate:0,
                      data.body[i].smokelessTobacco.yearsOfUse?data.body[i].smokelessTobacco.yearsOfUse:0,
                      data.body[i].alcohol==0?0:1,
                      data.body[i].alcohol.drinksPerWeek?data.body[i].alcohol.drinksPerWeek:0,
                      data.body[i].currentPartner==0?0:1,
                      data.body[i].currentPartner.sexOfCurrentPartner?data.body[i].currentPartner.sexOfCurrentPartner:0,
                      data.body[i].drug==0?0:1,
                      data.body[i].drug.drugType?data.body[i].drug.drugType:'',
                      data.body[i].drug.usePerWeek?data.body[i].drug.usePerWeek:0,
                      data.body[i].marital,
                      maritalName
                      )
                  );
            }
            array.push(...[]);
            setData(array);     
        });

          }
         );

      // eslint-disable-next-line
    }, [socialHistoryCtx.report]);

  const updateItem = (index) => {
    socialHistoryData[index].option=2;
    socialHistoryCtx.addItem(socialHistoryData[index]);
  };
  
  const deleteSocialHistoryURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_SOCIAL_HISTORY_DELETE;

  const deleteItem = (index) => {
    let result= window.confirm("Are you sure?");
    if (result) {
      const valuex= socialHistoryData[index];
      const newSocialHistoryData = socialHistoryData.filter((user, i) => i !== index);
      const {documentNumber}= valuex;
      
      const deleteData = 
      {
       documentId:  documentNumber.toString()
      };

      const requestOptions = {
        method: 'DELETE',
        headers: { 
          
          'Content-Type': 'application/json',
          "Token": localStorage.getItem("token")  
      
        },
            body: JSON.stringify(deleteData)
        };
        try{
          fetch(deleteSocialHistoryURL, requestOptions)
              .then(response => response.json());
              alert("Done");

        }
        catch{
            alert("Error");
        }

      setData(newSocialHistoryData);
    }
  };
  const styleObj = {
    fontSize: 30,
    color: "black",
}
  return (
    <React.Fragment>
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableHeader.map((cell) => (
              <TableCell key={cell.data} className={classes.tableHeaderCell}>
                {cell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
        {(rowsPerPage > 0
            ? socialHistoryData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : socialHistoryData
          ).map((row,i) => (
            <TableRow key={row.documentNumber}>
              <TableCell>{row.documentNumber}</TableCell>
              <React.Fragment>
                  <TableCell>
                  {
                    row.tobacco==0?
                    <React.Fragment></React.Fragment>
                    :
                    <div>      
                        <li>Packs per day: {row.tobaccoPacksPerDay}</li>
                        <li>Years of use: {row.tobaccoYearsOfUse}</li>
                        <li>Quit Date: {row.tobaccoQuitDate}</li>
                      </div>
                  }
                </TableCell>
              </React.Fragment>
              <TableCell>
              {
                    row.smokeless==0?
                    <React.Fragment></React.Fragment>
                    :
                    <div>  
                    <li>Years of use: {row.smokelessYearsOfUse}</li>
                    <li>Quit Date: {row.smokelessQuitDate}</li>
                    </div>
              }
              </TableCell>
              <TableCell>
              {
                    row.alcohol==0?
                    <React.Fragment></React.Fragment>
                    :
                    <div>  
                    <li>Drinks per week: {row.drinkPerWeek}</li>
                    </div>
              }
              </TableCell>
              <TableCell>
              {
                    row.drug==0?
                    <React.Fragment></React.Fragment>
                    :
                    <div>  
                    <li>Use per week: {row.usePerWeek}</li>
                    <li>Drug Type: {row.drugType}</li>
                    </div>
              }
              </TableCell>
              <TableCell>
                {row.maritalName}
              </TableCell>
              <TableCell>
              {
                    row.currentPartner==0?
                    <React.Fragment></React.Fragment>
                    :
                    <div>  
                    <li>Sex of current partner: 
                      {
                      row.sexOfCurrentPartner==3?" Prefer not to say":
                      row.sexOfCurrentPartner==2?" Other":
                      row.sexOfCurrentPartner==1?" Male":
                      row.sexOfCurrentPartner==0?" Female":""
                      }     
                      </li>
                      </div>
              }
              </TableCell>
              <TableCell>
                <Grid container>
                  <Grid item lg={12}>
                    <Typography>
                      <AutoFixNormalIcon
                        color="primary"
                        onClick={updateItem.bind(this, i)}
                        className={classes.hover}
                        />
                      </Typography>
                </Grid>
                </Grid>
              </TableCell>
              <TableCell>
                <Grid container>
                  <Grid item lg={12}>
                    <Typography>
                      <DeleteIcon
                        color="secondary"
                        onClick={deleteItem.bind(this, i)}
                        className={classes.hover}
                      />
                    </Typography>
                </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={socialHistoryData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
    </TableContainer>

    </React.Fragment>
  );
};

export default MTable;