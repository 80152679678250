import { Fragment } from 'react';
import  React,{useEffect,useState,useContext } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PersonalizedButton from "../../Layout/Button"
import FormControl from "@mui/material/FormControl";
import {numbersNotAllowed,emptyCheck,dateFormatter,dateIsValid} from "../../../methods/functions"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MedicationContext from '../Medication/medication-context';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Stack from "@mui/material/Stack";
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { isExists } from 'date-fns';
export default function InsuranceRecord() {
  const medicationCtx = useContext(MedicationContext);

  const [nameField, setNameField] = useState('');
  const [dosageField, setDosageField] = useState('');
  const [frequencyField, setFrequencyField] = useState('');
  const [dateOfFirstUseValue, setDateOfFirstUseValue] = React.useState(new Date());
  const [dateOfLastUseValue, setDateOfLastUseValue] = React.useState(new Date());
  const [updated,setUpdatedField]=useState('');
  const [nameFieldError, setNameFieldError] = useState();
  const [dosageFieldError, setDosageFieldError] = useState();
  const [frequencyFieldError, setFrequencyFieldError] = useState();
  const [dateOfFirstUseError, setDateOfFirstUseError] = useState();
  const [dateOfLastUseError, setDateOfLastUseError] = useState();													  
  const [recordOption ,setRecordOption] = useState(0);

  const createMedicationURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICATION_CREATE;

  const handleChangeRecordOption = (event) => {
    if(event.target.value==0)
    {
      setRecordOption(0);
      setNameField('');
      setFrequencyField('');
      setDosageField('');
      setDateOfFirstUseValue(new Date());
      setDateOfLastUseValue(new Date());
    }
    setRecordOption(event.target.value);
  };

  const handleSubmitInsurance = (event) => {
    event.preventDefault();
    setNameFieldError(false);
    setDosageFieldError(false);
    setFrequencyFieldError(false);
    setDateOfFirstUseError(false);
    setDateOfLastUseError(false);
    let name=nameField;
    let dosage=dosageField;
    let frequency= frequencyField;
    let dateOfFirstUseValuee=dateOfFirstUseValue;
    let dateOfLastUseValuee= dateOfLastUseValue;
    let finalDecision=true;
    let flag=0;
    name= name? name :'';
    if(emptyCheck(name)){
      setNameFieldError(true);
      finalDecision=false;
    }

    dosage=dosage? dosage :'';
    if(emptyCheck(dosage)){
      setDosageFieldError(true);
      finalDecision=false;
    }

    frequency= frequency? frequency :'';
    if(emptyCheck(frequency)){
      setFrequencyFieldError(true);
      finalDecision=false;
    }

    if (isNaN(dateOfFirstUseValuee) || dateOfFirstUseValuee == null) {
      try {
        if (dateIsValid(dateOfFirstUseValuee) == false) {
          setDateOfFirstUseError(true);
          finalDecision = false;
        }
      } catch {
        let today = new Date(dateOfFirstUseValuee);
        let yyyy = today.getFullYear();
        if (dateOfFirstUseValuee == null) {
          setDateOfFirstUseError(true);
          finalDecision = false;
        }
        if (yyyy <= 1899) {
          setDateOfFirstUseError(true);
          finalDecision = false;
        }
      }
    } else {
      let today = new Date(dateOfFirstUseValuee);
      let yyyy = today.getFullYear();
      if (yyyy <= 1899) {
        setDateOfFirstUseError(true);
        finalDecision = false;
      }
    }

    if (isNaN(dateOfLastUseValuee) || dateOfLastUseValuee == null) {
      try {
        if (dateIsValid(dateOfLastUseValuee) == false) {
          setDateOfLastUseError(true);
          finalDecision = false;
        }
      } catch {
        let today = new Date(dateOfLastUseValuee);
        let yyyy = today.getFullYear();
        if (dateOfLastUseValuee == null) {
          setDateOfLastUseError(true);
          finalDecision = false;
        }
        if (yyyy <= 1899) {
          setDateOfLastUseError(true);
          finalDecision = false;
        }
      }
    } else {
      let today = new Date(dateOfLastUseValuee);
      let yyyy = today.getFullYear();
      if (yyyy <= 1899) {
        setDateOfLastUseError(true);
        finalDecision = false;
      }
    }
    if(finalDecision)
    {
          setDateOfFirstUseError(false);
          setDateOfLastUseError(false);
          let dateOfFirstUsee= dateFormatter(dateOfFirstUseValuee);
          let dateOfLastUsee= dateFormatter(dateOfLastUseValuee);
          setNameFieldError(false);
          setDosageFieldError(false);
          setFrequencyFieldError(false);
          const getMedicationURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICATION_GET;
          fetch(getMedicationURL ,{
            method: 'GET',
            headers: {  
            'Content-Type': 'application/json',
            'Token': localStorage.getItem("token"),
            'userid':localStorage.getItem("userId")     
            }
          })
          .then((response) => response.json())
          .then((data) => {
            
            let arrayX;
            try
            {
            arrayX= data.body[0].medication;
            }
            catch
            {
              arrayX=[];
            }
      
            if(arrayX.length==0)
            {
                const medicationData={
                  userId:localStorage.getItem("userId"),
                  medication: [
                    {
                          medicationName: name,
                          dosage: dosage,
                          frequency: frequency,
                          dateOfFirstUse: dateOfFirstUsee,
                          dateOfLastUse: dateOfLastUsee
                    }
                  ]       
                };
                const requestOptions = {
                  method: 'POST',
                  headers: { 
                    
                    'Content-Type': 'application/json',
                    "Token": localStorage.getItem("token")  
                
                  },
                      body: JSON.stringify(medicationData)
                  };
                  try{
                    fetch(createMedicationURL, requestOptions)
                        .then(response => response.json());
                        alert("Done");
                        setNameField('');
                        setFrequencyField('');
                        setDosageField('');
                        setDateOfFirstUseValue(new Date());
                        setDateOfLastUseValue(new Date());
      
                        medicationCtx.items=[];
                        medicationCtx.addItem(medicationCtx.items);
                  }
                  catch{
                      alert("Error");
                  }  
            }
            else
            {
                   if(recordOption==1){
                    setRecordOption(0);
                    const valuex= medicationCtx;
                    let medication=[];
                    let originalArray=[];
 
                    originalArray=data.body;
                    for(let m in arrayX )
                    {
                     
                      if(m==valuex.items.id)
                      {
                           medication.push(
                             {
                                   medicationName: name,
                                   dosage:dosage,
                                   frequency: frequency,
                                   dateOfFirstUse: dateOfFirstUsee,
                                   dateOfLastUse: dateOfLastUsee
                             }  
                         );
                      }
                      else
                      {
                           medication.push(
                             {
                                   medicationName: arrayX[m].medicationName,
                                   dosage:arrayX[m].dosage,
                                   frequency: arrayX[m].frequency,
                                   dateOfFirstUse: arrayX[m].dateOfFirstUse,
                                   dateOfLastUse:  arrayX[m].dateOfLastUse
                             }  
                         );
                      }
       
                     
                    }
                    originalArray[0].documentId=originalArray[0].id;
                    originalArray[0].medication=medication;
       
                    const medicationUpdate=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICATION_UPDATE;
                    const requestOptions = {
                      method: 'PUT',
                      headers: { 
                        
                        'Content-Type': 'application/json',
                        "Token": localStorage.getItem("token")
                      },
                        body: JSON.stringify(originalArray[0])
                   };
                    try{
                    fetch(medicationUpdate, requestOptions)
                    .then(response => response.json());
                    medicationCtx.items=[];
                    setUpdatedField(1);
                    medicationCtx.report=medicationCtx.report+1;
                    medicationCtx.addItem(medicationCtx.items);
       
                    }
                    catch
                    {
                     alert("Error");
                    }
 
                   setDateOfFirstUseValue(new Date());
                   setDateOfLastUseValue(new Date());
                   setNameField('');
                   setFrequencyField('');
                   setDosageField('');
                   alert("Done");
                   
                   medicationCtx.addItem(medicationCtx.items);
                   }
                   else{
                    const valuex= medicationCtx;
                    let originalArray=[];
 
                    originalArray=data.body;
                    originalArray[0].medication.push(
                      {
                        medicationName: name,
                        dosage: dosage,
                        frequency: frequency,
                        dateOfFirstUse: dateOfFirstUsee,
                        dateOfLastUse: dateOfLastUsee
                      }
                    );

                    originalArray[0].documentId=originalArray[0].id;
                     
                    const medicationUpdate=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_MEDICATION_UPDATE;
                    const requestOptions = {
                      method: 'PUT',
                      headers: { 
                        
                        'Content-Type': 'application/json',
                        "Token": localStorage.getItem("token")
                      },
                        body: JSON.stringify(originalArray[0])
                   };

                    try{
                    fetch(medicationUpdate, requestOptions)
                    .then(response => response.json());
                    medicationCtx.items=[];
                    setUpdatedField(1);
                    medicationCtx.report=medicationCtx.report+1;
                    medicationCtx.addItem(medicationCtx.items);
                    }
                    catch
                    {
                     alert("Error");
                    }

                   setDateOfFirstUseValue(new Date());
                   setDateOfLastUseValue(new Date());
                   setNameField('');
                   setFrequencyField('');
                   setDosageField('');
                   alert("Done");
                   
                   medicationCtx.addItem(medicationCtx.items);
                   }

            }
          }
          );
    }

    

  };
  
  
    useEffect(() => {
      if(updated==1)
      {
        setNameField("");
        setFrequencyField("");
        setDosageField("");
        setDateOfFirstUseValue(new Date());
        setDateOfLastUseValue(new Date());
        setRecordOption(0);
        setUpdatedField(0);
      }
      if(medicationCtx.items.option==2)
      {
        
            let dateOfFirstUse= medicationCtx.items.dateOfFirstUse?medicationCtx.items.dateOfFirstUse:new Date();
            let dateOfLastUse= medicationCtx.items.dateOfLastUse?medicationCtx.items.dateOfLastUse:new Date();
            setNameField(medicationCtx.items.medicationName);
            setFrequencyField(medicationCtx.items.frequency);
            setDosageField(medicationCtx.items.dosage);
            setDateOfFirstUseValue(dateOfFirstUse);
            setDateOfLastUseValue(dateOfLastUse);
            setRecordOption(1);

      }
      // eslint-disable-next-line
    }, [medicationCtx]);

  const dateOfFirstUseValueChange = (newValue) => {
    setDateOfFirstUseValue(newValue);
  };

  const dateOfLastUseValueChange = (newValue) => {
    setDateOfLastUseValue(newValue);
  };
  
  const changeNameField = (event) => {
    setNameField(event.target.value);
  };

  const changeDosageField = (event) => {
    setDosageField(event.target.value);
  };

  const changeFrequencyField = (event) => {
    setFrequencyField(event.target.value);
  };

  return (
        <Box
        component="form"
         noValidate
        onSubmit={handleSubmitInsurance}
            sx={{
              my: 4,
              mx: 12,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >  
              <Typography component="h1" variant="h5">
                  Record
                </Typography>
                <FormControl sx={{ m: 2}} fullWidth>
                      <RadioGroup
                        sx={{ display: 'inline' }}
                        aria-labelledby="record-radio-buttons-group-label"
                        name="record-radio-buttons-group"
                        value={recordOption}
                        onChange={handleChangeRecordOption}
                      >
                        <FormControlLabel value="0" control={<Radio />} label="Create" />
                        <FormControlLabel value="1" disabled control={<Radio />} label="Update" />
                      </RadioGroup>
              </FormControl>
                <TextField
                        InputLabelProps={{ shrink: true }}
                        error= {nameFieldError? true:false}
                        sx={{ m: 2}}
                        margin="normal"
                        fullWidth
                        id="name"
                        label="Medication Name"
                        name="name"
                        value={nameField}
                        onChange={changeNameField}
                                        
                />      
                <TextField
                        InputLabelProps={{ shrink: true }}
                        error= {dosageFieldError? true:false}
                        sx={{ m: 2}}
                        margin="normal"
                        fullWidth
                        id="dosage"
                        label="Dosage"
                        name="dosage"
                        value={dosageField}
                        onChange={changeDosageField}
                                        
                />     
                <TextField
                        InputLabelProps={{ shrink: true }}
                        error= {frequencyFieldError? true:false}
                        sx={{ m: 2}}
                        margin="normal"
                        fullWidth
                        id="frequency"
                        label="Frequency"
                        name="frequency"
                        value={frequencyField}
                        onChange={changeFrequencyField}
                                        
                />     
                <Stack  spacing={4} sx={{ m: 1 , mx: 0,mt: 2}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} >
                      <DesktopDatePicker
                      label="Date of first use *"
                      id="dateOfFirstUse"
                      inputFormat="MM/dd/yyyy"
                      value={dateOfFirstUseValue}
                      onChange={dateOfFirstUseValueChange}
                      renderInput={(params) => (
                        <TextField {...params} error={dateOfFirstUseError? true : false} />
                      )}
                    />
                    </LocalizationProvider>
                </Stack>   
                <Stack  spacing={4} sx={{ m: 1 , mx: 0,mt: 2}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} >
                      <DesktopDatePicker
                      label="Date of last use *"
                      id="dateOfLastUse"
                      inputFormat="MM/dd/yyyy"
                      value={dateOfLastUseValue}
                      onChange={dateOfLastUseValueChange}
                      renderInput={(params) => (
                        <TextField {...params} error={dateOfLastUseError ? true : false} />
                      )}
                    />
                    </LocalizationProvider>
                </Stack>   
              <PersonalizedButton  
              type="submit"
                variant="contained"
                 sx={{ mt: 1, mb: 2 }}
              >
                  Save
              </PersonalizedButton>    
        </Box>
  );
}
