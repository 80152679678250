import React,{ useState }  from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PersonalizedButton from "../Layout/Button"
import { useHistory } from 'react-router-dom';
import {validateEmail} from "../../methods/functions"
import image from '../../assets/defaultImage.jpg';
const theme = createTheme();

function RedBar(props) {
  return (
    <Box
      sx={{
        height: props.height,
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? 'rgba(255, 0, 0, 0.1)'
            : 'rgb(255 132 132 / 25%)',
      }}
    > 
    <div align="left" 
    style=
    {{
      mt: props.mt, 
      mb: props.mb,
      paddingTop:props.paddingTop,
      paddingLeft:props.paddingLeft,
      paddingBottom:props.paddingBottom
    }}
    > 
    {props.children} 
    </div> 
    </Box>
   
  )
}

export default function ResetPassword() {
  const [error, setError] = useState();
  const [emailError, setEmailError] = useState();
  const history = useHistory();
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const resetPassword=process.env.REACT_APP_RESET_PASSWORD + process.env.REACT_APP_WEB_API_KEY;
    const email=data.get('email');
    if(!validateEmail(email)){
      setError(true);
      setEmailError(true);
    }
    else{
      setError(false);
      fetch(resetPassword, {
        method: 'POST',
        body: JSON.stringify({
          email: email,
          requestType: 'PASSWORD_RESET'
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        setEmailError(false);
        alert("An e-mail has been sent to change the password.")
        history.replace('/');
      })
      .catch((err) => {
        setEmailError(false);
        console.log(err.message);
      });
  }

    
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${image})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: '#4788B8' }}>
            </Avatar>
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
             <PersonalizedButton  
              type="submit"
                variant="contained"
                 sx={{ mt: 3, mb: 2 }}
                >
                  Reset Password
                </PersonalizedButton>
                {error &&(
                <RedBar
                    paddingTop={"0.1em"}
                    paddingBottom={"0.1em"}
                >
                  <ul> 
                  {  emailError &&(<li>Invalid Email</li>)}
                 </ul>
                </RedBar>
                )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
