import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import image from '../../assets/defaultImage.jpg';
const theme = createTheme();

export default function TermsOfService() {

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${image})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h1>Terms of Service</h1>
            <div>
            <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse non neque at ipsum placerat posuere et nec odio. Maecenas dui diam, gravida non luctus sit amet, bibendum a ligula. Aliquam non eros commodo, blandit urna id, mattis dolor. Curabitur gravida ipsum in erat rhoncus porta. Quisque sit amet pharetra nunc, vel varius lacus. Sed tempus metus eget est suscipit eleifend. Proin molestie sapien velit, vitae laoreet purus condimentum a. Ut eu lacus eget dolor semper pretium et vel dui. Nam pharetra vehicula ex a commodo. Nulla feugiat tellus sodales, faucibus nibh quis, condimentum libero. Pellentesque ex turpis, vestibulum sit amet volutpat id, rhoncus semper tortor.
            </p>
            <p>
             Nunc eu felis a ipsum auctor tempus ac iaculis sem. Vivamus orci metus, posuere porttitor imperdiet et, euismod blandit eros. In vulputate dui neque, vel eleifend mauris consectetur at. Maecenas dignissim interdum arcu, quis gravida enim condimentum quis. Praesent vitae nunc et eros dapibus sagittis ut in massa vel
             </p>
             <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse non neque at ipsum placerat posuere et nec odio. Maecenas dui diam, gravida non luctus sit amet, bibendum a ligula. Aliquam non eros commodo, blandit urna id, mattis dolor. Curabitur gravida ipsum in erat rhoncus porta. Quisque sit amet pharetra nunc, vel varius lacus. Sed tempus metus eget est suscipit eleifend. Proin molestie sapien velit, vitae laoreet purus condimentum a. Ut eu lacus eget dolor semper pretium et vel dui. Nam pharetra vehicula ex a commodo. Nulla feugiat tellus sodales, faucibus nibh quis, condimentum libero. Pellentesque ex turpis, vestibulum sit amet volutpat id, rhoncus semper tortor.
            </p>
            <p>
             Nunc eu felis a ipsum auctor tempus ac iaculis sem. Vivamus orci metus, posuere porttitor imperdiet et, euismod blandit eros. In vulputate dui neque, vel eleifend mauris consectetur at. Maecenas dignissim interdum arcu, quis gravida enim condimentum quis. Praesent vitae nunc et eros dapibus sagittis ut in massa vel
             </p>
             </div>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
