import MainNavigation from '../../src/components/Layout/MainNavigation';
import Box from '@mui/material/Box';
import {getParameters} from "../methods/functions"
import  React,{useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import AuthContext from '../store/auth-context';

const InvitePage = () => {
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;
  const history = useHistory();
useEffect(() => {

  const url=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_GET_INVITE_ACCEPT;
  let {token,email}=getParameters(window.location.href);
  
  const data={
    token:token,
    recipientEmail: email
  };

  const requestOptions = {
    method: 'POST',
    headers: { 
      
      'Content-Type': 'application/json'
  
    },
    
        body: JSON.stringify(data)
    };
    try{
      fetch(url, requestOptions)
          .then(response => response.json())
          .then((data) => {
            if(data.body.userExists==1){  
              if(isLoggedIn==true)
              {
                history.replace('/healthRecordSharedWithMe');
              }
              else
              {
                history.replace('/login');
              }
            }
             else
             {
              history.replace('/profile');
             }
          });     
    }
    catch{
        alert("Error");
    } 
    
}, [getParameters(window.location.href)]);


  return (
    <React.Fragment>
    <MainNavigation />
    <Box
        component="form"
         noValidate
            sx={{
              my: 4,
              mx: 12,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
            }}
          >  
              <center>
    <section>
      <h1>Loading...</h1>
    </section>
    </center>
     </Box>
    </React.Fragment>
  );
};

export default InvitePage;
