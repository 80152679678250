import React,{useState,useEffect}  from 'react';
import { Fragment } from 'react';
import GynecologicalHistoryRecord from './GynecologicalHistoryRecord';
import GynecologicalHistoryReport from './GynecologicalHistoryReport';
import  GynecologicalHistoryProvider from './gynecologicalHistory-Provider';
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import { styled } from '@mui/material/styles';
const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& > :not(style) + :not(style)': {
    marginTop: theme.spacing(2),
  },
}));
const MedicalHistoryCategory = () => {
  return (
      <GynecologicalHistoryProvider>
            <GynecologicalHistoryReport />
            <GynecologicalHistoryRecord />
      </GynecologicalHistoryProvider>

  );
};

export default MedicalHistoryCategory;
