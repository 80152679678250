export function validateEmail(email) 
{
    const strongRegex = new RegExp("^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$");

    if(strongRegex.test(email))
    {
        return true;
    }
    else
    {
        return false;
    }
}

export function checkPassword(password){
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{12,})");
   
    if(strongRegex.test(password)) 
    { 
    return true;
    }
    else
    { 
    return false;
    }
}

export function numbersNotAllowed(text){
    let strongRegex = new RegExp("^([^0-9]*)$");
    if(strongRegex.test(text)) 
    { 
    return true;
    }
    else
    { 
    return false;
    }
}

export function emptyCheck(text){
    let strongRegex = new RegExp("^$");
    if(strongRegex.test(text.trim())) 
    { 
    return true;
    }
    else
    { 
    return false;
    }
}

export function dateFormatter(text) 
{
    let today = new Date(text);
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    today = mm + '/' + dd + '/' + yyyy;
    return today;
}

export function dateIsValid(date) {
    let yyyy = date.getFullYear();
    return isFinite(date instanceof Date ? date : new Date(date))  && yyyy>1899;
}

export function getParameters(url) 
{
    let urlString = url;
    let paramString = urlString.split('?')[1];
    let params_arr = paramString.split('&');
    let urlObject=[];
    for(let i = 0; i < params_arr.length; i++) {
        let pair = params_arr[i].split('=');
        urlObject[pair[0]]=pair[1];
    }
    return urlObject;
}

