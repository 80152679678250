import React  from 'react';
import { Fragment } from 'react';
import FamilyMedicalHistoryRecord from './FamilyMedicalHistoryRecord';
import FamilyMedicalHistoryReport from './FamilyMedicalHistoryReport';
import FamilyMedicalHistoryProvider from './familyMedicalHistory-Provider';

const FamilyMedicalHistoryCategory = () => {
  return (
      <FamilyMedicalHistoryProvider>
            <FamilyMedicalHistoryReport/>
            <FamilyMedicalHistoryRecord/>
      </FamilyMedicalHistoryProvider>

  );
};

export default FamilyMedicalHistoryCategory;
