import { Fragment } from 'react';
import  React,{useEffect,useState,useContext } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PersonalizedButton from "../../Layout/Button"
import FormControl from "@mui/material/FormControl";
import {numbersNotAllowed,emptyCheck,dateFormatter,dateIsValid} from "../../../methods/functions"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Stack from "@mui/material/Stack";
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { isExists } from 'date-fns';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SurgicalHistoryContext from '../SurgicalHistory/surgicalHistory-context';
import { createTheme, ThemeProvider } from '@mui/material/styles';
const theme = createTheme();
function RedBar(props) {
  return (
    <Box
      sx={{
        height: props.height,
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? 'rgba(255, 0, 0, 0.1)'
            : 'rgb(255 132 132 / 25%)',
      }}
    > 
    <div align="left" 
    style=
    {{
      mt: props.mt, 
      mb: props.mb,
      paddingTop:props.paddingTop,
      paddingLeft:props.paddingLeft,
      paddingBottom:props.paddingBottom
    }}
    > 
    {props.children} 
    </div> 
 
    
    </Box>
   
  )
}

export default function InsuranceRecord() {
  const [surgicalDateValueError, setSurgicalDateValueError] = useState();
  const [arrayFieldCategory ,setArrayCategory] = React.useState([]);
  const [field,setFieldCategory] = React.useState(0);
  const [showSubCategory,setShowSubCategory]=  React.useState(0);
  const [arrayFieldSubCategory ,setArraySubCategory] = React.useState([]);
  const [arrayFieldSubCategoryObject ,setArraySubCategoryObject] = React.useState([]);
  const [fieldSubCategory,setFieldSubCategory] = React.useState(0);
  const [surgicalDateValue, setSurgicalDateValue] = React.useState(new Date());
  const [recordOption ,setRecordOption] = useState(0);
  const surgicalHistoryCtx = useContext(SurgicalHistoryContext);
  const [updated,setUpdatedField]=useState('');

  const [showSubName,setShowSubName]=  React.useState(0);
  const [subNameField, setSubNameField] = useState('');
  const [subNameFieldError, setSubNameFieldError] = useState();

  const createURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_SURGICAL_HISTORY_CREATE;

  function getData(){
    const getCatalog=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_SURGICAL_HISTORY_GET_CATALOG;
    return fetch(getCatalog ,{
     method: 'GET',
     headers: {  
     'Content-Type': 'application/json',
     'Token': localStorage.getItem("token"),
     'userid':localStorage.getItem("userId")     
     }
   })
   .then((response) => response.json())
   .then((data) => {   
    let arrCategory=[];
    let arrSubCategory=[];
    let count=0;
    let subCount=0;
    let datax=undefined;
    let objectSubCategory=[];
    let objectCategory=[];
    let existsSubCategory=0;
    let arrayX=[];
    try
    {
     datax= data.body[0].category;

    }
    catch
    {
      datax=[];
    }

    for(let category in datax)
    {
        count=count+1;
        try
        {
          existsSubCategory = datax[category].subCategory.length; 
          }  
          catch
          {
            existsSubCategory=-1;
          }

          if(datax[category].name.toLowerCase()=="other")
          {
            arrCategory.push({
                  index: count,
                  category:"Other",
                  label:"Other",
                  key: parseInt(category)
                })

          }
          else
          {
                    arrCategory.push({
                      index: count,
                      category:datax[category].name,
                      label:datax[category].name,
                      key: parseInt(category)
                    })              
          } 
        
          objectCategory=[];

          if(existsSubCategory>0)
          {
       
            for(let subCategory in datax[category].subCategory)
            { 
                arrSubCategory.push({
                  indexSubCategory: subCount++,
                  category:category,
                  label:datax[category].subCategory[subCategory],
                  key: parseInt(subCategory)
                });

            }
            objectSubCategory[category]=arrSubCategory;
            setArraySubCategoryObject(objectSubCategory);
            if(category==0)
            {
              setArraySubCategory(arrSubCategory);
            }

          }
          arrSubCategory=[];

          subCount=0;
    }
    setArrayCategory(arrCategory);
    setShowSubCategory(1);
   });
  }

  const handleChangeRecordOption = (event) => {
    if(event.target.value==0)
    {
      setRecordOption(0);
      setSurgicalDateValue(new Date());
      setSubNameField('');
      setShowSubCategory(0);
      setShowSubName(0);
      setFieldCategory(0);
      setFieldSubCategory(0);
    }
    setRecordOption(event.target.value);
  };

  const handleSubmitSurgicalHistory = (event) => {
    event.preventDefault();
    setSurgicalDateValueError(false);
    setSubNameFieldError(false);
    let category=field;
    let subCategory=fieldSubCategory;
    let surgicalDate=surgicalDateValue;
    let finalDecision=true;

    if(showSubCategory==0)
    {
      if(arrayFieldCategory[category].category.toLowerCase().toString()=="other"){
        if(emptyCheck(subNameField) || !numbersNotAllowed(subNameField)){
          setSubNameFieldError(true);
          finalDecision=false;
        }
      }
    }
    if (isNaN(surgicalDateValue) || surgicalDateValue == null) {
      try {
        if (dateIsValid(surgicalDateValue) == false) {
          setSurgicalDateValueError(true);
          finalDecision = false;
        }
      } catch {
        let today = new Date(surgicalDateValue);
        let yyyy = today.getFullYear();
        if (surgicalDateValue == null) {
          setSurgicalDateValueError(true);
          finalDecision = false;
        }
        if (yyyy <= 1899) {
          setSurgicalDateValueError(true);
          finalDecision = false;
        }
      }
    } else {
      let today = new Date(surgicalDateValue);
      let yyyy = today.getFullYear();
      if (yyyy <= 1899) {
        setSurgicalDateValueError(true);
        finalDecision = false;
      }
    }
    if(finalDecision)
    {
          setSurgicalDateValueError(false);
          setSubNameFieldError(false);
          let surgicalDatee= dateFormatter(surgicalDate);
          const getURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_SURGICAL_HISTORY_GET;
          fetch(getURL ,{
            method: 'GET',
            headers: {  
            'Content-Type': 'application/json',
            'Token': localStorage.getItem("token"),
            'userid':localStorage.getItem("userId")     
            }
          })
          .then((response) => response.json())
          .then((data) => {
            let arrayX=undefined;
            try
            {
            arrayX= data.body[0].surgicalHistory;
            }
            catch
            {
              arrayX=[];
            }
            const result = arrayX ? arrayX.length : 0;
            if(showSubName!=1)
            {
              subCategory=subCategory==-1?"":subCategory;
            }
            else
            {
              subCategory=subNameField;
            }

            if(result==0)
            {
                  const surgicalHistoryData={
                    userId:localStorage.getItem("userId"),
                    surgicalHistory: [
                      {
                            dateOfSurgery: surgicalDatee,
                            category: parseInt(category),
                            subCategory: subCategory
                      }
                    ]       
                  };


                const requestOptions = {
                  method: 'POST',
                  headers: { 
                    
                    'Content-Type': 'application/json',
                    "Token": localStorage.getItem("token")  
                
                  },
                      body: JSON.stringify(surgicalHistoryData)
                  };
                  try{
                    fetch(createURL, requestOptions)
                        .then(response => response.json());
                        alert("Done");
                        setSurgicalDateValue(new Date());
                        setFieldCategory(0);
                        setFieldSubCategory(0);
                        setSubNameField('');
                        setShowSubName(0);
                        setShowSubCategory(0);
                        surgicalHistoryCtx.items=[];
                        surgicalHistoryCtx.addItem(surgicalHistoryCtx.items);
                  }
                  catch{
                      alert("Error");
                  }  

            }
            else
            {
                   if(recordOption==1){
                     //Update
                    setRecordOption(0);
                    const valuex= surgicalHistoryCtx;
                    let surgicalHistory=[];
                    let originalArray=[];
                    originalArray=data.body;

                    for(let m in arrayX )
                    {
                      if(m==valuex.items.id)
                      {
                             surgicalHistory.push(
                              {
                               dateOfSurgery: surgicalDatee,
                               category: parseInt(category),
                               subCategory: subCategory
                              }  
                         );
                      }
                      else
                      {
                        surgicalHistory.push(
                          {
                           dateOfSurgery: arrayX[m].dateOfSurgery,
                           category: arrayX[m].category,
                           subCategory: arrayX[m].subCategory
                          }  
                      );
                      }
       
                     
                    }
  
                    originalArray[0].documentId=originalArray[0].id;
                    originalArray[0].surgicalHistory=surgicalHistory;

                      const update=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_SURGICAL_HISTORY_UPDATE;
                      const requestOptions = {
                        method: 'PUT',
                        headers: { 
                          
                          'Content-Type': 'application/json',
                          "Token": localStorage.getItem("token")
                        },
                          body: JSON.stringify(originalArray[0])
                    };

                      try{
                      fetch(update, requestOptions)
                      .then(response => response.json());
                      surgicalHistoryCtx.items=[];
                      setUpdatedField(1);
                      surgicalHistoryCtx.report=surgicalHistoryCtx.report+1;
                      surgicalHistoryCtx.addItem(surgicalHistoryCtx.items);
        
                      }
                      catch
                      {
                      alert("Error");
                      }

                   setSurgicalDateValue(new Date());
                   setSubNameField('');
                   setShowSubName(0);
                   setShowSubCategory(0);
                   setFieldCategory(0);
                   setFieldSubCategory(0);
                   alert("Done");
                   
                   surgicalHistoryCtx.addItem(surgicalHistoryCtx.items);
                   }
                   else{
                    const valuex= surgicalHistoryCtx;
                    let originalArray=[];
 
                    originalArray=data.body;

                    originalArray[0].surgicalHistory.push(
                      {
                        dateOfSurgery: surgicalDatee,
                        category: parseInt(category),
                        subCategory: subCategory
                      }
                    );

                    originalArray[0].documentId=originalArray[0].id;

                    const update=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_SURGICAL_HISTORY_UPDATE;
                    const requestOptions = {
                      method: 'PUT',
                      headers: { 
                        
                        'Content-Type': 'application/json',
                        "Token": localStorage.getItem("token")
                      },
                        body: JSON.stringify(originalArray[0])
                   };

                    try{
                    fetch(update, requestOptions)
                    .then(response => response.json());
                    surgicalHistoryCtx.items=[];
                    setUpdatedField(1);
                    surgicalHistoryCtx.report=surgicalHistoryCtx.report+1;
                    }
                    catch
                    {
                     alert("Error");
                    }

                    setSurgicalDateValue(new Date());
                    setSubNameField('');
                    setShowSubName(0);
                    setShowSubCategory(0);
                    setFieldCategory(0);
                    setFieldSubCategory(0);
                    alert("Done");
                   
                   surgicalHistoryCtx.addItem(surgicalHistoryCtx.items);
                   }

            }
          }
          );
    }

    

  };
  
  
    useEffect(() => {
      setShowSubName(0);
      if(updated==1)
      {
        setSurgicalDateValue(new Date());
        setSubNameField('');
        setShowSubName(0);
        setShowSubCategory(0);
        setFieldCategory(0);
        setFieldSubCategory(0);
        setRecordOption(0);
        setUpdatedField(0);
      }
      if(surgicalHistoryCtx.items.option==2)
      {
        let dateOfSurgery= surgicalHistoryCtx.items.dateOfSurgery?surgicalHistoryCtx.items.dateOfSurgery:new Date();
        setSurgicalDateValue(dateOfSurgery);
        setFieldCategory(surgicalHistoryCtx.items.categoryId);
        let subCount=0;
        let verification=-1;
        let arrXSubCategory=[];
        for(let row in arrayFieldSubCategoryObject[surgicalHistoryCtx.items.categoryId])
        {
            verification=0;
            arrXSubCategory.push({
              indexSubCategory: subCount++,
              category:parseInt(surgicalHistoryCtx.items.categoryId),
              label:arrayFieldSubCategoryObject[surgicalHistoryCtx.items.categoryId][row].label,
              key: parseInt(arrayFieldSubCategoryObject[surgicalHistoryCtx.items.categoryId][row].key)
            });
        }
        if(verification!=-1)
        {
          setArraySubCategory(arrXSubCategory);
          setFieldSubCategory(surgicalHistoryCtx.items.subCategoryId);
          setShowSubCategory(1);
          setShowSubName(0);
        }
        else
        {
          if(surgicalHistoryCtx.items.subCategoryId==-2){
             setShowSubName(1);
             setShowSubCategory(0);
             setSubNameField(surgicalHistoryCtx.items.subCategoryName);
          }
          else
          {
            setShowSubCategory(0);
          }
        }

        setRecordOption(1);
      }
      else{
        setShowSubName(0);
        setShowSubCategory(0);
        getData();
      }
      // eslint-disable-next-line
    }, [surgicalHistoryCtx]);


  const surgicalDateHandleChange = (newValue) => {
      setSurgicalDateValue(newValue);
  };

  const handleChangeOptionCategory = (event) => {
    const newArray= arrayFieldCategory;
    let arrSubCategoryX=[];

    let subCount=0;
    let categoryId=event.target.value;

    for(let row in arrayFieldSubCategoryObject[categoryId])
    {
        arrSubCategoryX.push({
          indexSubCategory: subCount++,
          category:newArray[(event.target.value)].key,
          label:arrayFieldSubCategoryObject[categoryId][row].label,
          key:arrayFieldSubCategoryObject[categoryId][row].key
        });

    }

    if(arrSubCategoryX.length>0 )
    {
      setArraySubCategory(arrSubCategoryX);
      setShowSubCategory(1);
      setShowSubName(0);
      setFieldSubCategory(0);
    }
    else
    {
      setShowSubCategory(0);
      setArraySubCategory([]);
      setFieldSubCategory(-1);
      if(newArray[(event.target.value)].label.toLowerCase()=="other")
      {
          setShowSubName(1);
      }
      else
      {
        setShowSubName(0);
      }
    }

    setFieldCategory(event.target.value);
  };

  const handleChangeOptionSubCategory = (event) => {

    setFieldSubCategory(event.target.value);
  };
  const changeSubNameField = (event) => {
    setSubNameField(event.target.value);
};
  return (
        <Box
        component="form"
         noValidate
        onSubmit={handleSubmitSurgicalHistory}
            sx={{
              my: 4,
              mx: 12,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >  
              <Typography component="h1" variant="h5">
                  Record
                </Typography>
                <FormControl sx={{ m: 2}}>
                      <RadioGroup
                        sx={{ display: 'inline' }}
                        aria-labelledby="record-radio-buttons-group-label"
                        name="record-radio-buttons-group"
                        value={recordOption}
                        onChange={handleChangeRecordOption}
                      >
                        <FormControlLabel value="0" control={<Radio />} label="Create" />
                        <FormControlLabel value="1" disabled control={<Radio />} label="Update" />
                      </RadioGroup>
              </FormControl>
              <FormControl  sx={{ m: 1}}>
              <Stack  spacing={4} sx={{ m: 1 , mx: 0}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} >
                              <DesktopDatePicker
                              label="Surgical Date"
                              id="surgicalDate"
                              value={surgicalDateValue}
                              onChange={surgicalDateHandleChange}
                              inputFormat="MM/dd/yyyy"
                              renderInput={(params) => (
                                <TextField {...params} error={surgicalDateValueError ? true : false} />
                              )}
                            />
                            </LocalizationProvider>
                          </Stack>   
              </FormControl>
              <FormControl  sx={{ mt: 0, mx: 0 }} >
              </FormControl>
              <React.Fragment>
              <FormControl  sx={{ mt: 1}} >
              <InputLabel sx={{ mt: 1 }} id="optionLabel">Category</InputLabel>
              <Select
                        labelId="optionLabel"
                        id="option"
                        label="Option"
                        value={field}
                        onChange={handleChangeOptionCategory}
                        >
                            {
                               arrayFieldCategory.map((option) => (
                                  <MenuItem  key={option.key} value={option.key}>{option.label}</MenuItem>
                                ))
                            }
              </Select>
             </FormControl>
             </React.Fragment>
             {
                  showSubCategory==1?
              <FormControl  sx={{ mt: 2}} >
              <InputLabel sx={{ mt: 1 }} id="optionSubCategoryLabel">SubCategory</InputLabel>
              <Select
                        labelId="optionSubCategoryLabel"
                        id="optionSubCategory"
                        label="option"
                        value={fieldSubCategory}
                        onChange={handleChangeOptionSubCategory}
                        >
                            {
                               arrayFieldSubCategory.map((optionSubCategory) => (
                                  <MenuItem  key={optionSubCategory.key} value={optionSubCategory.key}>{optionSubCategory.label}</MenuItem>
                                ))
                            }
              </Select>
             </FormControl>
                :   
                <React.Fragment></React.Fragment>
            }
             {
              showSubName==1?
             <FormControl  sx={{ mt: 3, mx: 0 }} >
             <TextField
                        InputLabelProps={{ shrink: true }}
                        error= {subNameFieldError? true:false}
                        sx={{ m: 0}}
                        margin="normal"
                        id="subNameField"
                        label="SubCategory"
                        name="subNameField"      
                        value={subNameField}
                        onChange={changeSubNameField}      
                />    
              </FormControl>                        :   
              <React.Fragment></React.Fragment>
              }
              <PersonalizedButton  
              type="submit"
                variant="contained"
                 sx={{ mt: 2, mb: 2 }}
              >
                  Save
              </PersonalizedButton>   
              {subNameFieldError &&(
                <RedBar
                    paddingTop={"0.1em"}
                    paddingBottom={"0.1em"}
                >
                  <ul> 
                  <li>Invalid SubCategory, only text allowed.</li>
                 </ul>
                </RedBar>
                )} 
        </Box>
  );
}
