import  React,{useEffect,useState,useContext} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography
} from "@material-ui/core";
import VisibilityIcon from '@mui/icons-material/Visibility';
import TablePagination from '@mui/material/TablePagination';
import HealthRecordSharedWithMeContext from './healthRecordSharedWithMe-context';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from "@mui/material/FormControl";
import InputAdornment from '@mui/material/InputAdornment';
import DownloadIcon from '@mui/icons-material/Download';
import AutorenewIcon from '@mui/icons-material/Autorenew';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    borderRadius: 15,
    margin: "10px 10px",
    maxWidth: "1800px",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    backgroundColor: "#4788B8",
    color: theme.palette.getContrastText(theme.palette.primary.dark)
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
  name: {
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
  },
  status: {
    fontWeight: "bold",
    fontSize: "0.75rem",
    color: "white",
    backgroundColor: "grey",
    borderRadius: 8,
    padding: "3px 10px",
    display: "inline-block",
  },
  hover: {
    cursor: "pointer",
  },
  center:{
    textAlign:'center'
  }
}));

const tableHeader = [
  { label: "User", data: "User" },
  { label: "Relationship", data: "relationship" },
  { label: "View", data: "view" },
  { label: "Sync", data: "sync" },
  { label: "Download", data: "download" },
];

function createData(
  userId,
  token,
  grantorName,
  relationship,
  access,
  option
  ) {
  return {
    userId,
    token,
    grantorName,
    relationship,
    access,
    option
  };
}

const MTable = (props) => {
  const healthRecordSharedWithCtx = useContext(HealthRecordSharedWithMeContext);
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [shareHealthRecordData, setData] = useState([]);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getURL=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_GET_SHARED_WITH;

    useEffect(() => {
      
         let array=[];
         let relationshipArray=[];
         const getCatalog=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_RELATIONSHIP_GET;
         fetch(getCatalog ,{
         method: 'GET',
         headers: {  
          'Content-Type': 'application/json',
          'Token': localStorage.getItem("token"),
          'userid':localStorage.getItem("userId")     
         }
        })
        .then((response) => response.json())
        .then((data) => {
          relationshipArray=data.body.category;
          });

        const getUser=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_GET_USER;
        fetch(getUser ,{
          method: 'GET',
          headers: {  
          'Content-Type': 'application/json',
          'Token': localStorage.getItem("token"),
            'id':  localStorage.getItem("userId")           
          }
        })
        .then(response=> response.json())
        .then(data => {
              fetch(getURL ,{
                method: 'GET',
                headers: {  
                'Content-Type': 'application/json',
                'Token': localStorage.getItem("token"),
                'email':data.body.email
                }
              })
              .then((response) => response.json())
              .then((datax) => {  
            
                for(let row in datax.body.accessList)
                {  
                  if(datax.body.accessList[row].status!="pending" && datax.body.accessList[row].status!="inactive" )
                  {
                    array.push(
                      createData(
                        datax.body.accessList[row].userId,
                        datax.body.accessList[row].token,
                        datax.body.accessList[row].grantorName,        
                        relationshipArray[datax.body.accessList[row].relationship],
                        datax.body.accessList[row].categories
                      ));
                  }
                }
                setData(array);  
              });
        }); 
        // eslint-disable-next-line
    }, [healthRecordSharedWithCtx.count]);


  const styleObj = {
    fontSize: 30,
    color: "black",
}

const verifyAndAttest = (index) => {
  const getUrl=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_HEALTH_GET_REPORT_VERIFY;
  fetch( getUrl ,{
    method: 'GET',
    headers: {  
    'Content-Type': 'application/json',
    'token': localStorage.getItem("token"),
    'userid':  index.userId,
    'recipientemail': localStorage.getItem("email"),
    'invitetoken':  index.token
    }
  })
  .then(response=> response.json())
  .then(data => {

        if(data.body.result=="verified")
        { 
          alert("PDF synced");
        }else {
          alert("Error on syncing PDF, please contact: support@oneme.app");
        }
  });
}

const downloadItem = (index) => {
  index.option=1;
  const download=process.env.REACT_APP_GET_SERVER_URL + process.env.REACT_APP_HEALTH_REPORT_DOWNLOAD;
          
  fetch(download ,{
    method: 'GET',
    headers: {  
    'Content-Type': 'application/json',
    'token': localStorage.getItem("token"),
    'userid':  index.userId,
    'recipientemail': localStorage.getItem("email"),
    'invitetoken':  index.token
    }
  })
  .then(response=> response.json())
  .then(result => {
    try{
    let file= new Uint8Array(result.body.content[0].data);
    const blob = new Blob([file]); 
    const url= window.URL.createObjectURL(blob);
    const a= document.createElement('a');
    a.style.display='none';
    a.href=url;
    //the filename you want
    a.download= "Health Report - " + index.grantorName;
    a.setAttribute('download',"Health Report - " + index.grantorName +".pdf");
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    }
    catch
    {
      alert("Error on downloading the report. Please contact support@oneme.app");
    }
  });

  //healthRecordSharedWithCtx.addItem(index);
}
const itemVisibility = (index) => {
  index.option=2;
  healthRecordSharedWithCtx.addItem(index);
}
const [query, setQuery] = useState("")
  return (
    <React.Fragment>
              <FormControl sx={{ m: 2}} fullWidth>
              <TextField
                        margin="normal"
                        fullWidth
                        id="address"
                        label="Search by user"
                        name="search by user"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                             <SearchIcon/>
                            </InputAdornment>
                          ),
                        }}
                        onChange={event => setQuery(event.target.value)}
                        variant="standard"
                />      
                </FormControl>
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableHeader.map((cell) => (
              <TableCell key={cell.data} className={classes.tableHeaderCell}>
                {cell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
        {(rowsPerPage > 0
            ? shareHealthRecordData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : shareHealthRecordData
          ).filter(post => {
            if (query === "") {
              //if query is empty
              return post;
            } else if (post.grantorName!=undefined?post.grantorName.toLowerCase().includes((query.toLowerCase())):false) {
              //returns filtered array
              return post;
            }
          }).map((row,i) => (
            <TableRow key={Math.random()} 
            >
              <TableCell>{row.grantorName}</TableCell>
              <TableCell>{row.relationship}</TableCell>
              <TableCell>
              <Typography>
                    <VisibilityIcon
                      color="primary"
                      className={classes.hover}
                      onClick={(e) => {
                        e.stopPropagation(); 
                        itemVisibility(row);
                      }}
                    />
             </Typography>
              </TableCell>
              <TableCell>
              <Typography>
                    <AutorenewIcon
                      color="primary"
                      className={classes.hover}
                      onClick={(e) => {
                        e.stopPropagation(); 
                        verifyAndAttest(row);
                      }}
                    />
             </Typography>
              </TableCell>
              <TableCell>
              <Typography>
                    <DownloadIcon
                      color="primary"
                      className={classes.hover}
                      onClick={(e) => {
                        e.stopPropagation(); 
                        downloadItem(row);
                      }}
                    />
             </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={shareHealthRecordData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
    </TableContainer>

    </React.Fragment>
  );
};

export default MTable;