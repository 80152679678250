import React  from 'react';
import { Fragment } from 'react';
import VaccineRecord from './VaccineRecord';
import VaccineReport from './VaccineReport';
import VaccineProvider from './vaccine-Provider';

const VaccineCategory = () => {
  return (
      <VaccineProvider>
           <VaccineReport/>
            <VaccineRecord/>
      </VaccineProvider>

  );
};

export default VaccineCategory;
